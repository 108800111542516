<!--添加集料-->
<template>
  <a-modal
    :visible="props.visible"
    width="50%"
    wrap-class-name="qy-modal"
    centered
    :destroyOnClose="true"
    :closeIcon="true"
    style="color: black"
    @cancel="closeModal"
  >
    <template #title>
      <div ref="modalTitleRef" class="modal-name">
        {{ props.modalTitle }}
      </div>
      <a-button class="close-btn" @click="closeModal"> 关闭 </a-button>
    </template>
    <div class="add-aggregates">
      <a-form
        ref="addAggregatesRef"
        :model="reqData"
        :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ span: 14 }"
        :rules="rules"
      >
        <a-form-item label="集料分类" name="aggregatesType">
          <div class="fromline">
            <a-select
              v-model:value="reqData.aggregatesType"
              :options="typeOptions"
              placeholder="请选择集料分类"
              @change="handleTypeChange"
            >
            </a-select>
          </div>
        </a-form-item>
        <a-form-item label="级配类型">
          <div class="agrund">
            <div
              class="agrund-item"
              v-for="(item, index) in jptypeOptionsCopy"
              :class="{ agrundActive: item.isCk }"
              :key="index"
              @click="handleStatusChange(index, 'agrund')"
            >
              {{ item.name }}
            </div>
          </div>
        </a-form-item>
        <a-form-item label="集料规格" name="aggregatesSpecs">
          <div class="fromline">
            <a-input
              v-model:value="reqData.aggregatesSpecs"
              placeholder="请输入集料规格"
              :maxlength="255"
              allow-clear
            />
          </div>
        </a-form-item>
        <a-form-item label="地磅集料规格" name="iwsAggregatesSpecs">
          <div class="fromline">
            <a-select
              v-model:value="reqData.iwsAggregatesSpecs"
              mode="tags"
              style="width: 100%"
              :token-separators="[',']"
              placeholder="请输入地磅集料规格"
              :options="iwsAggregateOptions"
            ></a-select>
          </div>
        </a-form-item>
        <a-form-item label="状态">
          <div class="agrund">
            <div
              class="agrund-item"
              v-for="(item, index) in statusOptionsCopy"
              :class="{ agrundActive: item.isCk }"
              :key="index"
              @click="handleStatusChange(index, 'status')"
            >
              {{ item.name }}
            </div>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <template #footer>
      <div class="footes" @click="handleSave">
        <div class="mbtns stars">
          <span>确定</span>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script setup>
import { ref, onMounted, defineProps, toRefs, defineEmits } from 'vue';
//TODO 下拉选项配置项
import {
  // categoryOptions,
  typeOptions,
  statusOptions,
  jptypeOptions,
} from '../../js/opts.group.js';
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
//TODO 导入api接口
import { apiTopHeader } from '@/api/api-topHeader.js';
// import { nextTick } from 'vue'

const emits = defineEmits(['update:visible']);
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
    default() {
      return false;
    },
  },
  modalTitle: {
    type: String,
    required: true,
    default: '添加集料',
  },
  childGridRef: {
    type: Object,
    required: true,
  },
  childEditVars: {
    type: Object,
    required: true,
  },
});
let { childEditVars, childGridRef } = toRefs(props);
let tempChildEditVars = childEditVars;
let tempChildGridRef = childGridRef;
const addAggregatesRef = ref(null);

// 集料规格校验规则
const rules = {
  aggregatesType: [
    {
      required: true,
      trigger: 'blur',
    },
  ],
  aggregatesSpecs: [
    {
      required: true,
      trigger: 'blur',
    },
  ],
};
const iwsAggregateOptions = ref([])

//请求接口参数
let reqData = ref({});

let statusOptionsCopy = ref([...statusOptions]);
let jptypeOptionsCopy = ref([...jptypeOptions]);
onMounted(() => {
  getDetail();
});
const getDetail = () => {
  if (tempChildEditVars.value.currentId) {
    //id存在=编辑
    //请求详情
    apiTopHeader
      .getAggregateDetailById(tempChildEditVars.value.currentId)
      .then((res) => {
        if (res.data.code == 0) {
          // 保存最编辑状态最初的集料规格
          // aggregatesSpecsTmp = res.data.data.aggregatesSpecs;

          reqData.value = res.data.data;
          if (res.data.data?.iwsAggregatesSpecs) {
            reqData.value.iwsAggregatesSpecs = reqData.value.iwsAggregatesSpecs.split(',')
          } else {
            reqData.value.iwsAggregatesSpecs = []
          }
          // console.log(reqData.value);
          //重置所有状态
          for (let i = 0; i < statusOptionsCopy.value.length; i++) {
            statusOptionsCopy.value[i].isCk = false;
          }
          //重置所有状态
          for (let i = 0; i < jptypeOptionsCopy.value.length; i++) {
            jptypeOptionsCopy.value[i].isCk = false;
          }
          // 渲染状态字段
          statusOptionsCopy.value.forEach((option) => {
            if (
              (reqData.value.status === 1 && option.name === '启用') ||
              (reqData.value.status === 0 && option.name === '停用')
            ) {
              option.isCk = true;
            }
          });

          // 渲染 jptype 字段
          jptypeOptionsCopy.value.forEach((option) => {
            if (
              (reqData.value.aggregatesFlag === 1 &&
                option?.name === '单粒粒级') ||
              (reqData.value.aggregatesFlag === 2 &&
                option?.name === '连续粒级')
            ) {
              option.isCk = true;
            }
          });
        }
      })
      .catch((error) => {
        console.error(error);
        message.error(error.data.message);
      });
  }
};

const closeModal = () => {
  emits('update:visible', false);
};
//处理集料分类选择
const handleTypeChange = (val) => {
  let aggregatesTypeGroup = val.split('-');
  //父级
  reqData.value.aggregatesTypeParent = aggregatesTypeGroup[0];
  //子级
  reqData.value.aggregatesType = aggregatesTypeGroup[1];
};

//处理状态选择
const handleStatusChange = (idx, type) => {
  // let tempOptionsCopy =
  //   type == 'status' ? statusOptionsCopy.value : jptypeOptionsCopy.value;
  if (type == 'status') {
    //重置所有状态
    for (let i = 0; i < statusOptionsCopy.value.length; i++) {
      statusOptionsCopy.value[i].isCk = false;
    }
    statusOptionsCopy.value[idx].isCk = true;
  } else {
    //重置所有状态
    for (let i = 0; i < jptypeOptionsCopy.value.length; i++) {
      jptypeOptionsCopy.value[i].isCk = false;
    }
    jptypeOptionsCopy.value[idx].isCk = true;
  }
};

//单独处理状态字段
const statusFieldSave = () => {
  let selectStatus = statusOptionsCopy.value.filter((item) => item.isCk);
  reqData.value.status = selectStatus[0]?.name == '启用' ? 1 : 0;
  let selectJptype = jptypeOptionsCopy.value.filter((item) => item.isCk);

  if (selectJptype[0]?.name == '连续粒级') {
    reqData.value.aggregatesFlag = 2;
  } else if (selectJptype[0]?.name == '单粒粒级') {
    reqData.value.aggregatesFlag = 1;
  }
};

//处理保存
const handleSave = async () => {
  // 校验集料规格、集料分类
  const valid = await addAggregatesRef.value.validate();
  if (!valid) return;
  //保存状态、级配类型字段
  statusFieldSave();

  if (reqData.value.iwsAggregatesSpecs) {
    Modal.confirm({
      title: '确认',
      icon: createVNode(ExclamationCircleOutlined),
      content: createVNode(
        'div',
        {
          style: 'color:red;font-size:0.09rem;',
        },
        `请确保“集料规格”与“地磅集料规格“是同一种材料!`
      ),
      maskClosable: true,
      style: `top:30%`,
      onOk() {
        handleAggregate();
      },
      onCancel() {
        // console.log('Cancel');
      },
      class: 'test',
    });
  } else {
    handleAggregate();
  }
};
const handleAggregate = () => {
  let params = JSON.parse(JSON.stringify(reqData.value))
  Object.assign(params, {
    iwsAggregatesSpecs:reqData.value.iwsAggregatesSpecs.join(',')
  })
  //编辑 调用编辑接口
  apiTopHeader
    .addAggregate(params)
    .then((res) => {
      if (res.data.code == 0) {
        message.success(res.data.message, 2);
        //刷新表格
        setTimeout(() => {
          tempChildGridRef.value.commitProxy('query');
        }, 1000);
        //关闭弹窗
        tempChildEditVars.value.modalShow = false;
      }
    })
    .catch((error) => {
      console.error(error.data.message);
      message.error(error.data.message, 2);
    });
};
</script>
<style lang="scss" scoped>
@use '../../../assets/style/modalcoustom.scss';
@use '../../../assets/style/vxe-coustom.scss';
input:not([type='range']),
label,
select,
summary,
textarea {
  padding: 0.02rem 0rem;
  text-align: center;
}
.standard-grain-composition {
  width: 82%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.my-table-use {
  width: 100%;
  margin: 0.02rem auto;
  border-color: #9ecdfc;
  margin-bottom: 0.3rem;
}
.my-table-use tr td,
.my-table-use tr th {
  text-align: center;
  font-size: 0.07rem;
}
.add-aggregates {
  height: 80% !important;
}
/*表格样式 start*/
.standard-wrapper {
  width: 82%;
  margin: 0.07rem auto;
  color: #fff;
}
.standard-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.1rem 0;
}
.stand-content .my-table-1 {
  width: 100%;
  border: 1px solid #9ecdfc;
  border-collapse: collapse;
  margin-bottom: 0.1rem;
}
:deep(.ant-btn-primary) {
  height: fit-content;
}

.stand-content .my-table-1 tr {
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: center;*/
}
.stand-content .my-table-1 tr th {
  padding: 0.05rem 0.08rem;
  border: 1px solid #9ecdfc;
  font-size: 0.07rem;
}
.stand-content .my-table-2 {
  border: 1px solid #9ecdfc;
  border-collapse: collapse;
  margin-bottom: 0.1rem;
}

.stand-content .my-table-2 tr td {
  padding: 0.05rem 0.08rem;
  border: 1px solid #9ecdfc;
  text-align: center;
}

/*表格样式 end*/

.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.06rem;
}

.save-btn {
  /*font-size: 0.12rem !important;*/
  background-color: rgb(43, 154, 214);
}

.ant-select-selection-item {
  font-size: 14px !important;
}

.ant-select {
  font-size: 14px !important;
}
:deep(.ant-select-selection-search){
  color: white;
}
:deep(.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input){
  color: black;
}
</style>
