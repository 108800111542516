import { createRouter, createWebHashHistory } from 'vue-router'//
import Home from '../views/Home.vue';
import Login from "../views/Login.vue";
import DetectionScreen from "../views/cpns/screen/detection-screen.vue";
import WeighbridgeScreen from "../views/cpns/screen/weighbridge-screen.vue";
import LiveMonitoring from "../views/cpns/screen/live-monitoring.vue";
// import NotFound from "../views/exception/404.vue";
import NProgress from 'nprogress';
// import store from "@/store";
import setting from "../config/setting";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/home',
        name: 'HomeNotLogin',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
        // component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/detectionScreen',
        name: 'DetectionScreen',
        component: DetectionScreen
        // component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/weighbridgeScreen',
        name: 'WeighbridgeScreen',
        component: WeighbridgeScreen
        // component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/liveMonitoring',
        name: 'LiveMonitoring',
        component: LiveMonitoring
        // component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    // {
    //     path: '/404',
    //     name: '/404',
    //     component: NotFound
    // },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/exception/404')
        // redirect: '/404',
    },
]

// console.log(process.env.BASE_URL)
const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
    
    NProgress.start();
    updateTitle(to);
    if (setting.takeToken()) {
        //放行，无需获取用户信息
        next();
        // store.dispatch('user/getUser').then(() => {
        //     const user = setting.takeUser();
        //     if (user) {
        //         next();
        //     } else {
        //         next({...to, replace: true});
        //     }
        // })
    } else if (setting.whiteList.indexOf(to.path) !== -1) {
        next();  // 在无需登录的白名单内
    } else {
        // TODO 0411 未登录跳转登录页面
        // next();
        next({ path: '/login', query: to.path === '/' ? {} : { from: to.path } });
    }
});

router.afterEach(() => {
    setTimeout(() => {
        NProgress.done(true);
    }, 300);
});


export default router

/**
 * 更新浏览器标题
 * @param route 路由
 */
function updateTitle(route) {
    let names = [];
    if (route && route.meta && route.meta.title) {
        names.push(route.meta.title);
    }
    const appName = process.env.VUE_APP_NAME;
    if (appName) {
        names.push(appName);
    }
    document.title = `${names.join(' - ')}-${process.env.VUE_APP_VERSION}`;
}
