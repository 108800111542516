import axios from "axios";

export class apiRight {
  //细度模数图表数据
  static getFinenessodulusChart(queryParams) {
    const query = queryParams;
    return axios({
      url: "/device/finenessModulusCharts/page",
      method: "GET",
      params: query,
    });
  }
  //颗粒级配（粗细）图表数据为空时调用接口
  static initializeChartData() {
    return axios({
      url: "/device/sample/initializeChartData",
      method: "POST"
    });
  }
}
