<template>
  <!--取样和样品取样弹框-->
  <a-modal
    :visible="props.visible"
    width="70%"
    wrap-class-name="qy-modal"
    centered
    class="registe-list"
    :closeIcon="true"
    @cancel="closeModal"
  >
    <template #title>
      <div class="modal-name">{{ registerName }}</div>
      <a-button class="close-btn" @click="closeModal">关闭 </a-button>
    </template>
    <div class="modlcont" style="padding: 0 0.5rem">
      <a-spin :spinning="loading">
        <a-form
          name="custom_form"
          ref="modalTitleRef"
          :model="formState1"
          @sumbmit="handleSubmit"
          :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
          :wrapper-col="{ span: 24 }"
          :rules="rules"
        >
          <a-row style="width: 100%">
            <a-col :span="12">
              <a-form-item label="执行标准" name="executionStandard">
                <div class="fromline sampsle" style="">
                  <a-select
                    v-model:value="formState1.executionStandard"
                    :options="executionStandardOptions"
                    @dropdownVisibleChange="searchExecutionStandard"
                    placeholder="请选择执行标准"
                    @change="executionChange"
                  >
                  </a-select>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="集料分类" name="aggregatesType">
                <div class="fromline">
                  <a-select
                    v-model:value="formState1.aggregatesType"
                    :options="typeOptions"
                    placeholder="请选择集料分类"
                    @change="handleTypeChange"
                  >
                  </a-select>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="规格型号" name="specificationMode">
                <div class="fromline">
                  <a-select
                    v-model:value="formState1.specificationMode"
                    :options="specificationModeOptions"
                    placeholder="请选择规格型号"
                    @dropdownVisibleChange="searchSpecificationMode"
                  >
                  </a-select>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="集料类别" name="aggregatesCategory">
                <div class="fromline">
                  <a-select
                    v-model:value="formState1.aggregatesCategory"
                    :options="aggregatesCategoryOptions"
                    placeholder="请选择集料类别"
                    allowClear
                  >
                  </a-select>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                label="检测依据"
                name="testingBasis"
                :labelCol="{ span: 3, offset: 0 }"
              >
                <div class="fromline sampsle" style="">
                  <a-select
                    v-model:value="formState1.testingBasis"
                    mode="multiple"
                    :max-tag-count="10"
                    :options="testingBasisOptions"
                    @dropdownVisibleChange="searchTestingBasis"
                    placeholder="请选择检测依据"
                  >
                  </a-select>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                label="判定依据"
                name="determineBasis"
                :labelCol="{ span: 3, offset: 0 }"
              >
                <div class="fromline sampsle" style="">
                  <a-select
                    v-model:value="formState1.determineBasis"
                    mode="multiple"
                    :max-tag-count="10"
                    :options="testingBasisOptions"
                    @dropdownVisibleChange="searchTestingBasis"
                    placeholder="请选择判定依据"
                  >
                  </a-select>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                label="供货单位"
                name="vendorId"
                :labelCol="{ span: 3, offset: 0 }"
              >
                <div class="fromline">
                  <a-select
                    v-model:value="formState1.vendorId"
                    :options="vendorOptions"
                    @dropdownVisibleChange="searchVendor"
                    placeholder="请选择供货单位"
                  >
                  </a-select>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="生产厂家" name="producerId">
                <div class="fromline">
                  <a-select
                    v-model:value="formState1.producerId"
                    :options="producerOptions"
                    @dropdownVisibleChange="searchProducer"
                    placeholder="请选择生产厂家"
                  >
                  </a-select>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="产地" name="producerPlace">
                <div class="fromline sampsle">
                  <a-auto-complete
                    v-model:value.trim="formState1.producerPlace"
                    :options="producerPlaceOptions"
                    placeholder="请输入产地"
                    :filter-option="filterPlaceOption"
                  />
                </div>
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <div
                style="
                  width: 100%;
                  height: 1px;
                  background-color: #fff;
                  margin-bottom: 24px;
                "
              ></div>
            </a-col>

            <a-col :span="12">
              <a-form-item label="出场批号" name="exitBatchNumber">
                <div class="fromline sampsle" style="">
                  <a-input
                    v-model:value="formState1.exitBatchNumber"
                    placeholder="请输入出场批号"
                  >
                  </a-input>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="出场日期" name="exitBatchTime">
                <div class="fromline sampsle" style="">
                  <a-date-picker
                    show-time
                    value-format="YYYY-MM-DD HH:mm:ss"
                    v-model:value="formState1.exitBatchTime"
                    placeholder="请选择出场日期"
                    :disabled-date="disabledDate"
                    :disabled-time="disabledDateTime"
                  />
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="取样日期" name="samplingTime">
                <div class="fromline sampsle" style="">
                  <a-date-picker
                    show-time
                    v-model:value="formState1.samplingTime"
                    value-format="YYYY-MM-DD HH:mm:ss"
                    placeholder="请选择取样日期"
                    :disabled-date="disabledDate"
                    :disabled-time="disabledDateTime"
                  />
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="取样地点" name="samplingLocation">
                <div class="fromline sampsle" style="">
                  <a-auto-complete
                    v-model:value.trim="formState1.samplingLocation"
                    :options="samplingLocationOptions"
                    placeholder="请输入取样地点"
                    :filter-option="filterSamplingOption"
                  />
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="代表数量" name="representativeQuantity">
                <div class="fromline sampsle" style="">
                  <a-input
                    v-model:value="formState1.representativeQuantity"
                    placeholder="请输入代表数量"
                  >
                  </a-input>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="检测单位" name="testingUnitName">
                <div class="fromline">
                  <a-select
                    v-model:value="formState1.testingUnitName"
                    :options="testingUnitNameOptions"
                    placeholder="请选择检测单位"
                    @dropdownVisibleChange="searchTestingUnitNameMode"
                  >
                  </a-select>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item
            label="检测项目(多选)"
            name="combination"
            :labelCol="{ span: 4, offset: 0 }"
          >
            <div class="agrund">
              <a-checkbox-group
                style="width: 100%"
                v-model:value="formState1.detectionArray"
                name="checkboxgroup"
                @change="detectionChange"
              >
                <a-row>
                  <a-col v-for="option in detectionOptions" :key="option.value">
                    <a-checkbox
                      :disabled="option.disabled"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </div>
          </a-form-item>
          <a-form-item
            label="亚甲蓝检测方式"
            name="detectionWay"
            v-if="hasMethyleneBlue"
            :labelCol="{ span: 4, offset: 0 }"
          >
            <div class="agrund">
              <a-radio-group
                style="width: 100%"
                v-model:value="formState1.detectionMethod"
                :options="methyleneOptions"
              />
            </div>
          </a-form-item>
        </a-form>
        <m-dynamic-form :formItems="formItems" :data="data" ref="dynamicForm" />
      </a-spin>
    </div>
    <template #footer>
      <div class="footer">
        <a-form-item
          label="自动取样"
          name="automaticSampling"
          class="footer-sample"
        >
          <a-switch v-model:checked="formState1.samplingManual" :disabled="samplingDisable" @change="handleSamplingChange" />
        </a-form-item>
        <div class="footes" @click="handleSubmit">
          <div class="mbtns stars">
            <span>确定</span>
          </div>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script setup>
import { ref, reactive, defineProps, onMounted, defineEmits } from "vue";
import { apiCenterBottom } from "@/api/api-center-bottom/api-center-bottom.js";
import { message } from "ant-design-vue";
// import { apiMainfoots } from "@/api/api-main-foots/api-main-foots.js";
import { apiTopHeader } from "@/api/api-topHeader.js";
import MDynamicForm from "@/components/MDynamicForm/index.vue";
import dayjs from "dayjs";
import {
  // categoryOptions,
  typeOptions,
  aggregatesCategoryOptions,
} from "../../js/opts.group.js";

let mproducerPlaceData = localStorage.getItem("producerPlaces-maoqiapjlx");
let samplingLocationData = localStorage.getItem("samplingLocationsa-maoqiapjlx");

let btntp = ref(false);

const dynamicForm = ref();
const formItems = ref([]);
const data = ref({});
const emits = defineEmits(["update:visible", "refreshSamplist"]);
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
    default() {
      return false;
    },
  },
  registerName: {
    type: String,
    required: true,
    default() {
      return "";
    },
  },
  detailId: {
    type: [String, Number],
    default() {
      return "";
    },
  },
});
//取样和样品登记的组件实例值
let modalTitleRef = ref();
let sampleDetail = ref({});
let loading = ref(false);

// 检测组合复选框option数据
const detectionOptions = ref([
  { label: "含水率", value: "含水率", disabled: false },
  { label: "含泥量", value: "含泥量", disabled: false },
  { label: "颗粒级配(粗集料)", value: "颗粒级配(粗集料)", disabled: false },
  { label: "颗粒级配(细集料)", value: "颗粒级配(细集料)", disabled: false },
  { label: "亚甲蓝值", value: "亚甲蓝值", disabled: false },
]);
// 亚甲蓝检测方式option数据
const methyleneOptions = [
  { label: "快速检测", value: "快速检测" },
  { label: "标准检测", value: "标准检测" },
  { label: "快速(标)检测", value: "快速(标)检测" },
];
// 是否选中亚甲蓝
const hasMethyleneBlue = ref(false);
// 所有检测组合以及对应的组合号
const allCombinations = {
  2: ["含水率"],
  3: ["含水率", "含泥量"],
  4: ["含水率", "含泥量", "颗粒级配(细集料)"],
  5: ["含水率", "含泥量", "颗粒级配(粗集料)"],
  6: ["含水率", "颗粒级配(细集料)"],
  7: ["取样"],
  8: ["颗粒级配(粗集料)"],
  9: ["取样","含水率"],
  10: ["取样","含水率", "含泥量"],
  11: ["取样","含水率", "含泥量", "颗粒级配(细集料)"],
  12: ["取样","含水率", "含泥量", "颗粒级配(粗集料)"],
  13: ["取样","含水率", "颗粒级配(细集料)"],
  14: ["含泥量", "颗粒级配(粗集料)"],
  15: ["取样","含泥量", "颗粒级配(粗集料)"],
};


const allStandardBasisdata = ref();

//取样和样品登记-----取样登记数据
let formState1 = reactive({
  // testingUnit: undefined, //检测单位
  vendorId: undefined, //供货单位
  aggregatesType: undefined, //集料分类
  specificationMode: undefined, //规格型号
  aggregatesCategory: undefined, //集料类别
  testingUnitName: undefined, //检测单位
  producerId: undefined, //生产厂家
  producerPlace: undefined, //产地
  executionStandard: undefined, //执行标准
  testingBasis: undefined, //检测依据
  determineBasis: undefined, //判定依据
  exitBatchNumber: undefined, //出厂批号
  exitBatchTime: undefined, //出场日期
  samplingTime: undefined, //取样日期
  samplingLocation: undefined, //取样地点
  representativeQuantity: undefined, //代表数量
  inspectTime: undefined, //检验日期
  detectionArray: [], //选择的检测组合
  detectionMethod: null, //亚甲蓝检测方式
  samplingManual: false, //是否自动取样
});
const samplingDisable = ref(false)
// 系统配置数据
const configurationValue = ref({
  moistureContent: true, //含水率
  mudContent: true, //含泥率
  particleMatchingDevice: true, //颗粒级配（粗）
  finenessModulusDevice: true, //颗粒级配（细）
});

let aggregatesTypeParent = ref([]);
let aggregatesType = ref([]);
// 获取的所有的集料规格
let aggregate = ref([]);
//取样和样品登记-----产地历史信息
let producerPlaceOptions = ref([
  // { value: "Burns Bay Road" },
  // { value: "Downing Street" },
  // { value: "Wall Street" },
]);
//取样和样品登记-----取样地点历史信息
let samplingLocationOptions = ref([]);

//取样和样品登记-----集料规格组合详细信息
let aggregateDetail = reactive({});

//取样和样品登记-----供应商后面展示的生产商
let producer = reactive({});

//取样和样品登记-----供货单位数据字典源
let vendorOptions = ref([]);

//取样和样品登记-----规格型号数据字典源
let specificationModeOptions = ref([]);

//取样和样品登记-----检测单位数据字典源
let testingUnitNameOptions = ref([]);

//取样和样品登记-----生产厂家数据字典源
let producerOptions = ref([]);

//取样和样品登记-----执行标准数据字典源
let executionStandardOptions = ref([]);
const executionStandardName = ref();

//取样和样品登记-----检测依据数据字典源
let testingBasisOptions = ref([]);

//取样和样品登记-----检测组合号
let combinationNumber = ref(null);

//取样和样品登记-----是否为取样登记（1--取样登记，0--样品登记）
let samplingManual = ref(null);

//取样和样品登记-----自定义校验（供货单位）
let validateVendor = async (_rule, value) => {
  if (value === "" || value === undefined) {
    return Promise.reject("请选择供货单位");
  }
  return Promise.resolve();
};
//取样和样品登记-----自定义校验（集料分类）
let validateAggregatesType = async (_rule, value) => {
  if (value === "" || value === undefined) {
    return Promise.reject("请选择集料分类");
  }
  return Promise.resolve();
};

//取样和样品登记-----自定义校验（检测单位）
let validateTestingUnitName = async (_rule, value) => {
  if (value === "" || value === undefined) {
    return Promise.reject("请选择检测单位");
  }
  return Promise.resolve();
};

//取样和样品登记-----自定义校验（规格型号）
let validateSpecificationMode = async (_rule, value) => {
  if (value === "" || value === undefined) {
    return Promise.reject("请选择规格型号");
  }
  return Promise.resolve();
};

//取样和样品登记-----自定义校验（生产厂家）
let validateProducerId = async (_rule, value) => {
  if (value === "" || value === undefined) {
    return Promise.reject("请选择生产厂家");
  }
  return Promise.resolve();
};

//取样和样品登记-----自定义校验（产地）
let validateProducerPlace = async (_rule, value) => {
  if (value === "" || value === undefined) {
    return Promise.reject("请输入产地");
  }
  return Promise.resolve();
};

//取样和样品登记-----自定义校验（执行标准）
let validateExecutionStandard = async (_rule, value) => {
  if (value === "" || value === undefined) {
    return Promise.reject("请选择执行标准");
  }
  return Promise.resolve();
};

//取样和样品登记-----自定义校验（检测依据）
let validateTestingBasis = async (_rule, value) => {
  if (value === "" || value === undefined) {
    return Promise.reject("请选择检测依据");
  }
  return Promise.resolve();
};

//取样和样品登记-----自定义校验（判定依据）
let validateDetermineBasis = async (_rule, value) => {
  if (value === "" || value === undefined) {
    return Promise.reject("请选择判定依据");
  }
  return Promise.resolve();
};

//取样和样品登记-----自定义校验（检测组合）
let validateCombination = async () => {
  // 如果只选择亚甲蓝也可以检测
  if (
    formState1.detectionArray.length == 1 &&
    formState1.detectionArray[0] == "亚甲蓝值"
  ) {
    return Promise.resolve();
  }
  if (combinationNumber.value == null) {
    return Promise.reject("请选择符合要求的检测组合");
  }
  return Promise.resolve();
};
//取样和样品登记-----自定义校验（亚甲蓝检测方式）
let validateDetectionWay = async () => {
  if (formState1.detectionMethod == null) {
    return Promise.reject("请选择亚甲蓝检测方式");
  }
  return Promise.resolve();
};
// 取样和样品登记取样校验规则
const rules = {
  // 供货单位
  vendorId: [
    {
      required: true,
      validator: validateVendor,
      trigger: "blur",
    },
  ],
  // 集料分类
  aggregatesType: [
    {
      required: true,
      validator: validateAggregatesType,
      trigger: "blur",
    },
  ],
  // 规格型号
  specificationMode: [
    {
      required: true,
      validator: validateSpecificationMode,
      trigger: "blur",
    },
  ],
  //检测单位
  testingUnitName: [
    {
      required: true,
      validator: validateTestingUnitName,
      trigger: "blur",
    },
  ],

  //生产厂家
  producerId: [
    {
      required: true,
      validator: validateProducerId,
      trigger: "blur",
    },
  ],
  //产地
  producerPlace: [
    {
      required: true,
      validator: validateProducerPlace,
      trigger: "blur",
    },
  ],
  //执行标准
  executionStandard: [
    {
      required: true,
      validator: validateExecutionStandard,
      trigger: "blur",
    },
  ],
  //检测依据
  testingBasis: [
    {
      required: true,
      validator: validateTestingBasis,
      trigger: "blur",
    },
  ],
  //判定依据
  determineBasis: [
    {
      required: true,
      validator: validateDetermineBasis,
      trigger: "blur",
    },
  ],
  // 检测组合
  combination: [
    {
      required: true,
      validator: validateCombination,
    },
  ],
  // 亚甲蓝检测方式
  detectionWay: [
    {
      required: true,
      validator: validateDetectionWay,
    },
  ],
};

let tempDetectionArray = ref([]);

onMounted(async () => {
  // await getSystemConfig();
  await getSystemConfigall();
  searchTestingBasis();
  banDetection();

  mproducerPlaceData = localStorage.getItem("producerPlaces-maoqiapjlxas");
  samplingLocationData = localStorage.getItem(
    "samplingLocationsa-maoqiapjlxas"
  );
  if (props.detailId) {
    loading.value = true;

    try {
      // 获取检测记录信息
      let res = await apiCenterBottom.getRegisteSampleInfo(props.detailId);
      if (res.data.code == 0) {
        sampleDetail.value = res.data.data;
        echoData(sampleDetail.value);
      }
    } catch (error) {
      console.error(error);
      message.error("获取样品数据失败", 2);
    }
  } else {
    await searchExecutionStandard();
    getJsonData(formState1.executionStandard, formState1.aggregatesType);
    setTimeout(() => {
      loading.value = true;
      echoaddData();
    }, 600);
  }

  //初始化设置产地历史值
  if (mproducerPlaceData) {
    let mdas = JSON.parse(mproducerPlaceData);
    producerPlaceOptions.value = mdas;
  }
  //初始化设置取样地点历史值
  if (samplingLocationData) {
    let mdas = JSON.parse(samplingLocationData);
    samplingLocationOptions.value = mdas;
  }
});

const getSystemConfigall = async () => {
  try {
    let res = await apiTopHeader.getAllConfig();
    let configData = res.data.data;
    if (configData !== "" && configData !== undefined && configData !== null) {
      //检测项目
      let mdetectionItemConfig = configData.find((item) => {
        return item.configurationName == "detectionItemConfig";
      });
      Object.assign(
        configurationValue.value,
        JSON.parse(mdetectionItemConfig.configurationValue)
      );
      //执行标准选择
      let mexecutionStandard = configData.find((item) => {
        return item.configurationName == "executionStandard";
      });
      let mpexe = mexecutionStandard.configurationValue
        ? JSON.parse(mexecutionStandard.configurationValue)
        : "";
      formState1.executionStandard = mpexe.value ? mpexe.value : undefined;
      //检测单位
      let mdefaultTestingUnit = configData.find((item) => {
        return item.configurationName == "defaultTestingUnit";
      });
      let munit = mdefaultTestingUnit.configurationValue
        ? JSON.parse(mdefaultTestingUnit.configurationValue)
        : "";
      formState1.testingUnitName = munit.label ? munit.label : undefined;

      let mexecutionStandards = configData.find((item) => {
        return item.configurationName == "executionStandards";
      });
      let mvlies = mexecutionStandards.configurationValue
        ? JSON.parse(mexecutionStandards.configurationValue)
        : "";

      for (let i = 0; i < mvlies.length; i++) {
        mvlies[i].basis.determineBasisC =
          mvlies[i].basis.determineBasisC?.split(",");
        mvlies[i].basis.determineBasisX =
          mvlies[i].basis.determineBasisX?.split(",");
        mvlies[i].basis.inspectionBasisC =
          mvlies[i].basis.inspectionBasisC?.split(",");
        mvlies[i].basis.inspectionBasisX =
          mvlies[i].basis.inspectionBasisX?.split(",");

        mvlies[i].basis.determineBasisC = mvlies[i].basis.determineBasisC.map(
          (item) => {
            return Number(item);
          }
        );
        mvlies[i].basis.determineBasisX = mvlies[i].basis.determineBasisX.map(
          (item) => {
            return Number(item);
          }
        );
        mvlies[i].basis.inspectionBasisC = mvlies[i].basis.inspectionBasisC.map(
          (item) => {
            return Number(item);
          }
        );
        mvlies[i].basis.inspectionBasisX = mvlies[i].basis.inspectionBasisX.map(
          (item) => {
            return Number(item);
          }
        );
      }

      allStandardBasisdata.value = mvlies;
    }
  } catch (error) {
    console.log(error);
    message.error(error.data.message, 2);
  }
};
/**
 * @introduction 根据系统参数禁用检测组合
 */
const banDetection = () => {
  samplingDisable.value = !configurationValue.value?.sampling
  detectionOptions.value.forEach((item) => {
    switch (item.label) {
      case '含水率':
        item.disabled = !configurationValue.value?.moistureContent
        break;
      case '含泥量':
        item.disabled = !configurationValue.value?.mudContent
        break;
      case '颗粒级配(粗集料)':
        item.disabled = !configurationValue.value?.particleMatchingDevice
        break;
      case '颗粒级配(细集料)':
        item.disabled = !configurationValue.value?.finenessModulusDevice
        break;
      case '亚甲蓝值':
        item.disabled = !configurationValue.value?.methyleneBlue
        break;
    }
  });
};
/**
 * @introduction 添加时回显上次数据
 */
const echoaddData = () => {
  let mdatas = localStorage.getItem("infofrom-maoqiaoypdj");
  if (mdatas) {
    let mdatinfo = JSON.parse(mdatas);
    // console.log(mdatinfo);
    for (const key in allCombinations) {
      if (key == mdatinfo.combinationNumber) {
        tempDetectionArray.value = [...allCombinations[key]];
        combinationNumber.value = key;
      }
      // 如果其中有禁用的项则不赋值给选中检测组合数据
      // 创建检测项与配置值的映射对象
      const detectionConfigMap = {
        '含水率': 'moistureContent',
        '取样': 'sampling',
        '含泥量': 'mudContent',
        '颗粒级配(粗集料)': 'particleMatchingDevice',
        '颗粒级配(细集料)': 'finenessModulusDevice'
      };
      // 使用映射对象进行过滤
      tempDetectionArray.value = tempDetectionArray.value.filter((detectItem) => {
        const configKey = detectionConfigMap[detectItem];
        return configKey && configurationValue.value?.[configKey];
      });
    }
    // 重新根据没禁用的组合判断组合号
    combinationNumber.value = Object.keys(allCombinations).find(
      (key) =>
        tempDetectionArray.value.length === allCombinations[key].length &&
        tempDetectionArray.value.every((item) => allCombinations[key].includes(item))
    );
    // console.log("🚀 ~ echoaddData ~ combinationNumber.value:", combinationNumber.value)

    let mfidx = mdatinfo.aggregatesSpecs.indexOf("-");
    let jllx = mdatinfo.aggregatesSpecs.slice(0, mfidx);
    

    let mjlfr = mdatinfo.aggregatesSpecs.slice(mfidx + 1);
    let mfidx2 = mjlfr.indexOf("-");
    let jllx2 = mjlfr.slice(0, mfidx2);

    let jllx3 = mjlfr.slice(mfidx2 + 1);

    let mjlstr = jllx + "-" + jllx2;

    getJsonData(mdatinfo.executionStandard, mjlstr);
    searchVendor();

    // //父级
    aggregatesTypeParent.value = jllx;
    // //子级
    aggregatesType.value = jllx2;
    searchSpecificationMode();
    searchProducer();
    searchExecutionStandard();
    searchTestingBasis();
    searchTestingUnitNameMode();
    banClick(aggregatesTypeParent.value);
    // 亚甲蓝没被禁用才赋值
    if (mdatinfo.detectionMethod && configurationValue.value?.methyleneBlue) {
      hasMethyleneBlue.value = true;
      tempDetectionArray.value.push("亚甲蓝值");
        Object.assign(formState1, {
          detectionMethod: mdatinfo.detectionMethod, //亚甲蓝检测方式
        });
    }
    // 自动取样没被禁用才赋值
    if (configurationValue.value?.sampling) {
      Object.assign(formState1, {
        samplingManual: mdatinfo.samplingManual == 1, //是否自动取样
      });
    }


    setTimeout(() => {
      //获取供货单位id
      
      let mvenid = vendorOptions.value.find((res) => {
        return res.label == mdatinfo.supplierName;
      });
      //获取生产厂家id
      let mproducerId = producerOptions.value.find((res) => {
        return res.label == mdatinfo.producerName;
      });
      //获取检测标准id
      let mexecutionStandard = executionStandardOptions.value.find((res) => {
        return res.svalue == mdatinfo.executionStandard;
      });
      //检测依据
      let mtestingBasis = mdatinfo.testingBasisIds.split(",");
      for (let i in mtestingBasis) {
        mtestingBasis[i] = Number(mtestingBasis[i]);
      }
      let mhxdats = [];
      // console.log(testingBasisOptions.value,'5555555555555');

      for (let j = 0; j < mtestingBasis.length; j++) {
        for (let i = 0; i < testingBasisOptions.value.length; i++) {
          if (testingBasisOptions.value[i].value == mtestingBasis[j]) {
            mhxdats.push(mtestingBasis[j]);
          }
        }
      }

      //判定依据
      let mdetermineBasis = mdatinfo.determineBasisIds.split(",");
      for (let i in mdetermineBasis) {
        mdetermineBasis[i] = Number(mdetermineBasis[i]);
      }
      let mhxdatsa = [];
      // console.log(testingBasisOptions.value,'5555555555555');

      for (let j = 0; j < mdetermineBasis.length; j++) {
        for (let i = 0; i < testingBasisOptions.value.length; i++) {
          if (testingBasisOptions.value[i].value == mdetermineBasis[j]) {
            mhxdatsa.push(mdetermineBasis[j]);
          }
        }
      }


      let mexitBatchTime = dayjs().format('YYYY-MM-DD HH:mm:ss');  
        
      let msamplingTime = dayjs().format('YYYY-MM-DD HH:mm:ss');     
        

      // 回显其他数据
      Object.assign(formState1, {
        vendorId: mvenid?.value || '', //供货单位
        aggregatesType: mjlstr, //集料分类
        specificationMode: jllx3, //规格型号
        aggregatesCategory: mdatinfo.aggregatesCategory, //集料类别
        testingUnitName: mdatinfo.testingUnitName, //检测单位
        producerId: mproducerId?.value || '', //生产厂家
        producerPlace: mdatinfo.producerPlace, //产地
        executionStandard: mexecutionStandard.value, //检测标准
        testingBasis: mhxdats, //检测依据
        determineBasis: mhxdatsa, //判定依据
        exitBatchNumber: mdatinfo.factoryNumber, //出场批号
        exitBatchTime: mexitBatchTime, //出场日期
        samplingTime: msamplingTime, //取样日期
        samplingLocation: mdatinfo.samplingLocation, //取样地点
        representativeQuantity: mdatinfo.representativeQuantity, //代表数量
        detectionArray: tempDetectionArray.value, //选择的检测组合
      });
      executionStandardName.value = mdatinfo.executionStandardName; //执行标准中文
      data.value = JSON.parse(mdatinfo.extendedParameter);
      loading.value = false;
    }, 500);

  } else {
      loading.value = false;
  }
};

/**
 * @introduction 编辑时回显数据
 */
const echoData = (newValue) => {
  // console.log('编辑', newValue);
  if (newValue) {
    // 根据组合号回显检测组合
    for (const key in allCombinations) {
      if (key == newValue.combinationNumber) {
        tempDetectionArray.value = [...allCombinations[key]];
        combinationNumber.value = key;
      }
    }
   
    //获取集料规格数据

    let mfidx = newValue.aggregatesSpecs.indexOf("-");
    let jllx = newValue.aggregatesSpecs.slice(0, mfidx);

    let mjlfr = newValue.aggregatesSpecs.slice(mfidx + 1);
    let mfidx2 = mjlfr.indexOf("-");
    let jllx2 = mjlfr.slice(0, mfidx2);

    let jllx3 = mjlfr.slice(mfidx2 + 1);

    let mjlstr = jllx + "-" + jllx2;
    console.log(jllx2);
    searchVendor();
    getJsonData(newValue.executionStandard, mjlstr);
    // //父级
    aggregatesTypeParent.value = jllx;
    // //子级
    aggregatesType.value = jllx2;
    searchSpecificationMode();
    searchProducer();
    searchExecutionStandard();
    searchTestingBasis();
    searchTestingUnitNameMode();
    banClick(aggregatesTypeParent.value);
  
    //回显亚甲蓝数据
    if (newValue.detectionMethod) {
      hasMethyleneBlue.value = true;
      tempDetectionArray.value.push("亚甲蓝值");
      Object.assign(formState1, {
        detectionMethod: newValue.detectionMethod, //亚甲蓝检测方式
      });
    }
    Object.assign(formState1, {
      samplingManual: newValue.samplingManual == 1, //是否自动取样
    });

    setTimeout(() => {
      //获取供货单位id
      let mvenid = vendorOptions.value.find((res) => {
        return res.label == newValue.supplierName;
      });
      //获取生产厂家id
      let mproducerId = producerOptions.value.find((res) => {
        return res.label == newValue.producerName;
      });
      //获取检测标准id
      let mexecutionStandard = executionStandardOptions.value.find((res) => {
        return res.svalue == newValue.executionStandard;
      });
      //检测依据
      let mtestingBasis = newValue.testingBasisIds.split(",");
      for (let i in mtestingBasis) {
        mtestingBasis[i] = Number(mtestingBasis[i]);
      }
      let mhxdats = [];
      // console.log(testingBasisOptions.value,'5555555555555');

      for (let j = 0; j < mtestingBasis.length; j++) {
        for (let i = 0; i < testingBasisOptions.value.length; i++) {
          if (testingBasisOptions.value[i].value == mtestingBasis[j]) {
            mhxdats.push(mtestingBasis[j]);
          }
        }
      }

      //判定依据
      let mdetermineBasis = newValue.determineBasisIds.split(",");
      for (let i in mdetermineBasis) {
        mdetermineBasis[i] = Number(mdetermineBasis[i]);
      }
      let mhxdatsa = [];
      // console.log(testingBasisOptions.value,'5555555555555');

      for (let j = 0; j < mdetermineBasis.length; j++) {
        for (let i = 0; i < testingBasisOptions.value.length; i++) {
          if (testingBasisOptions.value[i].value == mdetermineBasis[j]) {
            mhxdatsa.push(mdetermineBasis[j]);
          }
        }
      }

      let mexitBatchTime = newValue.productionDate
        ? dayjs(newValue.productionDate, "YYYY/MM/DD HH:mm:ss")
        : "";
      let msamplingTime = newValue.samplingTime
        ? dayjs(newValue.samplingTime, "YYYY/MM/DD HH:mm:ss")
        : "";
      // let mdetectDatetime = newValue.detectDatetime
      //   ? dayjs(newValue.detectDatetime, "YYYY/MM/DD HH:mm:ss")
      //   : "";

      // 回显其他数据
      Object.assign(formState1, {
        vendorId: mvenid.value || '', //供货单位
        aggregatesType: mjlstr, //集料分类
        specificationMode: jllx3, //规格型号
        aggregatesCategory: newValue.aggregatesCategory, //集料类别
        testingUnitName: newValue.testingUnitName, //检测单位
        producerId: mproducerId.value || '', //生产厂家
        producerPlace: newValue.producerPlace, //产地
        executionStandard: mexecutionStandard.value, //检测标准
        testingBasis: mhxdats, //检测依据
        determineBasis: mhxdatsa, //判定依据
        exitBatchNumber: newValue.factoryNumber, //出场批号
        exitBatchTime: mexitBatchTime, //出场日期
        samplingTime: msamplingTime, //取样日期
        samplingLocation: newValue.samplingLocation, //取样地点
        representativeQuantity: newValue.representativeQuantity, //代表数量
        detectionArray: tempDetectionArray.value, //选择的检测组合
      });
      executionStandardName.value = newValue.executionStandardName; //执行标准中文
      data.value = JSON.parse(newValue.extendedParameter);
      loading.value = false;
    }, 800);
  }
};

/**
 * @name 郭冲
 * @Date 2023-05-08 14:31:22
 * @introduction 关闭取样或样品登记弹窗
 * @description 清空数据和校验
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const closeModal = () => {
  emits("update:visible", false);

  combinationNumber.value = null;
  detectionOptions.value.forEach((item) => {
    item.disabled = false;
  });
  Object.keys(formState1).map((key) => {
    delete formState1[key];
  });
  hasMethyleneBlue.value = false;
  vendorOptions.value.length = 0;
  specificationModeOptions.value.length = 0;
  testingUnitNameOptions.value.length = 0;
  producerOptions.value.length = 0;
  producerPlaceOptions.value.length = 0;
  executionStandardOptions.value.length = 0;
  samplingLocationOptions.value.length = 0;
  testingBasisOptions.value.length = 0;

  samplingManual.value = null;
  Object.keys(aggregateDetail).map((key) => {
    delete aggregateDetail[key];
  });
  Object.keys(producer).map((key) => {
    delete producer[key];
  });
  aggregate.value.length = 0;
  modalTitleRef.value.resetFields();
};
/**
 * @introduction 表单校验
 */
const validateForm = async () => {
  try {
    await modalTitleRef.value.validate();
    return Promise.resolve(true);
  } catch (error) {
    modalTitleRef.value.scrollToField(error.errorFields[0].name, {
      behavior: "smooth",
      block: "start",
    });
    return Promise.resolve(false);
  }
};
/**
 * @introduction 动态表单校验
 */
const validateDynamicForm = async () => {
  try {
    await dynamicForm.value.formRef.validate();
    return Promise.resolve(true);
  } catch (error) {
    dynamicForm.value.formRef.scrollToField(error.errorFields[0].name, {
      behavior: "smooth",
      block: "start",
    });
    return Promise.resolve(false);
  }
};
/**
 * @introduction 取样或样品登记确认事件
 */
const handleSubmit = async () => {
  if (btntp.value) {
    return;
  }

  props.registerName == "取样登记" || props.registerName == "编辑-取样登记"
    ? (samplingManual.value = 1)
    : (samplingManual.value = 0);

  const valid = await validateForm();
  console.log("🚀 ~ handleSubmit ~ valid:", valid)
  const validDynamic = await validateDynamicForm();
  if (!valid || !validDynamic) return;
  let supplierItem = vendorOptions.value.filter(
    (item) => item.value === formState1.vendorId
  );

  let producerItem = producerOptions.value.find(
    (item) => item.value === formState1.producerId
  );

  let executionStandardItem = executionStandardOptions.value.find(
    (item) => item.value === formState1.executionStandard
  );
  let mtevas = formState1.testingBasis;
  let tebasarr = [];
  for (let k in mtevas) {
    tebasarr.push(mtevas[k]);
  }

  let mtevas1 = formState1.determineBasis;
  let tebasarr1 = [];
  for (let k in mtevas1) {
    tebasarr1.push(mtevas1[k]);
  }

  let mexitBatimes = "";
  let msamplingTimetimes = "";
  if (formState1.exitBatchTime) {
    mexitBatimes = dayjs(formState1.exitBatchTime).format(
      "YYYY-MM-DD HH:mm:ss"
    );
  }
  if (formState1.samplingTime) {
    msamplingTimetimes = dayjs(formState1.samplingTime).format(
      "YYYY-MM-DD HH:mm:ss"
    );
  }

  let params = {
    id: sampleDetail.value?.id,
    supplierName: supplierItem[0].label, //供货单位
    aggregatesSpecs: `${formState1.aggregatesType}-${formState1.specificationMode}`, // 集料组装数据(粗集料-碎砂-10-20mm)
    aggregatesCategory: formState1.aggregatesCategory, //集料类别
    testingUnitName: formState1.testingUnitName, //检测单位
    producerName: producerItem.label, //生产厂家
    producerPlace: formState1.producerPlace, //产地
    executionStandard: executionStandardItem.svalue, //检测标准
    executionStandardName: executionStandardName.value, //检测标准中文
    testingBasisIds: tebasarr.join(","), //检测依据
    determineBasisIds: tebasarr1.join(","), //判定依据
    factoryNumber: formState1.exitBatchNumber || "", //出场批号
    productionDate: mexitBatimes, //出场日期
    samplingTime: msamplingTimetimes, //取样日期
    samplingLocation: formState1.samplingLocation || "", //取样地点
    representativeQuantity: formState1.representativeQuantity || "", //代表数量
    combinationNumber: Number(combinationNumber.value), // 检测组合号
    detectionMethod: formState1.detectionMethod, //亚甲蓝检测方式
    //动态表单json数据
    extendedParameter: JSON.stringify(dynamicForm.value.formData),
    samplingManual: formState1.samplingManual ? 1 : 0,
  };

  btntp.value = true;
  loading.value = true;
  // 确认登记
  apiCenterBottom
    .confirmRegiste(params)
    .then((res) => {
      if (res.data.code == 0) {
        sampleDetail.value?.id
          ? message.success("修改成功", 2)
          : message.success("添加成功", 2);
        setPles();
        setSamplingLocationData();

        closeModal();
        // 添加或编辑成功之后刷新表格数据
        emits("refreshSamplist");

        if (!sampleDetail.value?.id) {
          let mdas = JSON.stringify(params);
          localStorage.setItem("infofrom-maoqiaoypdj", mdas);
        }

        btntp.value = false;
        loading.value = false;
      }
    })
    .catch((error) => {
      loading.value = false;
      btntp.value = false;
      console.log(error);
      message.error(error.data.message, 2);
    });
};

const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current > dayjs().endOf("day");
};
const range = (start, end) => {
  const result = [];

  for (let i = start; i < end; i++) {
    result.push(i);
  }

  return result;
};
const disabledDateTime = () => {
  let mdata = new Date();
  let mh = mdata.getHours();
  let mm = mdata.getMinutes();
  let ms = mdata.getSeconds();

  return {
    disabledHours: () => range(mh + 1, 24),
    disabledMinutes: () => range(mm + 1, 60),
    disabledSeconds: () => range(ms, 60),
  };
};

//设置产地历史值
const setPles = () => {
  let mpls = [];
  if (mproducerPlaceData) {
    let mprsad = JSON.parse(mproducerPlaceData);
    mpls = [...mprsad];

    let mfids = mprsad.find((item) => {
      return item.value == formState1.producerPlace;
    });
    if (!mfids && formState1.producerPlace && formState1.producerPlace != "") {
      let mqrs = {
        value: formState1.producerPlace,
      };
      mpls.unshift(mqrs);
      let msetdata = mpls;
      if (mpls.length > 50) {
        msetdata = mpls.filter((item, index) => {
          return index < 50;
        });
      }
      let mdas = JSON.stringify(msetdata);
      console.log(mdas);
      localStorage.setItem("producerPlaces-maoqiapjlxas", mdas);
    }
  } else {
    let mqrs = {
      value: formState1.producerPlace,
    };
    mpls.push(mqrs);
    let mdas = JSON.stringify(mpls);
    localStorage.setItem("producerPlaces-maoqiapjlxas", mdas);
  }
};

//设置取样地址历史值
const setSamplingLocationData = () => {
  let mpls = [];
  if (
    formState1.samplingLocation == "" ||
    formState1.samplingLocation == undefined
  ) {
    return;
  }

  if (samplingLocationData) {
    let mprsad = JSON.parse(samplingLocationData);
    mpls = [...mprsad];

    let mfids = mprsad.find((item) => {
      return item.value == formState1.samplingLocation;
    });

    if (
      !mfids &&
      formState1.samplingLocation &&
      formState1.samplingLocation != ""
    ) {
      let mqrs = {
        value: formState1.samplingLocation,
      };
      mpls.unshift(mqrs);
      let msetdata = mpls;
      if (mpls.length > 50) {
        msetdata = mpls.filter((item, index) => {
          return index < 50;
        });
      }
      let mdas = JSON.stringify(msetdata);
      console.log(mdas);
      localStorage.setItem("samplingLocationsa-maoqiapjlxas", mdas);
    }
  } else {
    let mqrs = {
      value: formState1.samplingLocation,
    };
    mpls.push(mqrs);
    let mdas = JSON.stringify(mpls);
    console.log(mdas);
    localStorage.setItem("samplingLocationsa-maoqiapjlxas", mdas);
  }
};

/**
 * @introduction 切换检测组合事件
 */
const detectionChange = (newArray) => {
  formState1.detectionArray = newArray;
  // 设置亚甲蓝检测方式的显示与隐藏
  hasMethyleneBlue.value = formState1.detectionArray.includes("亚甲蓝值");
  //取消亚甲蓝选中时清空检测方式
  if (!hasMethyleneBlue.value) {
    formState1.detectionMethod = null;
  }
  // 如果选中的有亚甲蓝选项，将其筛除后再去判断组合号
  let selectArray = [...formState1.detectionArray].filter(item => { 
    if (item === "亚甲蓝值") {
      formState1.detectionMethod = null;
      return false;
    }
    if (item === "取样") {
      return false;
    }
    return true;
  });
  if (formState1.samplingManual) {
    // 如果是自动取样
    selectArray.push("取样");
  } else {
    // 删除取样
    selectArray = selectArray.filter((item) => item !== "取样");
  }
  // console.log("🚀 ~ detectionChange ~ selectArray:", selectArray)

  // 根据选中的组合判断组合号
  combinationNumber.value = Object.keys(allCombinations).find(
  (key) =>
    selectArray.length === allCombinations[key].length &&
    selectArray.every((item) => allCombinations[key].includes(item))
  );
  // console.log(
  //   '🚀 ~ file: menuPop.vue:734 ~ detectionChange ~ 组合号:',
  //   combinationNumber.value
  // );
};

/**
 * @name guochong
 * @Date 2025-03-07 09:57:43
 * @introduction 切换自动取样事件
 * @description 详细描述
 */
const handleSamplingChange = (val) => {
  detectionChange(formState1.detectionArray)
}

//产地自动输入匹配
const filterPlaceOption = (input, option) => {
  return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;
};

//取样地点自动输入匹配
const filterSamplingOption = (input, option) => {
  return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;
};
//处理集料分类选择
const handleTypeChange = (val) => {
  //获取动态表单
  getJsonData(formState1.executionStandard, val);
  let aggregatesTypeGroup = val.split("-");
  // //父级
  aggregatesTypeParent.value = aggregatesTypeGroup[0];
  // //子级
  aggregatesType.value = aggregatesTypeGroup[1];

  // if (!props.detailId) {
  formState1.detectionArray = [];
  hasMethyleneBlue.value = false;
  combinationNumber.value = null;
  // }

  formState1.specificationMode = undefined;
  formState1.aggregatesCategory = undefined;
  formState1.producerId = undefined;
  formState1.producerPlace = undefined;

  let allstbas = allStandardBasisdata.value;
  let mckbz = allstbas?.find((item) => {
    return item.standardValue == formState1.executionStandard;
  });

  console.log(mckbz);
  if (mckbz) {
    if (aggregatesTypeParent.value == "粗集料") {
      formState1.testingBasis = mckbz.basis.inspectionBasisC;
      formState1.determineBasis = mckbz.basis.determineBasisC;
    }
    if (aggregatesTypeParent.value == "细集料") {
      formState1.testingBasis = mckbz.basis.inspectionBasisX;
      formState1.determineBasis = mckbz.basis.determineBasisX;
    }
  }

  //设置集料规格对应的可选性---检测组合：粗骨料，不可选“细度模数、亚甲蓝”；细骨料，不可选“颗粒级配”；
  banClick(aggregatesTypeParent.value);
};

const getJsonData = async (e, a) => {
  // console.log(e);
  // console.log(a);
  if (e && a) {
    let fix = "";
    a.includes("粗集料") ? (fix = "C") : (fix = "X");
    try {
      let res = await apiTopHeader.getSysConfig({
        configurationName: e + "EP" + fix,
      });
      if (res.data.data) {
        formItems.value = JSON.parse(res.data.data.configurationValue);
      } else {
        formItems.value = [];
      }
    } catch (error) {
      console.log(error);
    }
  }
};

const executionChange = (e) => {
  // console.log(formState1.aggregatesType);
  getJsonData(e, formState1.aggregatesType);
  let allstbas = allStandardBasisdata.value;
  let mckbz = allstbas.find((item) => {
    return item.standardValue == e;
  });
  executionStandardName.value = mckbz.standardName;
  // console.log(mckbz);
  if (mckbz) {
    if (aggregatesTypeParent.value == "粗集料") {
      formState1.testingBasis = mckbz.basis.inspectionBasisC;
      formState1.determineBasis = mckbz.basis.determineBasisC;
    }
    if (aggregatesTypeParent.value == "细集料") {
      formState1.testingBasis = mckbz.basis.inspectionBasisX;
      formState1.determineBasis = mckbz.basis.determineBasisX;
    }
  }
};

//获取供货单位
const searchVendor = () => {
  apiCenterBottom
    .getCompanyByType({ type: 1 })
    .then((res) => {
      if (res.data.code == 0) {
        // formState1.testingUnit = undefined;
        vendorOptions.value = res.data.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      }
    })
    .catch(() => {
      message.error("获取供货单位失败", 2);
    });
};

//获取生产厂家
const searchProducer = () => {
  apiCenterBottom
    .getCompanyByType({ type: 2 })
    .then((res) => {
      if (res.data.code == 0) {
        // formState1.testingUnit = undefined;
        producerOptions.value = res.data.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      }
    })
    .catch(() => {
      message.error("获取生产厂家失败", 2);
    });
};

//获取执行标准
const searchExecutionStandard = async () => {
  try {
    const res = await apiCenterBottom.getExecutionStandards();
    if (res.data.code == 0) {
      // formState1.testingUnit = undefined;
      executionStandardOptions.value = res.data.data.map((item) => {
        return {
          value: item.standardValue,
          svalue: item.standardValue,
          label: item.standardName,
        };
      });
    }
  } catch (error) {
    message.error("获取执行标准失败", 2);
  }
};

//获取检测依据
const searchTestingBasis = () => {
  apiCenterBottom
    .getTestingBasisList()
    .then((res) => {
      if (res.data.code == 0) {
        // formState1.testingUnit = undefined;
        testingBasisOptions.value = res.data.data.map((item) => {
          return {
            value: item.id,
            label: item.testingBasisAndCode,
          };
        });
      }
    })
    .catch(() => {
      message.error("获取供货单位失败", 2);
    });
};

//获取规格型号
const searchSpecificationMode = () => {
  apiCenterBottom
    .aggregatesTypeGroup({
      aggregatesTypeParent: aggregatesTypeParent.value,
      aggregatesType: aggregatesType.value,
    })
    .then((res) => {
      if (res.data.code == 0) {
        specificationModeOptions.value = res.data.data;
      }
    })
    .catch(() => {
      message.error("获取规格型号失败", 2);
    });
};

//获取检测单位
const searchTestingUnitNameMode = () => {
  apiCenterBottom
    .getCompanyByType({ type: 5 })
    .then((res) => {
      if (res.data.code == 0) {
        testingUnitNameOptions.value = res.data.data.map((item) => {
          return {
            value: item.name,
            label: item.name,
          };
        });
      }
    })
    .catch(() => {
      message.error("获取检测单位失败", 2);
    });
};
const banClick = (aggregateType) => {
  detectionOptions.value.forEach((item) => {
    switch (item.label) {
      case '颗粒级配(细集料)':
        item.disabled = aggregateType !== "细集料" || !configurationValue.value?.finenessModulusDevice
        break;
      case "颗粒级配(粗集料)":
        item.disabled = aggregateType !== "粗集料" || !configurationValue.value.particleMatchingDevice
        break;
      case "亚甲蓝值":
        item.disabled = aggregateType !== "细集料" || !configurationValue.value?.methyleneBlue
        break;
    }
  });
};
</script>

<style lang="scss" scoped>
@use '../../../assets/style/modalcoustom.scss';
:deep(.ant-checkbox + span) {
  color: white;
}

:deep(.ant-checkbox-disabled + span) {
  color: rgb(167, 167, 167);
}

:deep(.ant-checkbox-disabled .ant-checkbox-inner) {
  background-color: gray;
}

:deep(.ant-form label) {
  color: white;
}

.registe-list {
  min-width: 720px !important;
}

.regist-type {
  margin-top: 0.04rem;
  font-size: 0.13rem;
}

// .close-btn {
//   display: flex;
//   top: -0.12rem;
//   float: right;
//   z-index: 9999;
//   background: none;
//   border: none;
//   font-size: 0.08rem;
//   color: white;
// }
.modlcont-wren {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modlcont-wren-item {
  width: 50%;
  height: 3.5vh;
  display: flex;
  color: white;
  font-size: 0.08rem;
  border-radius: 3px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ff444480;
}

.wricon {
  color: rgb(255, 255, 255);
}

.fromline {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 0.08rem;
  padding-left: 0.04rem;
}

.fromline-name {
  font-size: 0.07rem;
  margin-left: 0.03rem;
  color: #fff;
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
}

.agrund {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 0.05rem;
}

.agrund-item {
  width: 30%;
  margin-right: 1%;
  padding: 0.04rem 0;
  text-align: center;
  background: rgba(18, 27, 37, 0.8);
  border: 1px solid #9ecdfc;
  margin-bottom: 0.06rem;
  color: #fff;
  border-radius: 0.02rem;
  cursor: pointer;
  font-size: 0.07rem;
}

.agrund .agrundActive {
  background: rgb(0, 183, 255, 0.4);
  border: 1px solid rgb(0, 183, 255);
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  .footer-sample {
    position: absolute;
    left: 13%;
    margin-bottom: 0;
  }
  .footes {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
  }
}
</style>
<style>
.sampsle
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  color: #fff;
}

.sampsle .ant-select-multiple .ant-select-selection-item {
  background: #195894;
  color: #fff;
  border: none;
}

.sampsle .ant-select-multiple .ant-select-selection-item-remove {
  color: #fff;
}

.sampsle .ant-picker {
  background: #121b25;
  border-color: #9ecdfc !important;
  color: white;
  width: 100%;
}

.sampsle .ant-picker-suffix {
  color: #fff;
}

.sampsle .ant-picker-input > input {
  color: #fff;
}
</style>
