<template>
  <a-modal
    :visible="props.visible"
    width="100%"
    wrap-class-name="table-modal"
    centered
    class="registe-list"
    :closeIcon="true"
    @cancel="closeModal"
  >
    <template #title>
      <div class="modal-name">设备参数</div>
      <a-button class="close-btn" @click="closeModal">关闭 </a-button>
    </template>
    <div class="modlcont device-content" style="padding: 0">
      <div class="content-left">
        <div class="device-name">
          <a-form-item
            label="设备"
            name="device"
            :label-col="{ style: { width: '15%', textAlign: 'left' } }"
            :wrapper-col="{ style: { width: '85%' } }"
            style="width: 100%"
          >
            <a-select
              v-model:value="device"
              style="width: 100%"
              :options="deviceOptions"
              placeholder="请选择设备"
              @change="onSelectDevice"
            >
            </a-select>
          </a-form-item>
        </div>
        <div class="tree-select">
          <a-spin tip="加载中..." :spinning="treeloding">
            <a-tree
              :blockNode="true"
              default-expand-all
              :tree-data="treeData"
              :field-names="fieldNames"
              v-model:selected-keys="selectedKey"
              v-model:expanded-keys="expandedKeys"
              @select="onSelectParameter"
              v-if="treeData?.length > 0"
            >
            </a-tree>
            <a-empty
              v-else
              class="list-empty"
              :image="Empty.PRESENTED_IMAGE_SIMPLE"
            />
          </a-spin>
        </div>
      </div>
      <div class="content-right">
        <div class="path">
          <b>当前选择：</b>
          <span>{{ currentPath?.split('/')?.join(' / ') }}</span>
        </div>
        <div class="table-list">
          <table border="1" v-if="JSON.stringify(parameterData) !== '{}'">
            <tbody>
              <tr class="table-head">
                <th colspan="2">参数名</th>
                <th colspan="2">值</th>
                <th colspan="2">说明</th>
              </tr>
              <tr
                rowspan="1"
                v-for="(value, parameter, index) in parameterData"
                :key="parameter"
              >
                <td colspan="2">
                  <b>{{ parameter }}</b>
                </td>
                <td colspan="2">
                  <a-input-number
                    :class="{ iserror: errorObj?.[currentPath]?.[parameter] }"
                    v-model:value="parameterData[parameter]"
                    placeholder="请输入值"
                    @change="
                      inputChange(
                        parameterData[parameter],
                        deviceParameterInfo[parameter]?.range,
                        parameter,
                        index
                      )
                    "
                  />
                  <div
                    class="error-message"
                    v-if="errorObj?.[currentPath]?.[parameter]"
                  >
                    {{ errorObj?.[currentPath]?.[parameter]?.errMsg }}
                  </div>
                </td>
                <td colspan="2">
                  <div class="info">
                    {{ deviceParameterInfo[parameter]?.range }}
                    {{ deviceParameterInfo[parameter]?.comments }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table border="1" v-else style="height: 100%">
            <tbody>
              <tr class="table-head">
                <th colspan="2">参数名</th>
                <th colspan="2">值</th>
                <th colspan="2">说明</th>
              </tr>
              <tr style="height: 100%">
                <td colspan="6">
                  <a-empty
                    class="list-empty"
                    :image="Empty.PRESENTED_IMAGE_SIMPLE"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="footes">
        <a-button type="primary" ghost @click="closeModal">取消</a-button>
        <a-button type="primary" @click="handleSubmit" :loading="saveLoading">
          保存
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script setup>
import { ref, defineProps, onMounted, defineEmits } from 'vue';
import { apiTopHeader } from '@/api/api-topHeader.js';
import { Empty, message } from 'ant-design-vue';
import { cloneDeep } from 'lodash';
// import { deviceParameter } from '../../js/parameterJson';

const emits = defineEmits(['update:visible']);
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
    default: false,
  },
});
const errorObj = ref({});
const saveLoading = ref(false);
// const deviceParameterObject = cloneDeep(deviceParameter);
let deviceParameterObject = ref({});
// 用于匹配配置项说明和填值范围
const deviceParameterInfo = {};
// 当前路径
const currentPath = ref('');
// 当前选中的模式
const currentParamKey = ref('');
// 当前选中的模式
const selectedKey = ref(['']);
// 当前设备
const device = ref(undefined);
// 设备options
const deviceOptions = ref([]);
const treeloding = ref(false);
const fieldNames = {
  title: 'paramKey',
};
const expandedKeys = ref([]);
const treeData = ref([]);
const parameterData = ref({});
onMounted(() => {
  initData();
});

/**
 * @name guochong
 * @Date 2024-11-28 17:32:30
 * @introduction 初始化设备参数json数据
 * @description 详细描述
 */
const initData = async () => {
  try {
    let res = await apiTopHeader.getDeviceParamJsonData();
    let jsonData = JSON.parse(res.data.message);
    deviceParameterObject.value = cloneDeep(jsonData);
    // console.log('🚀 ~ initData ~ deviceParameter:', deviceParameterObject.value);
    // deviceParameterObject.value = cloneDeep(deviceParameter);
    deviceOptions.value = deviceParameterObject.value?.paramConfig?.map(
      (item) => {
        return {
          value: item.paramKey,
          label: item.paramKey,
        };
      }
    );
    device.value = deviceOptions.value?.[0]?.value;
    onSelectDevice(device.value);

    deviceParameterObject.value.paramInfo.forEach((item) => {
      let key = item.name;
      deviceParameterInfo[key] = { ...item };
    });
  } catch (error) {
    console.error(error);
    message.error('获取设备参数失败', 2);
  }
};
/**
 * @name guochong
 * @Date 2024-11-28 10:43:58
 * @introduction 设备选择事件
 * @description 详细描述
 */
const onSelectDevice = (e) => {
  selectedKey.value = [e];
  parameterData.value = {};
  let filterData = deviceParameterObject.value?.paramConfig?.filter(
    (item) => item.paramKey == e
  );
  if (filterData?.length) {
    treeData.value = filterData[0]?.children.map((item) => addUniqueKeys(item));
    expandedKeys.value = getKeys(treeData.value);
    currentPath.value = device.value;
  }
  // console.log("🚀 ~ onSelectDevice ~ deviceParameterObject.value.paramConfig:", deviceParameterObject.value.paramConfig)
  parameterData.value =
    deviceParameterObject.value.paramConfig?.filter(
      (item) => item.paramKey == device.value
    )?.[0]?.params || {};
};
/**
 * @name guochong
 * @Date 2024-12-03 17:28:24
 * @introduction 给树形结构数据每一层添加key
 * @description 详细描述
 */
const addUniqueKeys = (node, parentKey = '') => {
  // 为当前节点生成唯一的 key
  const uniqueKey = parentKey ? `${parentKey}-${node.paramKey}` : node.paramKey;
  node.key = uniqueKey; // 添加 key
  // 如果有子节点，递归为每个子节点添加 key
  if (node.children && node.children?.length > 0) {
    node.children.forEach((child, index) => {
      addUniqueKeys(child, uniqueKey); // 传递父节点的 key 作为子节点的前缀
    });
  }
  return node;
};
/**
 * @name guochong
 * @Date 2024-12-03 17:27:47
 * @introduction 获取所有节点的 key 值,用于切换设备时默认展开所有树形
 * @description 详细描述
 */
const getKeys = (nodes) => {
  return nodes.reduce((keys, node) => {
    keys.push(node.key);
    if (node.children?.length > 0) {
      keys = keys.concat(getKeys(node.children));
    }
    return keys;
  }, []);
};
/**
 * @name guochong
 * @Date 2024-11-28 10:44:23
 * @introduction 树形组件选择事件
 * @description 详细描述
 */
const onSelectParameter = (selectedKeys, e) => {
  currentParamKey.value = e.node.paramKey;
  selectedKey.value = selectedKeys;
  let path = getPrentPath(e.node.parent);
  if (path?.length) {
    currentPath.value = `${device.value}/${path.join('/')}/${e.node.paramKey}`;
  } else {
    currentPath.value = `${device.value}/${e.node.paramKey}`;
  }
  // 这里不能用深拷贝，不然修改值之后切换属性层级之后再切换回去之后数据还想显示的是之前的数据
  parameterData.value = e.node.params ?? {};
};
/**
 * @name guochong
 * @Date 2024-11-28 11:00:04
 * @introduction 点击树形结构得到当前元素的父路径
 * @description 详细描述
 */
const getPrentPath = (node) => {
  if (node) {
    let path = [node.node.paramKey]; // 当前节点的paramKey
    if (node.parent) {
      // 如果有父节点，继续向上递归
      path = [...getPrentPath(node.parent), ...path];
    }
    return path;
  } else {
    return [];
  }
};
/**
 * @name guochong
 * @Date 2024-11-29 13:40:45
 * @introduction 右边表格数组输入框值改变事件
 * @description 详细描述
 */
const inputChange = (value, range, parameter, index) => {
  // console.log('🚀 ~ inputChange ~ e:', value, range, parameter, index);
  // 设置添加校验
  if (value && range?.length == 2) {
    if (value > range[1] || value < range[0]) {
      errorObj.value[currentPath.value] = {
        ...errorObj.value[currentPath.value],
        [parameter]: {
          errMsg: `值的范围应在 [${range}] `,
          errIndex: index,
        },
      };
    } else {
      delete errorObj.value[currentPath.value]?.[parameter];
      if (JSON.stringify(errorObj.value[currentPath.value]) == '{}') {
        delete errorObj.value[currentPath.value];
      }
    }
    // console.log('🚀 ~ inputChange ~ errorObj.value:', errorObj.value);
  }
  let editPrams = cloneDeep(parameterData.value);
  let pathArr = currentPath.value.split('/');
  updateParamConfig(
    deviceParameterObject.value.paramConfig,
    currentParamKey.value,
    editPrams,
    pathArr,
    0
  );
};
/**
 * @name guochong
 * @Date 2024-11-28 16:36:02
 * @introduction 输入框改变事件
 * @description 通过当前树形结构选择的值来更新对应的params
 * @param { Object } paramConfig 整个树形结构数据
 * @param { String } targetKey 树形结构选择的值
 * @param { Object } updatedParams 树形结构对应更新的params
 * @param { Array } pathArr 路径组成的数据，用于区分有相同子结构的对象属于哪个父节点
 * @param { Number } index 用于标记遍历路径时位于路径的哪个层级
 */
const updateParamConfig = (
  paramConfig,
  targetKey,
  updatedParams,
  pathArr,
  index
) => {
  for (let item of paramConfig) {
    // 判断当前层是否匹配目标 paramKey
    if (item.paramKey === targetKey && item.paramKey === pathArr[index]) {
      // 更新 params 或其他需要更新的字段
      item.params = updatedParams;
      return true; // 找到并更新后直接退出
    }
    // 如果有子节点，递归遍历 children
    if (
      item.children &&
      item.children?.length > 0 &&
      item.paramKey === pathArr[index]
    ) {
      const found = updateParamConfig(
        item.children,
        targetKey,
        updatedParams,
        pathArr,
        index + 1
      );
      if (found) return true; // 子节点中找到后直接退出
    }
  }
  return false; // 遍历完成未找到
};

const findParamConfig = (paramConfig, targetKey, pathArr, index) => {
  for (let item of paramConfig) {
    // 判断当前层是否匹配目标 paramKey
    if (item.paramKey === targetKey && item.paramKey === pathArr[index]) {
      return item.params;
    }
    // 如果有子节点，递归遍历 children
    if (
      item.children &&
      item.children?.length > 0 &&
      item.paramKey === pathArr[index]
    ) {
      const found = findParamConfig(
        item.children,
        targetKey,
        pathArr,
        index + 1
      );
      if (found) return found;
    }
  }
  return {};
};
/**
 * @name guochong
 * @Date 2024-12-04 09:37:04
 * @introduction 验证表格输入是否有误，并显示到错误对应的地方
 * @description 详细描述
 */
const isValidateData = () => {
  let res;
  if (JSON.stringify(errorObj.value) == '{}') {
    res = true;
  }
  Object.keys(errorObj.value).forEach((path, index) => {
    if (index == 0 && errorObj.value[path]) {
      // 有错误是总是提示第一个错误
      let errors = errorObj.value[path];
      let param = Object.keys(errors)[0];
      message.error(`${param} 值配置有误`);
      // 设置设备的值并显示出对应的树形结构数据
      let pathArr = path.split('/');
      device.value = pathArr[0];
      onSelectDevice(device.value);
      currentPath.value = path;
      // 设置树形结构有错误的层级高亮显示
      let pathKey = path.split('/');
      pathKey.shift();
      selectedKey.value = [pathKey.join('-')];
      let targetKey = path.split('/')[pathArr?.length - 1];
      // 设置表格显示的数据
      parameterData.value = findParamConfig(
        deviceParameterObject.value.paramConfig,
        targetKey,
        pathArr,
        0
      );
      res = false;
    }
  });
  return Promise.resolve(res);
};

/**
 * @name guochong
 * @Date 2024-11-28 17:35:45
 * @introduction 保存设备参数
 * @description 详细描述
 */
const handleSubmit = async () => {
  let isValid = await isValidateData();
  if (!isValid) {
    return;
  }
  saveLoading.value = true;
  try {
    let params = {
      deviceParamJson: JSON.stringify(deviceParameterObject.value),
    };
    // console.log('🚀 ~ handleSubmit ~ params:', deviceParameterObject.value);
    await apiTopHeader.saveDeviceParamJsonData(params);
    message.success('保存成功');
    saveLoading.value = false;
    closeModal();
  } catch (error) {
    saveLoading.value = false;
    console.error(error);
    message.error('保存设备参数失败', 2);
  }
};
/**
 * @introduction 关闭弹窗
 */
const closeModal = () => {
  emits('update:visible', false);
};
</script>

<style lang="scss" scoped>
@use '../../../assets/style/modalcoustom.scss';
table {
  width: 100%;
  color: white;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid #acacac;
}
tbody {
  position: relative;
}
th,
td {
  display: table-cell;
  padding: 0.05rem 0.1rem;
  border: 1px solid #acacac;
}
.iserror {
  border: 1px solid rgb(255, 77, 79) !important;
}
.error-message {
  color: rgb(255, 77, 79);
  line-height: 1.5715;
}
.info {
  color: #c6c6c6;
}
.table-head {
  height: 5%;
  position: sticky;
  top: 0;
  background-color: #263248;
  z-index: 99999;
}
.detection-name {
  font-size: 0.09rem;
  font-weight: bold;
  color: white;
}
.detection-name2 {
  font-size: 0.07rem;
  font-weight: bold;
  color: white;
}
.detection-row {
  padding: 0.1rem;
  .detection-row-item {
    font-size: 0.08rem;
    color: white;
    margin-right: 0.02rem;
    vertical-align: middle;
  }
}
.registe-list {
  min-width: 720px !important;
}
.regist-type {
  margin-top: 0.04rem;
  font-size: 0.13rem;
}
.footes {
  padding-bottom: 0.06rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1%;
}

.device-content {
  width: 100%;
  height: 100%;
  display: flex;
  column-gap: 0.08rem;
  /* padding: 0 !important; */
}
.content-left {
  display: flex;
  row-gap: 0.08rem;
  width: 25%;
  flex-direction: column;
}
.content-right {
  width: 75%;
  display: flex;
  row-gap: 0.08rem;
  flex-direction: column;
}
.device-name,
.path {
  height: 5%;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
}
.table-list {
  height: 95%;
  overflow: auto;
  border: 1px solid #fff;
}
.tree-select {
  height: 95%;
  border: 1px solid #ffffff;
  overflow: auto;
  padding: 0.08rem 0.05rem;
  border-radius: 3px;
}
:deep(.ant-form-item) {
  margin-bottom: 0px;
}
:deep(.ant-input-number) {
  width: 100% !important;
}
:deep(.ant-input-number-input-wrap) {
  height: 100%;
}
:deep(.ant-spin-nested-loading) {
  height: 100%;
}
:deep(.ant-spin-container) {
  height: 100%;
}
:deep(.ant-empty-description) {
  color: white;
  margin: 0;
}
:deep(.ant-spin-blur) {
  height: 100%;
}
:deep(.ant-tree-treenode) {
  color: white;
}
:deep(.ant-tree) {
  background-color: transparent !important;
}
:deep(.ant-tree-node-content-wrapper:hover) {
  background-color: #0063a0;
}
:deep(.ant-tree-node-selected) {
  background-color: #0063a0 !important;
}
:deep(.ant-tree-switcher) {
  background-color: transparent;
}
:deep(.vxe-table--body-wrapper) {
  padding: 0.05rem 0;
}
:deep(.ant-input-number-input) {
  background-color: #121b26;
  color: white;
  border: 1px solid #9ecdfc;
}
:deep(.anticon) {
  color: #121b26 !important;
}
:deep(.ant-tree-switcher .ant-tree-switcher-icon svg) {
  color: white !important;
}
.list-empty {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 35vh;
}
</style>
