<template>
  <div class="content-foots-1">
    <!-- 设备故障或警告提示 -->
    <div class="device-message">
      <div
        class="device-breakdown-tip"
        v-for="item in deviceFaultInfo"
        :key="item.deviceName"
      >
        <ExclamationCircleFilled />
        {{ `${item.deviceName}:` }}
        <span class="breakdown-msg">{{ item.msg }}</span>
      </div>
      <!-- deviceFaultInfo -->
    </div>

    <!-- 机械手 -->
    <div class="cfot-item" style="width: 11%">
      <div class="cfot-item-top">
        <div class="cfot-item-top-title">机械手</div>
        <setting-outlined
          @click="setOtherDeviceShow('机械手')"
          :style="{ fontSize: '0.09rem' }"
        />
      </div>
      <div class="cfot-item-content">
        <!-- 1就绪  2待就绪  3运行中  4故障 -->
        <DeviceStatus
          :deviceStatus="deviceStatus?.manipulatorDeviceStatus ?? 0"
        />
      </div>
    </div>

    <div class="divider"></div>

    <!-- 烘干 -->
    <div class="cfot-item" style="width: 9%">
      <div class="cfot-item-top">
        <div class="cfot-item-top-title">烘干</div>
        <setting-outlined
          @click="setDryDeviceShow"
          :style="{ fontSize: '0.09rem' }"
        />
      </div>
      <div class="cfot-item-content">
        <!-- 1就绪  2待就绪  3运行中  4故障 -->
        <DeviceStatus :deviceStatus="deviceStatus?.dryDeviceStatus ?? 0" />
      </div>
    </div>

    <div class="divider"></div>

    <!-- 水洗 -->
    <div class="cfot-item" style="width: 9%">
      <div class="cfot-item-top">
        <div class="cfot-item-top-title">水洗</div>
        <setting-outlined
          @click="setOtherDeviceShow('水洗')"
          :style="{ fontSize: '0.09rem' }"
        />
      </div>
      <div class="cfot-item-content">
        <!-- 1就绪  2待就绪  3运行中  4故障 -->
        <DeviceStatus :deviceStatus="deviceStatus?.washDeviceStatus ?? 0" />
      </div>
    </div>

    <div class="divider"></div>

    <!-- 颗粒级配 -->
    <div class="cfot-item" style="width: 14%">
      <div class="cfot-item-top">
        <div class="cfot-item-top-title">颗粒级配(粗)</div>
        <setting-outlined
          @click="setOtherDeviceShow('颗粒级配(粗)')"
          :style="{ fontSize: '0.09rem' }"
        />
      </div>
      <div class="cfot-item-content">
        <!-- 1就绪  2待就绪  3运行中  4故障 -->
        <DeviceStatus
          :deviceStatus="deviceStatus?.particleGradingModulusDeviceStatus ?? 0"
        />
      </div>
    </div>

    <div class="divider"></div>

    <!-- 细度模数 -->
    <div class="cfot-item" style="width: 14%">
      <div class="cfot-item-top">
        <div class="cfot-item-top-title">颗粒级配(细)</div>
        <setting-outlined
          @click="setOtherDeviceShow('颗粒级配(细)')"
          :style="{ fontSize: '0.09rem' }"
        />
      </div>
      <div class="cfot-item-content">
        <!-- 1就绪  2待就绪  3运行中  4故障 -->
        <DeviceStatus
          :deviceStatus="deviceStatus?.finenessModulusDeviceStatus ?? 0"
        />
      </div>
    </div>

    <div class="divider"></div>

    <!-- 亚甲蓝 -->
    <div class="cfot-item" style="width: 16%">
      <div class="cfot-item-top">
        <div class="cfot-item-top-title">亚甲蓝（MB值）</div>
        <setting-outlined
          @click="setOtherDeviceShow('亚甲蓝（MB值）')"
          :style="{ fontSize: '0.09rem' }"
        />
      </div>
      <div class="cfot-item-content">
        <!-- 1就绪  2待就绪  3运行中  4故障 -->
        <DeviceStatus
          :deviceStatus="deviceStatus?.methyleneBlueDeviceStatus ?? 0"
        />
      </div>
    </div>

    <!--烘干-参数设置-->
    <a-modal
      v-model:visible="dryDeviceShow"
      width="50%"
      wrap-class-name="qy-modal"
      centered
      :closeIcon="true"
      @cancel="closeDrying"
    >
      <template #title>
        <div ref="modalTitleRef" class="setting-title">烘干-参数设置</div>
        <a-button class="close-btn" @click="closeDrying">关闭 </a-button>
      </template>
      <div class="" v-if="JSON.stringify(setting.dryingParameter) !== '{}'">
        <div
          class="setting-dry"
          v-for="(item, key) in setting.dryingParameter"
          :key="key"
        >
          <div class="setting-dry-item">{{ key }}</div>
          <div class="setmina">
            <div class="setmina-item">
              <div class="setmina-item-title">功率</div>
              <div style="padding: 0 0.03rem">
                <a-input
                  suffix="kw"
                  placeholder="请输入功率"
                  v-model:value="item.config.power"
                />
              </div>
            </div>
            <div class="setmina-item">
              <div class="setmina-item-title">风量</div>
              <div style="padding: 0 0.03rem">
                <a-input
                  suffix="%"
                  placeholder="请输入风量"
                  v-model:value="item.config.air"
                />
              </div>
            </div>
            <div class="setmina-item">
              <div class="setmina-item-title">温度上限</div>
              <div style="padding: 0 0.03rem">
                <a-input
                  suffix="℃"
                  placeholder="请输入温度上限"
                  v-model:value="item.config.temperature_ceiling"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modlcont dry-loading" v-else>
        <a-spin size="large" />
      </div>
      <template #footer>
        <div
          class="footes"
          style="padding-bottom: 0.06rem"
          v-if="JSON.stringify(setting.dryingParameter) !== '{}'"
        >
          <div class="mbtns stars" @click="handleSaveDrying">
            <span>保存</span>
          </div>
        </div>
      </template>
    </a-modal>

    <!--暂无参数设备-参数设置-->
    <a-modal
      v-model:visible="otherDeviceShow"
      width="50%"
      wrap-class-name="qy-modal"
      centered
      :closeIcon="true"
    >
      <template #title>
        <div ref="modalTitleRef" class="setting-title">
          {{ `${deviceName}-参数设置` }}
        </div>
        <a-button class="close-btn" @click="otherDeviceShow = false"
          >关闭
        </a-button>
      </template>
      <div class="dry-loading">
        <div style="font-size: 0.08rem; color: #fab421">暂无参数</div>
      </div>
      <template #footer>
        <div class="footes" style="padding-bottom: 0.06rem">
          <div class="mbtns stars">
            <span>保存</span>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script setup>
import {
  // LoadingOutlined,
  // WarningOutlined,
  // CloseOutlined,
  // CheckOutlined,
  SettingOutlined,
  // PauseCircleOutlined,
  // CloseOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons-vue';
//TODO 导入子组件
import DeviceStatus from './cpns/device/device-status.vue';
import { ref, reactive, watch, nextTick } from 'vue';
import { apiCenterBottom } from '@/api/api-center-bottom/api-center-bottom.js';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
let store = useStore();

const isCloudPage = process.env.VUE_APP_ISCLOUDPAGE === 'true';

//整个设备列表对应设备数据
const setting = reactive({
  bitParameter: {}, //取样设备
  dryingParameter: {}, //烘干设备
});
// 各个设备状态
const deviceStatus = ref({
  samplingDeviceStatus: null, //取样
  manipulatorDeviceStatus: null, //机械手
  dryDeviceStatus: null, //烘干
  washDeviceStatus: null, //水洗
  particleGradingModulusDeviceStatus: null, //颗粒级配
  finenessModulusDeviceStatus: null, //细度模数
  methyleneBlueDeviceStatus: null, //亚甲蓝
  masterDeviceFaultStatus: null, //总控
});
// 各个设备故障信息
const deviceFaultInfo = ref([]);

watch(
  () => store.state.engine.allEngineData, //检测数据的前20条数据
  (newValue) => {
    nextTick(() => {
      // 设备状态
      Object.assign(deviceStatus.value, newValue ?? {});

      // console.log('设备状态', deviceStatus.value);
      // 故障和警告信息
      let totalMsg = [];
      if (newValue.deviceFaultInfo) {
        Object.keys(newValue.deviceFaultInfo)?.forEach((key) => {
          let partMsg = newValue.deviceFaultInfo[key].map((msg) => {
            return {
              msg: msg,
              deviceName: key,
            };
          });
          totalMsg.push(...partMsg);
        });
        deviceFaultInfo.value = totalMsg.reverse();
      }
    });
  },
  { immediate: false }
);

//烘干
const dryDeviceShow = ref(false);
/**
 * @Date 2023-05-08 14:07:37
 * @introduction 关闭烘干设备参数弹框
 * @description 关闭时清空所有值的背景颜色
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const closeDrying = () => {
  dryDeviceShow.value = false;
  setting.dryingParameter = {};
};
/**
 * @Date 2023-05-08 14:08:56
 * @introduction 打开烘干设备参数弹窗
 * @description 打开时获取参数并展示背景颜色
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const setDryDeviceShow = () => {
  if (isCloudPage) {
    message.warning('线上不可以设置');
    return;
  }
  dryDeviceShow.value = true;
  // 获取烘干设备参数
  apiCenterBottom
    .getDeviceSettings()
    .then((res) => {
      if (res.data.code == 0) {
        setting.dryingParameter = JSON.parse(res.data.data[0].drying);
        // console.log('获取的烘干设备参数', setting.dryingParameter);
      }
    })
    .catch(() => {
      message.error('获取设备参数失败', 2);
    });
};

/**
 * @Date 2023-05-08 14:13:21
 * @introduction 保存烘干设备参数信息
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const handleSaveDrying = () => {
  for (const key in setting.dryingParameter) {
    const config = setting.dryingParameter[key].config;
    if (
      config.temperature_ceiling === '' ||
      config.air === '' ||
      config.power === ''
    ) {
      message.error('自定义的值不能为空');
      return;
    }
  }

  // console.log('保存烘干设备信息的参数', setting.dryingParameter);
  // 保存烘干设备信息的参数
  let data = {
    drying: JSON.stringify(setting.dryingParameter),
  };
  apiCenterBottom
    .saveDeviceSettings(data)
    .then((res) => {
      if (res.data.code == 0) {
        message.success('修改成功');
        closeDrying();
      }
    })
    .catch(() => {
      message.error('修改失败');
    });
};

//机械手，水洗，颗粒级配，细度模数，亚甲蓝
const otherDeviceShow = ref(false);
let deviceName = ref('');
const setOtherDeviceShow = (device) => {
  if (isCloudPage) {
    message.warning('线上不可以设置');
  } else {
    // otherDeviceShow.value = true;
    // deviceName.value = device;
    message.warning('暂无配置功能');
  }
};
</script>

<style scoped>
.bgc-glass {
  background: rgba(255, 255, 255, 0.15) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(4px) !important;
  -webkit-backdrop-filter: blur(4px);
}
:deep(
    .drill-modal .ant-modal,
    .drill-modal .ant-modal-header,
    .drill-modal .ant-modal-body,
    .drill-modal .ant-modal-footer
  ) {
  background: rgba(61, 136, 255, 0.15) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(4px) !important;
  -webkit-backdrop-filter: blur(4px);
}
.footes {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wricon {
  color: red;
  font-size: 0.08rem;
  margin-right: 0.05rem;
}

.flex-row-center-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.dry-loading {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-foots-1 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0.15rem;
  background-color: rgba(26, 49, 86, 0.5);
  overflow: visible;
}
.device-status {
  font-size: 0.07rem;
  color: white;
}
.ctop {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
.cfot-item {
  /* position: relative; */
  height: 100%;
  padding: 0rem 0.07rem;
  box-sizing: border-box;
  /* border: 1px solid rgb(0, 183, 255, 0.4);
  box-shadow: rgba(15, 92, 151, 0.4) 0px 0px 0.2rem inset; */
  color: #fff;
  font-size: 0.06rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-around;
}
.divider {
  width: 0.02rem;
  height: 70%;
  /* margin-left: 0.05rem; */
  background: radial-gradient(closest-side, #ffffff, #9f9f9f75, #8d8d8d12);
}
.cfot-item-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cfot-item-top img {
  width: 0.1rem;
  height: 0.1rem;
  cursor: pointer;
}
.device-message {
  position: absolute;
  width: 100%;
  /* height: 42vh; */
  max-height: 42vh;
  /* background-color: #ffdbdb4e; */
  left: 0;
  bottom: 100%;
  padding: 0.5% 0;
  overflow: auto;
  margin-top: auto;
  scrollbar-width: none; /* Firefox 隐藏滚动条 */
  -ms-overflow-style: none; /* IE/Edge 隐藏滚动条 */
}
.device-message::-webkit-scrollbar {
  display: none; /* Chrome/Safari 隐藏滚动条 */
}
.device-breakdown-tip,
.device-warning-tip {
  margin-top: 10px;
  width: fit-content;
  height: 8%;
  border: #e64b4b solid 0.01rem;
  color: #e35757;
  font-size: 0.08rem;
  border-radius: 5px;
  display: flex;
  padding: 0 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.04rem;
  background: #182c4eb8;
  /* backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px); */
}
.device-warning-tip {
  border: #faad14 solid 0.01rem;
  color: #faad14;
}
.breakdown-msg {
  word-break: break-word;
}
.cfot-item-top-left {
  display: flex;
  align-items: center;
}
.cfot-item-top-mied {
  display: flex;
  align-items: center;
}
.cfot-item-top-mieditem {
  display: flex;
  align-items: center;
  font-size: 0.07rem;
  color: #fff;
  margin-right: 0.08rem;
}
.cfotstars {
  width: 0.07rem;
  height: 0.07rem;
  border-radius: 50%;
  margin-right: 0.04rem;
}
.cfot-item-top-title {
  font-size: 0.08rem;
  color: white;
}
.cfot-item-content {
  width: 100%;
  font-size: 0.07rem;
  margin-top: 0.04rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.cfot-item-content-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.color1 {
  background: #00ff00;
  box-shadow: 0px 0px 5px #00ff00;
}
.color2 {
  background: #fab421;
  box-shadow: 0px 0px 5px #fab421;
}
.color3 {
  background: #ec2745;
  box-shadow: 0px 0px 5px #ec2745;
}
.cfotstars-warn {
  font-size: 0.1rem;
  color: #ec2745;
  margin-right: 10px;
}
.twinkle {
  animation: star 1.2s linear infinite;
  box-shadow: 0px 0px 10px #00ff00;
}
.twinklewr {
  animation: star 1.2s linear infinite;
}
@keyframes star {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}

.mbtns {
  font-size: 0.08rem;
  color: #fff;
  padding: 0.03rem 0.06rem;
  display: inline-block;
  border-radius: 0.02rem;
  width: 0.65rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.stars {
  background: #47a3fc;
  border: 1px solid #47a3fc;
}
.mbtns .icons {
  font-size: 0.08rem;
  margin-left: 0.05rem;
}

/* .qy-modal .ant-modal {
  max-width: 60%;
  padding-bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.qy-modal .ant-modal-header {
  background: rgb(45, 58, 81);
  opacity: 1;
  border-bottom: none;
}

.qy-modal .ant-modal-title {
  text-align: center;
  opacity: 1;
  color: white;
  font-size: 25px;
  font-weight: bold;
  font-size: 0.15rem;
}

.qy-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: calc(70vh);
  background: rgb(45, 58, 81, 0.8);
}

.qy-modal .ant-modal-body {
  flex: 1;
}

.qy-modal .ant-modal-footer {
  border-top: none;
}

.ant-modal-close-icon svg {
  color: rgb(45, 58, 81);
}

.ddddddd .ant-modal-content .ant-modal-header {
  margin-top: 100px;
  background: rgb(1, 18, 47);
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: -23px;
} */

.agrund {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.agrund .agrundActive {
  background: rgb(0, 183, 255, 0.4);
  border: 1px solid rgb(0, 183, 255);
}
/* .fromline {
  display: flex;
  align-items: center;
  position: relative;
}
.fromline-name {
  font-size: 0.07rem;
  margin-left: 0.03rem;
  color: #fff;
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
} */

:deep(.ant-form-item-label > label) {
  color: white;
  font-size: 0.08rem;
}

:deep(.qy-modal .ant-modal-title) {
  font-size: 0.15rem;
}
:deep(.ant-input-suffix) {
  font-size: 0.09rem;
}
.setmina {
  display: flex;
  align-items: center;
}
.setmina-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 0 0.03rem;
}
.setting-dry-item {
  color: white;
  font-size: 0.09rem;
  font-weight: 800;
  width: 0.4rem;
}
.setmina-item-title {
  text-align: center;
  font-size: 0.08rem;
  color: #fab421;
  margin-bottom: 0.04rem;
}
.setting-dry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  color: white;
  font-size: 0.08rem;
  padding: 0.1rem 0.3rem;
}
:deep(.ant-input) {
  font-size: 0.08rem;
}
.setting-title {
  margin-top: 0.04rem;
  font-size: 0.13rem;
}
/* .close-btn {
  margin-left: auto;
  z-index: 9999;
  background: none;
  border: none;
  font-size: 0.08rem;
  color: white;
} */
</style>
