<template>
  <!--账号管理弹框-->
  <a-modal
    :visible="props.visible"
    width="100%"
    wrap-class-name="table-modal"
    centered
    :destroyOnClose="true"
    :closeIcon="true"
    @cancel="closeModal"
  >
    <template #title>
      <div class="modal-name">{{ `账号管理` }}</div>
      <a-button class="close-btn" @click="closeModal">关闭 </a-button>
    </template>
    <div class="modmian" style="padding: 0">
      <div style="margin-bottom: 0.06rem">
        <a-form
          :model="formState"
          style="width: 100%"
          :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
          :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }"
        >
          <a-row>
            <a-col :lg="8" :md="12" :sm="24" :xs="24">
              <a-form-item label="状态：">
                <a-select
                  v-model:value="formState.status"
                  :options="optionsStatus"
                  :allowClear="true"
                  placeholder="请选择状态"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :sm="24" :xs="24">
              <a-form-item label="角色：">
                <a-select
                  v-model:value="formState.role"
                  :options="optionsRole"
                  :allowClear="true"
                  placeholder="请选择角色"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :sm="24" :xs="24">
              <a-form-item label="姓名：">
                <a-input
                  v-model:value="formState.name"
                  placeholder="请输入姓名"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :sm="24" :xs="24">
              <a-form-item label="账号：">
                <a-input
                  v-model:value="formState.account"
                  placeholder="请输入账号"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :sm="24" :xs="24">
              <a-form-item>
                <a-space :size="'small'">
                  <a-button type="primary" @click="resetSearch">重置</a-button>
                  <a-button type="primary" @click="handleSearch">查询</a-button>
                  <a-button type="primary" @click="addAccount">添加</a-button>
                </a-space>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-table
        ref="standardTableList"
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :row-class-name="
          (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
        :pagination="pagination"
        @change="pageChange"
      >
        <template v-slot:bodyCell="{ column, record, index }">
          <!-- 序号列 -->
          <template v-if="column.key === 'sort'">
            {{ pagination.pageSize * (pagination.current - 1) + index + 1 }}
          </template>
          <template v-if="column.key === 'status'">
            <div class="typeTag success-color" v-if="record.status == 1">
              启用
            </div>
            <div class="typeTag hitch-color" v-if="record.status == 0">
              停用
            </div>
          </template>
          <template v-if="column.key === 'defaultStandard'">
            <a-switch
              checked-children="是"
              un-checked-children="否"
              v-model:checked="record.defaultStandard"
            />
          </template>
          <template v-if="column.key === 'operate'">
            <div class="under_detection">
              <a-popconfirm
                title="是否确认删除?"
                @confirm="deleteStandard(row)"
              >
                <a-button type="link" :size="'middle'" danger> 删除 </a-button>
              </a-popconfirm>
              <a-button
                type="link"
                :size="'middle'"
                @click="editAccount(record)"
              >
                编辑
              </a-button>
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <template #footer></template>
  </a-modal>

  <!-- 集料检测记录表 -->
  <accountManageEdit
    v-if="accountManageEditShow"
    ref="standardEditRef"
    v-model:visible="accountManageEditShow"
    :record="record"
  />
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from 'vue';
import { apiTopHeader } from '@/api/api-topHeader.js';
import accountManageEdit from './account-manage-edit.vue';
import { message } from 'ant-design-vue';

const emits = defineEmits(['update:visible', 'refreshSamplist']);
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
    default() {
      return false;
    },
  },
  detailId: {
    type: [String, Number],
    default() {
      return '';
    },
  },
});
let record = ref({});
const optionsStatus = ref([
  {
    value: 2,
    label: '全部',
  },
  {
    value: 0,
    label: '停用',
  },
  {
    value: 1,
    label: '启用',
  },
]);
const optionsRole = ref([
  {
    value: '全部',
    label: '全部',
  },
  {
    value: '管理员',
    label: '管理员',
  },
  {
    value: '操作员',
    label: '操作员',
  },
]);
// 标准管理弹窗---表格加载数据
let loading = ref(true);
// 标准管理列表弹窗---表格列配置源
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    key: 'sort',
    width: '5%',
    align: 'center',
  },
  {
    title: '账号',
    dataIndex: 'account',
    key: 'account',
    width: '15%',
    align: 'center',
  },
  {
    title: '角色',
    dataIndex: 'role',
    key: 'role',
    width: '15%',
    align: 'center',
  },
  {
    title: '状态',
    key: 'status',
    dataIndex: 'status',
    width: '10%',
    align: 'center',
  },
  {
    title: '操作',
    key: 'operate',
    dataIndex: 'operate',
    width: '10%',
    align: 'center',
    fixed: 'right',
  },
]);
// 标准管理弹窗---表格数据源
let data = ref([]);
//标准管理弹窗---表格查询条件数据
let formState = ref({
  status: 2,
  role: '全部',
  name: undefined,
  account: undefined,
});
// 标准管理弹窗---弹窗表格分页器配置项
let pagination = ref({
  current: 1,
  pageSize: 10,
  total: null,
  pageSizeOptions: ['5', '10', '15', '20'],
  showSizeChanger: true,
  showQuickJumper: true,
  responsive: true,
  showTotal: (total) => `总共 ${total} 条数据`,
});
const accountManageEditShow = ref(false);
const standardEditRef = ref();
onMounted(() => {
  // 标准列表弹窗---展示默认的表格数据
  handleSearch();
});
const closeModal = () => {
  emits('update:visible', false);
};
/**
 * @introduction 列表弹窗---分页器页码变化的回调
 * @param { Number } page 页码
 */
const pageChange = (page) => {
  let { current, pageSize } = page;
  pagination.value.current = current;
  pagination.value.pageSize = pageSize;
  handleSearch();
};

/**
 * @introduction 重置查询
 */
const resetSearch = () => {
  pagination.value.current = 1;
  formState.value = {
    status: 2,
    role: '全部',
    name: undefined,
    account: undefined,
  };
  handleSearch();
};

/**
 * @introduction 账号管理---账号管理查询事件
 */
const handleSearch = () => {
  let params = {
    page: pagination.value.current,
    limit: pagination.value.pageSize,
    ...formState.value,
  };
  // 账号管理查询事件
  apiTopHeader
    .getStandardPage(params)
    .then((res) => {
      loading.value = true;
      if (res.data.code == 0) {
        loading.value = false;
        pagination.value.total = res.data.data.count;
        data.value = res.data.data.list;
      }
    })
    .catch((error) => {
      console.log(error);
      message.error(error.data.message, 2);
    });
};
/**
 * @introduction 新增账号
 */
const addAccount = () => {
  accountManageEditShow.value = true;
};
/**
 * @introduction 修改账号
 */
const editAccount = (detail) => {
  record.value = detail;
  accountManageEditShow.value = true;
};
</script>

<style lang="scss" scoped>
@use '../../../assets/style/modalcoustom.scss';
.success-color {
  color: #5bde75;
}
.detecting-color {
  color: #d1ff99;
}
.offline-color {
  color: #fdb474;
}
.none-color {
  color: #fff;
}
.hitch-color {
  color: #e46570;
}
.ant-btn-link {
  border-color: #1890ff;
}
.account-list {
  min-width: 720px !important;
}
.account-type {
  margin-top: 0.04rem;
  font-size: 0.13rem;
}
// .close-btn {
//   margin-left: auto;
//   z-index: 9999;
//   background: none;
//   border: none;
//   font-size: 0.08rem;
//   color: white;
// }
.btns {
  font-size: 0.07rem;
  color: #fff;
  background: rgb(0, 183, 255, 0.4);
  border: 1px solid rgb(0, 183, 255);
  padding: 0.02rem 0.08rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.02rem;
  width: 0.45rem;
  cursor: pointer;
}
.btns .bticon {
  font-size: 0.08rem;
  margin-left: 0.04rem;
}
:deep(.ant-pagination-total-text) {
  color: white;
}
:deep(.ant-pagination-options-quick-jumper) {
  color: white;
}
:deep(.ant-form-item-label > label) {
  color: white;
  font-size: 0.08rem;
}
:deep(
    .ant-btn-dangerous.ant-btn-link:hover,
    .ant-btn-dangerous.ant-btn-link:focus
  ) {
  color: #ff7875;
  border-color: #ff7875;
  background: transparent;
}
:deep(.ant-btn-dangerous) {
  color: #ff7875;
  border-color: #ff7875;
  background: transparent;
}
:deep(.ant-btn-dangerous:not(:focus)) {
  border-color: #ff7875;
}
.close-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  float: right;
  z-index: 9999;
  background: none;
}
.download-btn {
  background: none;
  color: white;
}
.under_detection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
:deep(.ant-pagination-jump-prev) {
  display: none !important;
}
:deep(.ant-pagination-jump-next) {
  display: none !important;
}
</style>
