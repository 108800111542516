<template>
  <a-modal
    :visible="props.visible"
    width="70%"
    wrap-class-name="qy-modal"
    centered
    class="registe-list"
    :closeIcon="true"
    @cancel="closeModal"
  >
    <template #title>
      <div class="modal-name">系统参数</div>
      <a-button class="close-btn" @click="closeModal">关闭 </a-button>
    </template>
    <div class="modlcont" style="padding: 0 0.5rem">
      <a-row>
        <span class="detection-name">检测项目</span>
      </a-row>
      <a-row class="detection-row">
        <a-col :lg="6" :md="12" :sm="24" :xs="24">
          <span class="detection-row-item">含水率</span>
          <a-switch
            checked-children="开"
            un-checked-children="关"
            v-model:checked="sysParameter.configurationValue.moistureContent"
          />
        </a-col>
        <a-col :lg="6" :md="12" :sm="24" :xs="24">
          <span class="detection-row-item">含泥率</span>
          <a-switch
            checked-children="开"
            un-checked-children="关"
            v-model:checked="sysParameter.configurationValue.mudContent"
          />
        </a-col>
        <a-col :lg="6" :md="12" :sm="24" :xs="24">
          <span class="detection-row-item">颗粒级配(粗)</span>
          <a-switch
            checked-children="开"
            un-checked-children="关"
            v-model:checked="
              sysParameter.configurationValue.particleMatchingDevice
            "
          />
        </a-col>
        <a-col :lg="6" :md="12" :sm="24" :xs="24">
          <span class="detection-row-item">颗粒级配(细)</span>
          <a-switch
            checked-children="开"
            un-checked-children="关"
            v-model:checked="
              sysParameter.configurationValue.finenessModulusDevice
            "
          />
        </a-col>
      </a-row>
      <a-row class="detection-row">
        <a-col :lg="6" :md="12" :sm="24" :xs="24">
          <span class="detection-row-item">亚甲蓝值</span>
          <a-switch
            checked-children="开"
            un-checked-children="关"
            v-model:checked="sysParameter.configurationValue.methyleneBlue"
          />
        </a-col>
        <a-col :lg="6" :md="12" :sm="24" :xs="24">
          <span class="detection-row-item">取样</span>
          <a-switch
            checked-children="开"
            un-checked-children="关"
            v-model:checked="sysParameter.configurationValue.sampling"
          />
        </a-col>
      </a-row>
      <a-row>
        <span class="detection-name" style="margin-top: 30px">试验模式</span>
      </a-row>
      <a-row style="padding: 20px 0 0px 30px; box-sizing: border-box">
        <a-radio-group
          v-model:value="sysExperimentalMode.experimentalMode"
          name="radioGroup"
          @change="exeChneg"
        >
          <a-radio
            v-for="(item, index) in experimentalModeOptions"
            :key="index"
            :value="item.value"
            class="mrdos"
            >{{ item.label }}</a-radio
          >
        </a-radio-group>
      </a-row>
      <a-row>
        <span class="detection-name" style="margin-top: 30px">
          当前执行标准
        </span>
      </a-row>
      <a-row style="padding: 20px 0 30px 30px; box-sizing: border-box">
        <a-radio-group
          v-model:value="sysExecutionStandards.executionStandards"
          name="radioGroup"
          @change="exeChneg"
        >
          <a-radio
            v-for="(item, index) in executionStandardOptions"
            :key="index"
            :value="item.value"
            class="mrdos"
            >{{ item.label }}</a-radio
          >
        </a-radio-group>
      </a-row>
      <!-- <a-row>
        
      </a-row> -->
      <div
        v-for="(item, index) in executionStandardsBasis.configurationValue"
        :key="index"
      >
        <div
          v-if="sysExecutionStandards.executionStandards == item.standardValue"
        >
          <a-row
            style="
              width: 100%;
              padding: 5px 0 10px 0px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
            "
          >
            <span class="detection-name2" style="margin-right: 10px">
              检测依据(粗集料)
            </span>
            <div class="sampsle" style="flex: 1">
              <a-select
                v-model:value="item.basis.inspectionBasisC"
                mode="multiple"
                style="width: 100%"
                :max-tag-count="10"
                :options="testingBasisOptions"
                @dropdownVisibleChange="searchTestingBasis"
                placeholder="请选择检测依据"
              >
              </a-select>
            </div>
          </a-row>

          <a-row
            style="
              width: 100%;
              padding: 20px 0 10px 0px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
            "
          >
            <span class="detection-name2" style="margin-right: 10px">
              检测依据(细集料)
            </span>
            <div class="sampsle" style="flex: 1">
              <a-select
                v-model:value="item.basis.inspectionBasisX"
                mode="multiple"
                style="width: 100%"
                :max-tag-count="10"
                :options="testingBasisOptions"
                @dropdownVisibleChange="searchTestingBasis"
                placeholder="请选择检测依据"
              >
              </a-select>
            </div>
          </a-row>

          <a-row
            style="
              width: 100%;
              padding: 20px 0 10px 0px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
            "
          >
            <span class="detection-name2" style="margin-right: 10px">
              判定依据(粗集料)
            </span>
            <div class="sampsle" style="flex: 1">
              <a-select
                v-model:value="item.basis.determineBasisC"
                mode="multiple"
                style="width: 100%"
                :max-tag-count="10"
                :options="testingBasisOptions"
                @dropdownVisibleChange="searchTestingBasis"
                placeholder="请选择检测依据"
              >
              </a-select>
            </div>
          </a-row>

          <a-row
            style="
              width: 100%;
              padding: 20px 0 10px 0px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
            "
          >
            <span class="detection-name2" style="margin-right: 10px">
              判定依据(细集料)
            </span>
            <div class="sampsle" style="flex: 1">
              <a-select
                v-model:value="item.basis.determineBasisX"
                mode="multiple"
                style="width: 100%"
                :max-tag-count="10"
                :options="testingBasisOptions"
                @dropdownVisibleChange="searchTestingBasis"
                placeholder="请选择检测依据"
              >
              </a-select>
            </div>
          </a-row>
        </div>
      </div>
      <!-- 国标依据 start -->
      <!-- <div
        v-if="sysExecutionStandards.executionStandards == 'nationalStandard'"
      >
        <a-row
          style="
            width: 100%;
            padding: 5px 0 10px 0px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          "
        >
          <span class="detection-name2" style="margin-right: 10px"
            >检测依据(粗集料)</span
          >
          <div class="sampsle" style="flex: 1">
            <a-select
              v-model:value="nationalStandardBasis.jccjl"
              mode="multiple"
              style="width: 100%"
              :max-tag-count="10"
              :options="testingBasisOptions"
              @dropdownVisibleChange="searchTestingBasis"
              placeholder="请选择检测依据"
            >
            </a-select>
          </div>
        </a-row>
      
        <a-row
          style="
            width: 100%;
            padding: 20px 0 10px 0px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          "
        >
          <span class="detection-name2" style="margin-right: 10px"
            >检测依据(细集料)</span
          >
          <div class="sampsle" style="flex: 1">
            <a-select
              v-model:value="nationalStandardBasis.jcxjl"
              mode="multiple"
              style="width: 100%"
              :max-tag-count="10"
              :options="testingBasisOptions"
              @dropdownVisibleChange="searchTestingBasis"
              placeholder="请选择检测依据"
            >
            </a-select>
          </div>
        </a-row>
     
        <a-row
          style="
            width: 100%;
            padding: 20px 0 10px 0px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          "
        >
          <span class="detection-name2" style="margin-right: 10px"
            >判定依据(粗集料)</span
          >
          <div class="sampsle" style="flex: 1">
            <a-select
              v-model:value="nationalStandardBasis.pdcjl"
              mode="multiple"
              style="width: 100%"
              :max-tag-count="10"
              :options="testingBasisOptions"
              @dropdownVisibleChange="searchTestingBasis"
              placeholder="请选择检测依据"
            >
            </a-select>
          </div>
        </a-row>
      
        <a-row
          style="
            width: 100%;
            padding: 20px 0 10px 0px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          "
        >
          <span class="detection-name2" style="margin-right: 10px"
            >判定依据(细集料)</span
          >
          <div class="sampsle" style="flex: 1">
            <a-select
              v-model:value="nationalStandardBasis.pdxjl"
              mode="multiple"
              style="width: 100%"
              :max-tag-count="10"
              :options="testingBasisOptions"
              @dropdownVisibleChange="searchTestingBasis"
              placeholder="请选择检测依据"
            >
            </a-select>
          </div>
        </a-row>
      </div> -->
      <!-- 国标依据 end -->

      <!-- 公路依据 start -->
      <!-- <div v-if="sysExecutionStandards.executionStandards == 'highwayStandard'">
        <a-row
          style="
            width: 100%;
            padding: 5px 0 10px 0px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          "
        >
          <span class="detection-name2" style="margin-right: 10px"
            >检测依据(粗集料)</span
          >
          <div class="sampsle" style="flex: 1">
            <a-select
              v-model:value="highwayStandardBasis.jccjl"
              mode="multiple"
              style="width: 100%"
              :max-tag-count="10"
              :options="testingBasisOptions"
              @dropdownVisibleChange="searchTestingBasis"
              placeholder="请选择检测依据"
            >
            </a-select>
          </div>
        </a-row>
       
        <a-row
          style="
            width: 100%;
            padding: 20px 0 10px 0px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          "
        >
          <span class="detection-name2" style="margin-right: 10px"
            >检测依据(细集料)</span
          >
          <div class="sampsle" style="flex: 1">
            <a-select
              v-model:value="highwayStandardBasis.jcxjl"
              mode="multiple"
              style="width: 100%"
              :max-tag-count="10"
              :options="testingBasisOptions"
              @dropdownVisibleChange="searchTestingBasis"
              placeholder="请选择检测依据"
            >
            </a-select>
          </div>
        </a-row>
       
        <a-row
          style="
            width: 100%;
            padding: 20px 0 10px 0px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          "
        >
          <span class="detection-name2" style="margin-right: 10px"
            >判定依据(粗集料)</span
          >
          <div class="sampsle" style="flex: 1">
            <a-select
              v-model:value="highwayStandardBasis.pdcjl"
              mode="multiple"
              style="width: 100%"
              :max-tag-count="10"
              :options="testingBasisOptions"
              @dropdownVisibleChange="searchTestingBasis"
              placeholder="请选择检测依据"
            >
            </a-select>
          </div>
        </a-row>
       
        <a-row
          style="
            width: 100%;
            padding: 20px 0 10px 0px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          "
        >
          <span class="detection-name2" style="margin-right: 10px"
            >判定依据(细集料)</span
          >
          <div class="sampsle" style="flex: 1">
            <a-select
              v-model:value="highwayStandardBasis.pdxjl"
              mode="multiple"
              style="width: 100%"
              :max-tag-count="10"
              :options="testingBasisOptions"
              @dropdownVisibleChange="searchTestingBasis"
              placeholder="请选择检测依据"
            >
            </a-select>
          </div>
        </a-row>
      </div> -->
      <!-- 公路依据 end -->

      <!-- 铁路依据 start -->
      <!-- <div v-if="sysExecutionStandards.executionStandards == 'railwayStandard'">
        <a-row
          style="
            width: 100%;
            padding: 5px 0 10px 0px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          "
        >
          <span class="detection-name2" style="margin-right: 10px"
            >检测依据(粗集料)</span
          >
          <div class="sampsle" style="flex: 1">
            <a-select
              v-model:value="railwayStandardBasis.jccjl"
              mode="multiple"
              style="width: 100%"
              :max-tag-count="10"
              :options="testingBasisOptions"
              @dropdownVisibleChange="searchTestingBasis"
              placeholder="请选择检测依据"
            >
            </a-select>
          </div>
        </a-row>
        <a-row
          style="
            width: 100%;
            padding: 20px 0 10px 0px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          "
        >
          <span class="detection-name2" style="margin-right: 10px"
            >检测依据(细集料)</span
          >
          <div class="sampsle" style="flex: 1">
            <a-select
              v-model:value="railwayStandardBasis.jcxjl"
              mode="multiple"
              style="width: 100%"
              :max-tag-count="10"
              :options="testingBasisOptions"
              @dropdownVisibleChange="searchTestingBasis"
              placeholder="请选择检测依据"
            >
            </a-select>
          </div>
        </a-row>
        <a-row
          style="
            width: 100%;
            padding: 20px 0 10px 0px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          "
        >
          <span class="detection-name2" style="margin-right: 10px"
            >判定依据(粗集料)</span
          >
          <div class="sampsle" style="flex: 1">
            <a-select
              v-model:value="railwayStandardBasis.pdcjl"
              mode="multiple"
              style="width: 100%"
              :max-tag-count="10"
              :options="testingBasisOptions"
              @dropdownVisibleChange="searchTestingBasis"
              placeholder="请选择检测依据"
            >
            </a-select>
          </div>
        </a-row>
    
        <a-row
          style="
            width: 100%;
            padding: 20px 0 10px 0px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          "
        >
          <span class="detection-name2" style="margin-right: 10px"
            >判定依据(细集料)</span
          >
          <div class="sampsle" style="flex: 1">
            <a-select
              v-model:value="railwayStandardBasis.pdxjl"
              mode="multiple"
              style="width: 100%"
              :max-tag-count="10"
              :options="testingBasisOptions"
              @dropdownVisibleChange="searchTestingBasis"
              placeholder="请选择检测依据"
            >
            </a-select>
          </div>
        </a-row>
      </div> -->
      <!-- 铁路依据 end -->

      <a-row>
        <span class="detection-name" style="margin-top: 35px">检测预警</span>
      </a-row>
      <a-row
        style="width: 100%; padding: 20px 0 10px 30px; box-sizing: border-box"
      >
        <div class="sampsle" style="width: 100%">
          <a-select
            v-model:value="sysTestingUnitName.testingUnitName"
            style="width: 100%"
            :options="testingUnitNameOptions"
            placeholder="请选择集料类别"
            @dropdownVisibleChange="searchTestingUnitNameMode"
          >
          </a-select>
        </div>
      </a-row>

      <a-row>
        <span class="detection-name" style="margin-top: 35px">设备检测</span>
      </a-row>
      <a-row
        class="detection-row"
        style="width: 100%; padding: 20px 0 10px 30px; box-sizing: border-box"
      >
        <a-col :lg="24" :md="24" :sm="24" :xs="24">
          <span class="detection-row-item" style="font-size: 16px">
            取样时间：
          </span>
          <input
            placeholder="取样时间"
            type="number"
            class="mipusts"
            v-model="sysEquipmentWarning.configurationValue.samplingTime"
            oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<0){value=''} if(value>10000){value=10000}"
          />
        </a-col>
        <a-col :lg="24" :md="24" :sm="24" :xs="24" style="margin-top: 14px">
          <span class="detection-row-item" style="font-size: 16px">
            检测时间：
          </span>
          <input
            placeholder="检测时间"
            type="number"
            class="mipusts"
            v-model="sysEquipmentWarning.configurationValue.detectionTime"
            oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<0){value=''} if(value>10000){value=10000}"
          />
        </a-col>
        <a-col :lg="24" :md="24" :sm="24" :xs="24" style="margin-top: 14px">
          <span class="detection-row-item" style="font-size: 16px">
            含水率预警阈值：
          </span>
          <span class="mspans">大于</span>
          <input
            type="number"
            style="width: 60px"
            class="mipusts"
            v-model="sysEquipmentWarning.configurationValue.moistureContent.max"
            oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<0){value=''} if(value>10000){value=10000}"
          />
          <span class="mspans">%</span>
          <span class="mspans" style="margin-left: 6px">小于</span>
          <input
            type="number"
            style="width: 60px"
            class="mipusts"
            v-model="sysEquipmentWarning.configurationValue.moistureContent.min"
            oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<0){value=''} if(value>10000){value=10000}"
          />
          <span class="mspans">%</span>
        </a-col>
        <a-col :lg="24" :md="24" :sm="24" :xs="24" style="margin-top: 14px">
          <span
            class="detection-row-item"
            style="font-size: 16px; margin-top: 12px"
          >
            含泥量预警阈值：
          </span>
          <span class="mspans">大于</span>
          <input
            type="number"
            style="width: 60px"
            class="mipusts"
            v-model="sysEquipmentWarning.configurationValue.mudContent.max"
            oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<0){value=''} if(value>10000){value=10000}"
          />
          <span class="mspans">%</span>
          <span class="mspans" style="margin-left: 6px">小于</span>
          <input
            type="number"
            style="width: 60px"
            class="mipusts"
            v-model="sysEquipmentWarning.configurationValue.mudContent.min"
            oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<0){value=''} if(value>10000){value=10000}"
          />
          <span class="mspans">%</span>
        </a-col>
        <a-col :lg="24" :md="24" :sm="24" :xs="24" style="margin-top: 14px">
          <span class="detection-row-item" style="font-size: 16px">
            取样质量：
          </span>
          <span class="mspans">大于</span>
          <input
            type="number"
            style="width: 60px"
            class="mipusts"
            v-model="sysEquipmentWarning.configurationValue.samplingMass.max"
            oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<0){value=''} if(value>10000){value=10000}"
          />
          <span class="mspans">克</span>
          <span class="mspans" style="margin-left: 6px">小于</span>
          <input
            type="number"
            style="width: 60px"
            class="mipusts"
            v-model="sysEquipmentWarning.configurationValue.samplingMass.min"
            oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<0){value=''} if(value>10000){value=10000}"
          />
          <span class="mspans">克</span>
        </a-col>
        <!-- <a-col :lg="12" :md="12" :sm="24" :xs="24" style="margin-top: 14px">
          <span
            class="detection-row-item"
            style="font-size: 16px; margin-top: 12px"
            >取样质量（细）：</span
          >
          <span class="mspans">大于</span>
          <input
            type="number"
            style="width: 60px"
            class="mipusts"
            v-model="sysEquipmentWarning.configurationValue.samplingMassX.max"
            oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<0){value=''} if(value>10000){value=10000}"
          />
          <span class="mspans">克</span>
          <span class="mspans" style="margin-left: 6px">小于</span>
          <input
            type="number"
            style="width: 60px"
            class="mipusts"
            v-model="sysEquipmentWarning.configurationValue.samplingMassX.min"
            oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<0){value=''} if(value>10000){value=10000}"
          />
          <span class="mspans">克</span>
        </a-col> -->
      </a-row>
    </div>

    <template #footer>
      <div
        class="footes"
        style="padding-bottom: 0.06rem"
        html-type="submit"
        @click="handleSubmit"
      >
        <div class="mbtns stars">
          <span>保存</span>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script setup>
import { ref, defineProps, onMounted, defineEmits } from "vue";
import { apiTopHeader } from "@/api/api-topHeader.js";
import { apiCenterBottom } from "@/api/api-center-bottom/api-center-bottom.js";
import { message } from "ant-design-vue";

const emits = defineEmits(["update:visible"]);
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
    default: false,
  },
});

let executionStandardOptions = ref(); //执行标准
let testingBasisOptions = ref(); //检测依据
let testingUnitNameOptions = ref(); //检测单位
let experimentalModeOptions = ref();

//检测项目
const sysParameter = ref({
  configurationName: "detectionItemConfig",
  id: null,
  configurationValue: {
    moistureContent: true, //含水率
    mudContent: true, //含泥率
    particleMatchingDevice: true, //颗粒级配（粗）
    finenessModulusDevice: true, //颗粒级配（细）
    methyleneBlue: true, // 亚甲蓝值
    sampling: true, // 取样
  },
});
//执行标准
const sysExecutionStandards = ref({
  configurationName: "executionStandard",
  id: null,
  configurationValue: {},
  executionStandards: "", //执行标准
  experimentalMode: "",
});
// //检测依据粗集料
// const sysTestingBasisC = ref({
//   configurationName: "inspectionBasisC",
//   id: null,
//   configurationValue: {},
//   ctestingBasis: undefined, //检测依据粗集料
// });
// //检测依据细集料
// const sysTestingBasisX = ref({
//   configurationName: "InspectionBasisX",
//   id: null,
//   configurationValue: {},
//   xtestingBasis: undefined, //检测依据粗集料
// });
// //检测依据粗集料
// const sysTestingBasispdC = ref({
//   configurationName: "inspectionBasisC",
//   id: null,
//   configurationValue: {},
//   ctestingBasis: undefined, //检测依据粗集料
// });
// //检测依据细集料
// const sysTestingBasispdX = ref({
//   configurationName: "InspectionBasisX",
//   id: null,
//   configurationValue: {},
//   xtestingBasis: undefined, //检测依据粗集料
// });

// const basisList = ref([
//   {
//     //国标
//     configurationName: "nationalStandardBasis",
//     id: null,
//     configurationValue: {},
//     jccjl: undefined,
//     jcxjl: undefined,
//     pdcjl: undefined,
//     pdxjl: undefined,
//   },
//   {
//     //公路
//     configurationName: "highwayStandardBasis",
//     id: null,
//     configurationValue: {},
//     jccjl: undefined,
//     jcxjl: undefined,
//     pdcjl: undefined,
//     pdxjl: undefined,
//   },
//   {
//     //铁路
//     configurationName: "railwayStandardBasis",
//     id: null,
//     configurationValue: {},
//     jccjl: undefined,
//     jcxjl: undefined,
//     pdcjl: undefined,
//     pdxjl: undefined,
//   },
// ]);

//国标
// const nationalStandardBasis = ref({
//   configurationName: "nationalStandardBasis",
//   id: null,
//   configurationValue: {},
//   jccjl: undefined,
//   jcxjl: undefined,
//   pdcjl: undefined,
//   pdxjl: undefined,
// });
// //公路
// const highwayStandardBasis = ref({
//   configurationName: "highwayStandardBasis",
//   id: null,
//   configurationValue: {},
//   jccjl: undefined,
//   jcxjl: undefined,
//   pdcjl: undefined,
//   pdxjl: undefined,
// });
// //铁路
// const railwayStandardBasis = ref({
//   configurationName: "railwayStandardBasis",
//   id: null,
//   configurationValue: {},
//   jccjl: undefined,
//   jcxjl: undefined,
//   pdcjl: undefined,
//   pdxjl: undefined,
// });

const executionStandardsBasis = ref({
  configurationName: "executionStandards",
  id: null,
  configurationValue: {},
});

//检测单位
const sysTestingUnitName = ref({
  configurationName: "defaultTestingUnit",
  id: null,
  configurationValue: {},
  testingUnitName: undefined, //检测单位
});

//试验模式
const sysExperimentalMode = ref({
  configurationName: "experimentalModeValue",
  id: null,
  configurationValue: {},
  experimentalMode: undefined, //试验模式
});

//设备预警
const sysEquipmentWarning = ref({
  configurationName: "equipmentWarning",
  id: null,
  configurationValue: {
    samplingTime: 0,
    detectionTime: 0,
    moistureContent: {
      max: 0,
      min: 0,
    },
    mudContent: {
      max: 0,
      min: 0,
    },
    samplingMass: {
      max: 0,
      min: 0,
    },
  },
});

const sysExecutionStandardsad = ref({
  configurationName: "executionStandards",
  id: null,
  configurationValue: {},
});
onMounted(() => {
  getExecutionStandard();
  searchTestingBasis();
  searchTestingUnitNameMode();

  getSystemConfig();
});

/**
 * @introduction 获取系统参数信息
 */
const getSystemConfig = () => {
  // { configurationName: "detectionItemConfig" }
  apiTopHeader
    .getAllConfig()
    .then((res) => {
      // console.log(res);
      let configData = res.data.data;
      if (
        configData !== "" &&
        configData !== undefined &&
        configData !== null
      ) {
        try {
          //检测项目
          let mdetectionItemConfig = configData.find((item) => {
            return item.configurationName == "detectionItemConfig";
          });
          let mcfgdvl = JSON.parse(mdetectionItemConfig.configurationValue);
          if (typeof mcfgdvl == "string") {
            mcfgdvl = JSON.parse(mcfgdvl);
          }

          //执行标准选择
          let mexecutionStandards = configData.find((item) => {
            return item.configurationName == "executionStandards";
          });
          let mpexe = mexecutionStandards.configurationValue
            ? JSON.parse(mexecutionStandards.configurationValue)
            : "";
          if (mpexe.length) {
            for (let i = 0; i < mpexe.length; i++) {
              mpexe[i].basis.determineBasisC =
                mpexe[i].basis?.determineBasisC?.split(",");
              mpexe[i].basis.determineBasisX =
                mpexe[i].basis?.determineBasisX?.split(",");
              mpexe[i].basis.inspectionBasisC =
                mpexe[i].basis?.inspectionBasisC?.split(",");
              mpexe[i].basis.inspectionBasisX =
                mpexe[i].basis?.inspectionBasisX?.split(",");

              mpexe[i].basis.determineBasisC = mpexe[
                i
              ].basis.determineBasisC.map((item) => {
                return Number(item);
              });
              mpexe[i].basis.determineBasisX = mpexe[
                i
              ].basis.determineBasisX.map((item) => {
                return Number(item);
              });
              mpexe[i].basis.inspectionBasisC = mpexe[
                i
              ].basis.inspectionBasisC.map((item) => {
                return Number(item);
              });
              mpexe[i].basis.inspectionBasisX = mpexe[
                i
              ].basis.inspectionBasisX.map((item) => {
                return Number(item);
              });
            }
          }

          Object.assign(executionStandardsBasis.value, {
            id: mexecutionStandards.id,
            configurationName: mexecutionStandards.configurationName,
            configurationValue: mpexe || "",
          });

          // console.log(executionStandardsBasis.value);

          // //国标依据

          // let nationalStandardBasisdata = configData.find((item) => {
          //   return item.configurationName == "nationalStandardBasis";
          // });
          // let mnationalStandardc = JSON.parse(
          //   nationalStandardBasisdata.configurationValue
          // );
          // console.log(mnationalStandardc, "89898998");
          // mnationalStandardc.inspectionBasisC =
          //   mnationalStandardc.inspectionBasisC.split(",").map((item) => {
          //     return Number(item);
          //   });
          // mnationalStandardc.inspectionBasisX =
          //   mnationalStandardc.inspectionBasisX.split(",").map((item) => {
          //     return Number(item);
          //   });
          // mnationalStandardc.determineBasisC =
          //   mnationalStandardc.determineBasisC.split(",").map((item) => {
          //     return Number(item);
          //   });
          // mnationalStandardc.determineBasisX =
          //   mnationalStandardc.determineBasisX.split(",").map((item) => {
          //     return Number(item);
          //   });

          // //公路依据
          // let highwayStandardBasisdata = configData.find((item) => {
          //   return item.configurationName == "highwayStandardBasis";
          // });
          // let mhighwayStandardBasisdc = JSON.parse(
          //   highwayStandardBasisdata.configurationValue
          // );
          // mhighwayStandardBasisdc.inspectionBasisC =
          //   mhighwayStandardBasisdc.inspectionBasisC.split(",").map((item) => {
          //     return Number(item);
          //   });
          // mhighwayStandardBasisdc.inspectionBasisX =
          //   mhighwayStandardBasisdc.inspectionBasisX.split(",").map((item) => {
          //     return Number(item);
          //   });
          // mhighwayStandardBasisdc.determineBasisC =
          //   mhighwayStandardBasisdc.determineBasisC.split(",").map((item) => {
          //     return Number(item);
          //   });
          // mhighwayStandardBasisdc.determineBasisX =
          //   mhighwayStandardBasisdc.determineBasisX.split(",").map((item) => {
          //     return Number(item);
          //   });

          // //铁路路依据
          // let railwayStandardBasisdata = configData.find((item) => {
          //   return item.configurationName == "railwayStandardBasis";
          // });
          // let mrailwayStandardBasisdc = JSON.parse(
          //   railwayStandardBasisdata.configurationValue
          // );
          // mrailwayStandardBasisdc.inspectionBasisC =
          //   mrailwayStandardBasisdc.inspectionBasisC.split(",").map((item) => {
          //     return Number(item);
          //   });
          // mrailwayStandardBasisdc.inspectionBasisX =
          //   mrailwayStandardBasisdc.inspectionBasisX.split(",").map((item) => {
          //     return Number(item);
          //   });
          // mrailwayStandardBasisdc.determineBasisC =
          //   mrailwayStandardBasisdc.determineBasisC.split(",").map((item) => {
          //     return Number(item);
          //   });
          // mrailwayStandardBasisdc.determineBasisX =
          //   mrailwayStandardBasisdc.determineBasisX.split(",").map((item) => {
          //     return Number(item);
          //   });

          // //检测依据粗
          // let minspectionBasisC = configData.find((item) => {
          //   return item.configurationName == "inspectionBasisC";
          // });
          // let minc = minspectionBasisC.configurationValue.split(",");
          // for (let i in minc) {
          //   minc[i] = Number(minc[i]);
          // }
          // //检测依据细
          // let mInspectionBasisX = configData.find((item) => {
          //   return item.configurationName == "InspectionBasisX";
          // });
          // let minx = mInspectionBasisX.configurationValue.split(",");
          // for (let i in minx) {
          //   minx[i] = Number(minx[i]);
          // }
          //检测单位
          let mdefaultTestingUnit = configData.find((item) => {
            return item.configurationName == "defaultTestingUnit";
          });
          let munit = mdefaultTestingUnit.configurationValue
            ? JSON.parse(mdefaultTestingUnit.configurationValue)
            : "";

          let mdexecutionStandards = configData.find((item) => {
            return item.configurationName == "executionStandards";
          });

          Object.assign(sysExecutionStandardsad.value, {
            id: mdexecutionStandards.id,
            configurationName: mdexecutionStandards.configurationName,
            configurationValue: mdexecutionStandards.configurationValue,
          });
          Object.assign(sysParameter.value, {
            id: mdetectionItemConfig.id,
            configurationName: mdetectionItemConfig.configurationName,
            configurationValue: mcfgdvl,
          });
          let mdexecutionStandardal = configData.find((item) => {
            return item.configurationName == "executionStandard";
          });

          let madrs = mdexecutionStandardal.configurationValue
            ? JSON.parse(mdexecutionStandardal.configurationValue)
            : "";
          Object.assign(sysExecutionStandards.value, {
            id: mdexecutionStandardal.id,
            configurationName: mdexecutionStandardal.configurationName,
            executionStandards: madrs.value || "",
          });

          let msysEquipmentWarning = configData.find((item) => {
            return item.configurationName == "equipmentWarning";
          });
          // console.log(msysEquipmentWarning);

          let meqwra = msysEquipmentWarning.configurationValue
            ? JSON.parse(msysEquipmentWarning.configurationValue)
            : "";
          Object.assign(sysEquipmentWarning.value, {
            id: msysEquipmentWarning.id,
            configurationName: msysEquipmentWarning.configurationName,
            configurationValue: meqwra || "",
          });

          // Object.assign(nationalStandardBasis.value, {
          //   id: nationalStandardBasisdata.id,
          //   configurationName: nationalStandardBasisdata.configurationName,
          //   jccjl: mnationalStandardc.inspectionBasisC,
          //   jcxjl: mnationalStandardc.inspectionBasisX,
          //   pdcjl: mnationalStandardc.determineBasisC,
          //   pdxjl: mnationalStandardc.determineBasisX,
          // });
          // Object.assign(highwayStandardBasis.value, {
          //   id: highwayStandardBasisdata.id,
          //   configurationName: highwayStandardBasisdata.configurationName,
          //   jccjl: mhighwayStandardBasisdc.inspectionBasisC,
          //   jcxjl: mhighwayStandardBasisdc.inspectionBasisX,
          //   pdcjl: mhighwayStandardBasisdc.determineBasisC,
          //   pdxjl: mhighwayStandardBasisdc.determineBasisX,
          // });
          // Object.assign(railwayStandardBasis.value, {
          //   id: railwayStandardBasisdata.id,
          //   configurationName: railwayStandardBasisdata.configurationName,
          //   jccjl: mrailwayStandardBasisdc.inspectionBasisC,
          //   jcxjl: mrailwayStandardBasisdc.inspectionBasisX,
          //   pdcjl: mrailwayStandardBasisdc.determineBasisC,
          //   pdxjl: mrailwayStandardBasisdc.determineBasisX,
          // });
          // console.log(railwayStandardBasis.value);
          // Object.assign(sysTestingBasisC.value, {
          //   id: minspectionBasisC.id,
          //   configurationName: minspectionBasisC.configurationName,
          //   ctestingBasis: minc,
          // });
          // Object.assign(sysTestingBasisX.value, {
          //   id: mInspectionBasisX.id,
          //   configurationName: mInspectionBasisX.configurationName,
          //   xtestingBasis: minx,
          // });
          Object.assign(sysTestingUnitName.value, {
            id: mdefaultTestingUnit.id,
            configurationName: mdefaultTestingUnit.configurationName,
            testingUnitName: munit.value ? munit.value : "",
          });

          //试验模式
          let mExperimentalMode = configData.find((item) => {
            return item.configurationName == "experimentalMode";
          });
          let mmddata = mExperimentalMode.configurationValue
            ? JSON.parse(mExperimentalMode.configurationValue)
            : "";
          experimentalModeOptions.value = mmddata.map((item) => {
            return {
              value: item.value,
              label: item.label,
            };
          });

          let mExperimentalModeval = configData.find((item) => {
            return item.configurationName == "experimentalModeValue";
          });
          let mmddataval = mExperimentalModeval.configurationValue
            ? JSON.parse(mExperimentalModeval.configurationValue)
            : "";

          Object.assign(sysExperimentalMode.value, {
            id: mExperimentalModeval.id,
            configurationName: mExperimentalModeval.configurationName,
            experimentalMode: mmddataval.value || "",
          });

        
        } catch (error) {
          console.error(error);
        }
      }
    })
    .catch((error) => {
      console.log(error);
      message.error(error.data.message, 2);
    });
};

const exeChneg = (e) => {
  // console.log(e);
};

/**
 * @introduction 保存系统参数
 */
const handleSubmit = async () => {
  let mdataswr = sysEquipmentWarning.value;
  if (
    mdataswr.configurationValue.moistureContent.max <=
    mdataswr.configurationValue.moistureContent.min
  ) {
    message.warning("含水率预警阈值最大数值不能小于等于最小数值", 2);
    return;
  }
  if (
    mdataswr.configurationValue.mudContent.max <=
    mdataswr.configurationValue.mudContent.min
  ) {
    message.warning("含泥量预警阈值最大数值不能小于等于最小数值", 2);
    return;
  }
  if (
    mdataswr.configurationValue.samplingMass.max <=
    mdataswr.configurationValue.samplingMass.min
  ) {
    message.warning("取样质量最大数值不能小于等于最小数值", 2);
    return;
  }

  let pusas = [];
  let mdatas1 = sysParameter.value;
  mdatas1.configurationValue = JSON.stringify(mdatas1.configurationValue);
  pusas.push(mdatas1);
  //执行标准
  let mdatas2 = sysExecutionStandards.value;
  let mexs = executionStandardOptions.value.find((item) => {
    return item.value == mdatas2.executionStandards;
  });
  mdatas2.configurationValue = mexs ? JSON.stringify(mexs) : "";
  pusas.push(mdatas2);

  let mdatas3 = executionStandardsBasis.value;
  for (let i = 0; i < mdatas3.configurationValue.length; i++) {
    mdatas3.configurationValue[i].basis.determineBasisC =
      mdatas3.configurationValue[i].basis.determineBasisC.join(",");
    mdatas3.configurationValue[i].basis.determineBasisX =
      mdatas3.configurationValue[i].basis.determineBasisX.join(",");
    mdatas3.configurationValue[i].basis.inspectionBasisC =
      mdatas3.configurationValue[i].basis.inspectionBasisC.join(",");
    mdatas3.configurationValue[i].basis.inspectionBasisX =
      mdatas3.configurationValue[i].basis.inspectionBasisX.join(",");
  }
  mdatas3.configurationValue = JSON.stringify(mdatas3.configurationValue);
  pusas.push(mdatas3);
  // //检测依据粗
  // let mdatas3 = sysTestingBasisC.value;
  // let tebasarr = [];
  // for (let k in mdatas3.ctestingBasis) {
  //   tebasarr.push(mdatas3.ctestingBasis[k]);
  // }
  // mdatas3.configurationValue = tebasarr.length > 0 ? tebasarr.join(",") : "";
  // pusas.push(mdatas3);
  // //检测依据细
  // let mdatas4 = sysTestingBasisX.value;
  // let tebasarr1 = [];
  // for (let k in mdatas4.xtestingBasis) {
  //   tebasarr1.push(mdatas4.xtestingBasis[k]);
  // }
  // mdatas4.configurationValue = tebasarr1.length > 0 ? tebasarr1.join(",") : "";
  // pusas.push(mdatas4);
  //国标依据
  // let mdatas3 = nationalStandardBasis.value;
  // let tebasarr = {
  //   inspectionBasisC: mdatas3.jccjl ? mdatas3.jccjl.join(",") : "",
  //   inspectionBasisX: mdatas3.jcxjl ? mdatas3.jcxjl.join(",") : "",
  //   determineBasisC: mdatas3.pdcjl ? mdatas3.pdcjl.join(",") : "",
  //   determineBasisX: mdatas3.pdxjl ? mdatas3.pdxjl.join(",") : "",
  // };
  // mdatas3.configurationValue = JSON.stringify(tebasarr);
  // pusas.push(mdatas3);
  // //公路依据
  // let mdatas4 = highwayStandardBasis.value;
  // let tebasarr1 = {
  //   inspectionBasisC: mdatas4.jccjl ? mdatas4.jccjl.join(",") : "",
  //   inspectionBasisX: mdatas4.jcxjl ? mdatas4.jcxjl.join(",") : "",
  //   determineBasisC: mdatas4.pdcjl ? mdatas4.pdcjl.join(",") : "",
  //   determineBasisX: mdatas4.pdxjl ? mdatas4.pdxjl.join(",") : "",
  // };
  // mdatas4.configurationValue = JSON.stringify(tebasarr1);
  // pusas.push(mdatas4);
  // //铁路依据
  // let mdatas7 = railwayStandardBasis.value;
  // let tebasarr2 = {
  //   inspectionBasisC: mdatas7.jccjl ? mdatas7.jccjl.join(",") : "",
  //   inspectionBasisX: mdatas7.jcxjl ? mdatas7.jcxjl.join(",") : "",
  //   determineBasisC: mdatas7.pdcjl ? mdatas7.pdcjl.join(",") : "",
  //   determineBasisX: mdatas7.pdxjl ? mdatas7.pdxjl.join(",") : "",
  // };
  // mdatas7.configurationValue = JSON.stringify(tebasarr2);
  // pusas.push(mdatas7);

  //检测单位
  let mdatas5 = sysTestingUnitName.value;
  let mexstex = testingUnitNameOptions.value.find((item) => {
    return item.value == mdatas5.testingUnitName;
  });
  mdatas5.configurationValue = mexstex ? JSON.stringify(mexstex) : "";
  pusas.push(mdatas5);

  //设备检测
  let mdatas6 = JSON.parse(JSON.stringify(sysEquipmentWarning.value));
  let mcfing = mdatas6.configurationValue
    ? JSON.stringify(mdatas6.configurationValue)
    : "";
  mdatas6.configurationValue = mcfing;

  pusas.push(mdatas6);

  //试验模式
  let mdatas7 = sysExperimentalMode.value;
  let memdval = experimentalModeOptions.value.find((item) => {
    return item.value == mdatas7.experimentalMode;
  });
  mdatas7.configurationValue = memdval ? JSON.stringify(memdval) : "";
  pusas.push(mdatas7);

  apiTopHeader
    .sysConfigSave(pusas)
    .then((res) => {
      if (res.data.code == 0) {
        message.success("保存成功", 2);
        closeModal();
      }
    })
    .catch((error) => {
      console.log(error);
      message.error(error.data.message, 2);
    });
};

//获取执行标准
const getExecutionStandard = () => {
  apiCenterBottom
    .getExecutionStandards()
    .then((res) => {
      if (res.data.code == 0) {
        // formState1.testingUnit = undefined;
        executionStandardOptions.value = res.data.data.map((item) => {
          return {
            value: item.standardValue,
            label: item.standardName,
          };
        });
      }
    })
    .catch(() => {
      message.error("获取执行标准失败", 2);
    });
};
//获取检测依据
const searchTestingBasis = () => {
  apiCenterBottom
    .getTestingBasisList()
    .then((res) => {
      if (res.data.code == 0) {
        // formState1.testingUnit = undefined;
        testingBasisOptions.value = res.data.data.map((item) => {
          return {
            value: item.id,
            label: item.testingBasisAndCode,
          };
        });
      }
    })
    .catch(() => {
      message.error("获取供货单位失败", 2);
    });
};

//获取检测单位
const searchTestingUnitNameMode = () => {
  apiCenterBottom
    .getCompanyByType({ type: 5 })
    .then((res) => {
      if (res.data.code == 0) {
        testingUnitNameOptions.value = res.data.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        // formState1.testingUnit = undefined;
        // specificationModeOptions.value = res.data.data.map((item) => {
        //   return {
        //     value: item.dictDataId,
        //     label: item.dictDataName,
        //   };
        // });
      }
    })
    .catch(() => {
      message.error("获取检测单位失败", 2);
    });
};

/**
 * @introduction 关闭弹窗
 */
const closeModal = () => {
  emits("update:visible", false);
};
</script>

<style lang="scss" scoped>
@use '../../../assets/style/modalcoustom.scss';
.detection-name {
  font-size: 0.09rem;
  font-weight: bold;
  color: white;
}
.detection-name2 {
  font-size: 0.07rem;
  font-weight: bold;
  color: white;
}
.detection-row {
  padding: 0.1rem;
  .detection-row-item {
    font-size: 0.08rem;
    color: white;
    margin-right: 0.02rem;
    vertical-align: middle;
  }
}
.registe-list {
  min-width: 720px !important;
}
.regist-type {
  margin-top: 0.04rem;
  font-size: 0.13rem;
}
// .close-btn {
//   margin-left: auto;
//   z-index: 9999;
//   background: none;
//   border: none;
//   font-size: 0.08rem;
//   color: white;
// }
.fromline {
  display: flex;
  align-items: center;
  position: relative;
}
.agrund {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.agrund-item {
  width: 30%;
  margin-right: 1%;
  padding: 0.04rem 0;
  text-align: center;
  background: rgba(18, 27, 37, 0.8);
  border: 1px solid #9ecdfc;
  margin-bottom: 0.06rem;
  color: #fff;
  border-radius: 0.02rem;
  cursor: pointer;
  font-size: 0.07rem;
}
.agrund .agrundActive {
  background: rgb(0, 183, 255, 0.4);
  border: 1px solid rgb(0, 183, 255);
}
.footes {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mrdos {
  color: #fff;
  margin-right: 30px;
  margin-bottom: 10px;
}
</style>

<style>
.sampsle
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  color: #fff;
}
.sampsle .ant-select-multiple .ant-select-selection-item {
  background: #195894;
  color: #fff;
  border: none;
}
.sampsle .ant-select-multiple .ant-select-selection-item-remove {
  color: #fff;
}
.sampsle .ant-picker {
  background: #121b25;
  border-color: #9ecdfc !important;
  color: white;
  width: 100%;
}
.sampsle .ant-picker-suffix {
  color: #fff;
}
.sampsle .ant-picker-input > input {
  color: #fff;
}
.mipusts {
  background-color: #121b25;
  border: 1px solid #9ecdfc;
  padding: 4px 12px;
  box-sizing: border-box;
  color: #fff;
}

.mipusts:focus {
  background-color: #121b25;
  border: 1px solid rgb(0, 183, 255);
  outline: none;
}

.mipusts::-webkit-inner-spin-button,
.mipusts::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mipusts[type="number"] {
  -moz-appearance: textfield;
}
.mspans {
  font-size: 14px;
  color: #fff;
  margin-right: 10px;
  margin-left: 7px;
}
</style>
