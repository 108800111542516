<template>
  <a-modal
    :visible="props.visible"
    width="100%"
    wrap-class-name="table-modal"
    centered
    class="registe-list"
    :closeIcon="true"
    @cancel="closeModal"
  >
    <template #title>
      <div class="modal-name">json编辑</div>
      <a-button class="close-btn" @click="closeModal">关闭 </a-button>
    </template>
    <div class="modlcont editor-content" style="padding: 0 0.5rem">
      <JsonEditorVue
        class="editor"
        v-model="data.jsonData"
        :showBtns="false"
        v-model:mode="data.mode"
        :read-only="data.readOnly"
        :stringified="data.stringified"
      />
    </div>
    <template #footer>
      <div class="footes">
        <a-button type="primary" ghost @click="closeModal">取消</a-button>
        <a-button type="primary" @click="handleSubmit" :loading="saveLoading">
          保存
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script setup>
import { ref, defineProps, onMounted, defineEmits, reactive } from 'vue';
import { apiTopHeader } from '@/api/api-topHeader.js';
import { Empty, message } from 'ant-design-vue';
import { cloneDeep } from 'lodash';
import JsonEditorVue from 'json-editor-vue';
// import { deviceParameter } from '../../js/parameterJson';

const emits = defineEmits(['update:visible']);
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
    default: false,
  },
});
const saveLoading = ref(false);
const data = reactive({
  // jsonData: deviceParameter,
  jsonData: {},
  mode: 'tree',
  readOnly: false,
  stringified: false,
});
onMounted(() => {
  initData();
});

/**
 * @name guochong
 * @Date 2024-11-28 17:32:30
 * @introduction 初始化设备参数json数据
 * @description 详细描述
 */
const initData = async () => {
  try {
    let res = await apiTopHeader.getDeviceParamJsonData();
    let jsonData = JSON.parse(res.data.message);
    data.jsonData = jsonData;
  } catch (error) {
    console.error(error);
    message.error('获取设备参数失败', 2);
  }
};
const handleSubmit = async () => {
  saveLoading.value = true;
  try {
    let params = {
      deviceParamJson: JSON.stringify(data.jsonData),
    };
    await apiTopHeader.saveDeviceParamJsonData(params);
    message.success('保存成功');
    saveLoading.value = false;
    closeModal();
  } catch (error) {
    saveLoading.value = false;
    console.error(error);
    message.error('保存设备参数失败', 2);
  }
};
/**
 * @introduction 关闭弹窗
 */
const closeModal = () => {
  emits('update:visible', false);
};
</script>

<style lang="scss" scoped>
.editor-content {
  padding: 0 !important;
}
.footes {
  padding-bottom: 0.06rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1%;
}
</style>
