export default {
    //token
    token: '',
    //url
    url: '',
    //username
    username: '',
    //password
    password: '',
    // 路由白名单(不需要登录的)
    whiteList: ['/login', '/home', '/detectionScreen', '/weighbridgeScreen', '/liveMonitoring','/404'],
    // 用户信息接口
    userUrl: '/auth/user',
    // 自定义解析接口用户信息
    parseUser: null,
    // 用户角色
    userRole:'userRole',
    // token传递的header名称
    tokenHeaderName: 'Authorization',
    // token存储的名称
    tokenStoreName: 'access_token',
    // 创建sse创建连接的clientId字段标识
    clientId:"clientId",
    // 线上创建sse创建连接的clientId字段标识
    clientIdOnline:"clientIdOnline",
    // 获取缓存的token
    takeToken() {
        let token = localStorage.getItem(this.tokenStoreName);
        if (!token) {
            token = sessionStorage.getItem(this.tokenStoreName);
        }
        return token;
    },
    // 缓存token
    cacheToken(token, remember) {
        localStorage.removeItem(this.tokenStoreName);
        sessionStorage.removeItem(this.tokenStoreName);
        if (token) {
            if (remember) {
                localStorage.setItem(this.tokenStoreName, token);
            } else {
                sessionStorage.setItem(this.tokenStoreName, token);
            }
        }
    },
    // 用户信息存储的名称
    userStoreName: 'user',
    // 获取缓存的用户信息
    takeUser() {
        try {
            return JSON.parse(sessionStorage.getItem(this.userStoreName)) || {};
        } catch (e) {
            console.error(e);
        }
        return {};
    },
    // 缓存用户信息
    cacheUser(user) {
        if (user) {
            sessionStorage.setItem(this.userStoreName, JSON.stringify(user));
        } else {
            sessionStorage.removeItem(this.userStoreName);
        }
    },
    // 缓存用户角色信息
    saveUserRole(role) {
        if (role) {
            // console.log('用户角色',role);
            localStorage.setItem(this.userRole, JSON.stringify(role))
        }
    },
    // 移除用户角色信息
    remmoveUserRole() {
        if (localStorage.getItem(this.userRole)) {
            localStorage.removeItem(this.userRole)
            // console.log('移除用户角色信息成功',localStorage.getItem(this.userRole));
        }
    },
    //存储请求operateId
    cacheOperateId(operateId, config) {
        if (operateId) {
            sessionStorage.setItem(operateId, JSON.stringify(config));
        } else {
            sessionStorage.removeItem(operateId)
        }
    },
    //获取operateId对应的信息
    takeOperateId(operateId) {
        try {
            return JSON.parse(sessionStorage.getItem(operateId)) || {};
        } catch (e) {
            console.error(e);
        }
        return {};
    }
}