<template>
  <div class="left-card-1 card">
    <div
      class="card-top"
      :class="
        aggregates.includes('机制砂') || aggregates.includes('混合砂')
          ? 'card-top-shi'
          : aggregates.includes('碎石')
          ? 'card-top-ni'
          : 'card-top-other'
      "
    >
      <!-- <img src="../assets/left/mud/mud-xi.png" alt="" style="height: 100%" /> -->
      <span class="card-top-center">
        {{
          `${dryDetectionData?.workId?.slice(0, 4) || '-'}-${
            dryDetectionData?.workId?.slice(-4) || '-'
          }`
        }}
      </span>
    </div>

    <div class="card-center">
      <div class="card-center-left">
        <div class="water">
          <div class="water-value">
            {{ tool.formatNumber(dryDetectionData?.moistureContent) ?? '-' }}%
          </div>
          <div>含水率</div>
        </div>
        <div class="quality-value">
          <div>
            {{ dryDetectionData?.initWaterWeight?.toFixed(1) ?? '--' }}g
          </div>
          <div>|</div>
          <div>{{ dryDetectionData?.dryWeight?.toFixed(1) ?? '--' }}g</div>
        </div>
      </div>
      <div class="card-center-right">
        <div class="mud">
          <div class="water-value">
            {{ tool.formatNumber(washDetectionData?.mudContent) ?? '-' }}%
          </div>
          <div
            v-if="
              aggregates.includes('机制砂') || aggregates.includes('混合砂')
            "
          >
            石粉含量
          </div>
          <div v-else-if="aggregates.includes('碎石')">泥粉含量</div>
          <div v-else>含泥量</div>
        </div>
        <div class="quality-value">
          <div>{{ washDetectionData?.initMudWeight?.toFixed(1) ?? '--' }}g</div>
          <div>|</div>
          <div>
            {{ washDetectionData?.washingAndDryingWeight?.toFixed(1) ?? '--' }}g
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { nextTick } from 'vue';
import tool from './js/tool';
const store = useStore();
// 含水率结果
const dryDetectionData = ref({});
// 集料类型
// let aggregatesType = ref('');
const aggregates = ref('');
// // 前20个检测记录表数据
// let sampleList = ref([]);
// 含泥量/石粉含量全部数据
const washDetectionData = ref({});
/**
 * @name 郭冲
 * @Date 2023-09-02 11:25:52
 * @introduction 获取当前标准
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
// const getCurrentStandards = () => {
//   apiTopHeader
//     .getCurrentStandards()
//     .then((res) => {
//       if (res.data.code === 0) {
//         res.data.data.forEach((item) => {
//           if (aggregates.value.includes(item.aggregatesType)) {
//             //修改之前的标准
//             waterDetectionStandard.value = item.waterContent;
//           }
//         });
//       }
//     })
//     .catch((error) => {
//       console.error(error);
//       message.error('获取当前含水率标准失败', 2);
//     });
// };
// watchEffect(() => {
//   let mewIsEditStandard = store.state.device.isEditStandard;
//   if (typeof mewIsEditStandard == 'boolean') {
//     console.log('detectionStandard 变了', mewIsEditStandard);
//     getCurrentStandards();
//   }
// });
/**
 * @name 郭冲
 * @Date 2023-09-02 10:47:09
 * @introduction 监听器-监听含水率结果
 * @description 详细描述
 * @param {any} newValue 变化后的数据
 * @param {any} oldValue 变化前的数据
 * @return 无返回类型
 * @exception [违例类型] [违例类型说明]
 */
watch(
  () => store.state.engine.allEngineData,
  (newValue) => {
    const newWashDevice = newValue?.washDeviceX || {}; //最新检测项的含泥结果数据
    const newDryDevice = newValue?.dryDeviceX || {}; //最新检测项的含水结果数据
    const newToBeTestedQueueToList = newValue?.toBeTestedQueueToList || []; //根据workId与testNumber是否相同去获取当前检测项的集料规格类型，然后获取最新的含水含泥
    // console.log('🚀 ~ newToBeTestedQueueToList:', newToBeTestedQueueToList);
    nextTick(() => {
      if (newToBeTestedQueueToList) {
        let washDetectItem = newToBeTestedQueueToList?.find(
          (element) => element.testNumber == newWashDevice?.workId
        );
        let dryDetectItem = newToBeTestedQueueToList?.find(
          (element) => element.testNumber == newDryDevice?.workId
        );
        let isWashEarlier = washDetectItem?.detectDatetime > dryDetectItem?.detectDatetime;
        let isDryEarlier = washDetectItem?.detectDatetime < dryDetectItem?.detectDatetime;

        if (isWashEarlier) {
          aggregates.value = washDetectItem?.aggregatesSpecs || '';
          washDetectionData.value = newWashDevice;
          dryDetectionData.value = {};
        } else if (isDryEarlier) {
          aggregates.value = dryDetectItem?.aggregatesSpecs || '';
          washDetectionData.value = {};
          dryDetectionData.value = newDryDevice;
        } else {
          // 时间相同
          aggregates.value = washDetectItem?.aggregatesSpecs || dryDetectItem?.aggregatesSpecs || '';
          washDetectionData.value = washDetectItem ? newWashDevice : {};
          dryDetectionData.value = dryDetectItem ? newDryDevice : {};
        }
        // newToBeTestedQueueToList?.forEach((item) => {
        //   // 含水含泥结果同时更新的
        //   if (
        //     newWashDevice?.workId == item.testNumber &&
        //     newDryDevice?.workId == item.testNumber &&
        //     item.aggregatesSpecs.includes('细')
        //   ) {
        //     aggregates.value = item.aggregatesSpecs;
        //     washDetectionData.value = newWashDevice;
        //     dryDetectionData.value = newDryDevice;
        //   }
        // });
      } else {
        aggregates.value = '';
        washDetectionData.value = {};
        dryDetectionData.value = {};
      }
      //根据workId与testNumber是否相同去获取当前检测项的集料规格类型，然后获取最新的含水含泥
    });
  },
  { immediate: false }
);

onMounted(() => {
  // mchart.value.setOption(getTemperatureOption([], []));
  // gaugechart.value?.setOption(tool.getGaugeChartsOption('-'), '');
  // 获取当前标准
  // getCurrentStandards();
});

/**
 * @name 郭冲
 * @Date 2023-09-02 13:59:06
 * @introduction 获取所有检测数据
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
// const getDetectionList = () => {
//   let params = {
//     page: 1,
//     limit: 20,
//     statusList:[]
//   };
//   // 样品列表弹窗---获取样品列表数据
//   apiMainfoots
//     .getSampleList(params)
//     .then((res) => {
//       if (res.data.code == 0) {
//         sampleList.value = res.data.data.data;
//       }
//     })
//     .catch(() => {
//       message.error('获取样品列表数据失败', 2);
//     });
// };
</script>

<style scoped lang="scss">
@use '../assets/style/common.scss';
@import '../../static/font.css';
.left-card-1 {
  .card-top-shi {
    background: url(../assets/left/mud/mud-shi-xi.png) 0%/100% no-repeat;
  }
  .card-top-ni {
    background: url(../assets/left/mud/mud-ni-xi.png) 0%/100% no-repeat;
  }
  .card-top-other {
    background: url(../assets/left/mud/mud-xi.png) 0%/100% no-repeat;
  }
}
.chart {
  background-color: transparent;
}

.left-card-1 {
  .card-top {
    height: 35%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .card-top-center {
      font-family: 'PangMenZhengDao', sans-serif;
      font-size: 0.09rem;
    }
  }

  .card-center {
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0% 3% 0% 0%;

    .card-center-left,
    .card-center-right {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      // column-gap: 3%;
      .water,
      .mud {
        width: 70%;
        height: 88%;
        background: url(../assets/left/mud/bg-shui.png) center/0% no-repeat;
        background-size: contain;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 0.07rem;
        .water-value {
          font-weight: bold;
          color: #5eaaff;
          font-size: 0.11rem;
        }
      }
      .mud {
        height: 88%;
        background: url(../assets/left/mud/bg-ni.png) center/0% no-repeat;
        background-size: contain;
      }
      .quality-value {
        color: white;
        font-size: 0.07rem;
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 2%;
      }
    }

    // .card-center-right {
    //   .mud {
    //   }
    // }
  }
}
</style>
