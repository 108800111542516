<!--添加供应商-->
<template>
  <a-modal
    :visible="props.visible"
    width="60%"
    wrap-class-name="qy-modal"
    centered
    :destroyOnClose="true"
    :closeIcon="true"
    style="color: black"
    @cancel="closeModal"
  >
    <template #title>
      <div ref="modalTitleRef" class="modal-name">
        {{ props.modalTitle }}
      </div>
      <a-button class="close-btn" @click="closeModal"> 关闭 </a-button>
    </template>
    <div>
      <div class="mod-content" style="padding: 0.5rem 0.5rem">
        <a-form :model="reqData" :rules="rules" ref="addUnitsRef">
          <a-form-item
            label="编号"
            name="testingBasisCode"
            :label-col="{ style: { width: '80px', textAlign: 'right' } }"
          >
            <div class="fromline">
              <a-input
                v-model:value="reqData.testingBasisCode"
                placeholder="请输入编号"
              />
            </div>
          </a-form-item>
          <a-form-item
            label="名称"
            name="testingBasis"
            :label-col="{ style: { width: '80px', textAlign: 'right' } }"
          >
            <div class="fromline">
              <a-input
                v-model:value="reqData.testingBasis"
                placeholder="请输入名称"
              />
            </div>
          </a-form-item>

          <!-- <a-form-item
            label="单位类型"
            :label-col="{ style: { width: '80px', textAlign: 'right' } }"
          >
            <div class="fromline">
              <a-select
                v-model:value="reqData.type"
                placeholder="请选择单位类型"
                :options="unitsGroupOptions"
              >
              </a-select>
            </div>
          </a-form-item>
          <a-form-item
            label="电话"
            name="phone"
            :label-col="{ style: { width: '80px', textAlign: 'right' } }"
          >
            <div class="fromline">
              <a-input v-model:value="reqData.phone" placeholder="请输入电话" />
            </div>
          </a-form-item>

          <a-form-item
            label="状态"
            :label-col="{ style: { width: '80px', textAlign: 'right' } }"
          >
            <div class="agrund">
              <div
                class="agrund-item"
                v-for="(item, index) in statusOptionsCopy"
                :class="{ agrundActive: item.isCk }"
                :key="index"
                @click="handleStatusChange(index)"
              >
                {{ item.name }}
              </div>
            </div>
          </a-form-item> -->
        </a-form>
      </div>
    </div>

    <template #footer>
      <div class="footes" @click="handleSave">
        <div class="mbtns stars">
          <span>保存</span>
        </div>
      </div>
    </template>
  </a-modal>
</template>
<script setup>
import {
  ref,
  onMounted,
  reactive,
  defineProps,
  toRefs,
  defineEmits,
} from "vue";
//TODO 下拉选项配置
import { statusOptions } from "../../js/opts.group.js";
// import { nextTick } from 'vue'
//TODO 导入api接口
import { apiTopHeader } from "@/api/api-topHeader";
import { message } from "ant-design-vue";
// import _ from 'lodash';
const emits = defineEmits(["update:visible", "refreshSamplist"]);
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
    default: false,
  },
  modalTitle: {
    type: String,
    required: true,
    default: "添加标准",
  },
  childGridRef: {
    type: Object,
    required: true,
  },
  childEditVars: {
    type: Object,
    required: true,
  },
});
let { childEditVars, childGridRef } = toRefs(props);
let tempChildEditVars = childEditVars;
let tempChildGridRef = childGridRef;
const addUnitsRef = ref(null);
/**
 * @name 郭冲
 * @Date 2023-05-26 17:25:58
 * @introduction 自定义供应商校验
 * @description 当供应商名已存在时提示'该供应商已被添加，请重新输入'
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
// let checkVendorName = async (_rule, value) => {
//   if (value !== '' && value !== undefined) {
//     return new Promise((resolve, reject) => {
//       apiTopHeader
//         .isExistVendorManage({ vendorName: value })
//         .then((res) => {
//           if (res.data.code === 0) {
//             resolve();
//           }
//         })
//         .catch((error) => {
//           if (error.data.code === 1) {
//             // 编辑时供应商名与点开编辑时的供应商名不同时才做校验
//             if (vendorNametmp === reqData.value.vendorName) {
//               resolve();
//             } else {
//               reject('该供应商已被添加，请重新输入');
//             }
//           }
//         });
//     });
//   }

//   return Promise.resolve();
// };
// 集料规格校验规则
const rules = {
  testingBasisCode: [
    {
      required: true,
      message: "编号不能为空",
      trigger: "blur",
    },
    // {
    //   validator: checkVendorName,
    //   trigger: 'blur',
    // },
  ],
  testingBasis: [
    {
      required: true,
      message: "名称不能为空",
      trigger: "blur",
    },
  ],
  
};
// let tableData = [];
// 供应集料管理表格的校验规则
// const validRules = ref({
//   aggregateId: [
//     { required: true, message: '供应集料不能为空', trigger: 'blur' },
//     {
//       /**
//        * @Date 2023-05-29 14:26:22
//        * @introduction aggregateId字段的校验
//        * @description 详细描述
//        */
//       validator({ cellValue }) {
//         // console.log(
//         //   '🚀 ~ file: add-supplier.vue:209 ~ validator ~ cellValue:',
//         //   cellValue
//         // )
//         // function countOccurrences(arr, target) {
//         //   return arr.reduce((acc, curr) => {
//         //     if (curr === target) {
//         //       acc++
//         //     }
//         //     return acc
//         //   }, 0)
//         // }
//         // if (
//         //     cellValue !== null &&
//         //     _.uniq(tableData).length !== tableData.length &&
//         //     (countOccurrences(tableData, cellValue) !== 1 ||
//         //       countOccurrences(tableData, cellValue) !== 0)
//         //   ) {
//         //     reject(new Error('不能重复选择该供应集料'))
//         //   } else {
//         //     resolve()
//         //   }
//         // })
//         // console.log(
//         //   '🚀 ~ file: add-supplier.vue:222 ~ validator ~ tableData:',
//         //   countOccurrences(tableData, cellValue)
//         // )
//         tableData = addTableRef.value
//           .getTableData()
//           .tableData?.map((item) => item.aggregateId)
//           .filter((item) => item !== null); // 获取表格数据

//         // 校验供应集料是否重复选择
//         return new Promise((resolve, reject) => {
//           if (
//             cellValue !== null &&
//             _.uniq(tableData)?.length !== tableData?.length
//           ) {
//             reject(new Error('不能重复选择该供应集料'));
//           } else {
//             resolve();
//           }
//         });
//       },
//     },
//   ],
//   manufacturesName: [
//     { required: true, message: '生产商名不能为空', trigger: 'blur' },
//   ],
// });
const closeModal = () => {
  emits("update:visible", false);
};
/**
 * @name 郭冲
 * @Date 2023-05-29 14:24:59
 * @introduction 校验供应集料是否重复选择
 * @description 切换option时触发校验
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
// const aggregatesChange = async () => {
//   await addTableRef.value.validate();
// };

//节点
// let addTableRef = ref();

let reqData = ref({
  // status: 1,
});
// let unitsGroupOptions = ref([...unitsOptions]);
let statusOptionsCopy = ref([...statusOptions]);
//集料下拉项集合
let AggregatesGroupOptions = ref([]);
//备份
let AggregatesGroupOptionsCopy = ref([]);
//TODO 集料集合下拉项拼接
const handleJoinGroupOptions = () => {
  //请求接口
  apiTopHeader.queryEnableAggregates({ status: 1 }).then((res) => {
    // console.log(res);
    if (res.data.code == 0) {
      if (res.data.data && res.data.data.length == 0) {
        AggregatesGroupOptions.value = [];
      } else if (res.data.data && res.data.data.length > 0) {
        for (let i = 0; i < res.data.data.length; i++) {
          let tempObj = {};
          tempObj.label =
            res.data.data[i]["aggregatesTypeParent"] +
            "-" +
            res.data.data[i]["aggregatesType"] +
            "-" +
            res.data.data[i]["aggregatesSpecs"];
          // if (res.data.data[i]["aggregateCategory"] == 1) {
          //   tempObj.label = tempObj.label + "-Ⅰ类";
          // }
          // if (res.data.data[i]["aggregateCategory"] == 2) {
          //   tempObj.label = tempObj.label + "-Ⅱ类";
          // }
          // if (res.data.data[i]["aggregateCategory"] == 3) {
          //   tempObj.label = tempObj.label + "-Ⅲ类";
          // }
          tempObj.value = res.data.data[i]["id"];
          AggregatesGroupOptions.value.push(tempObj);
          AggregatesGroupOptionsCopy.value.push(tempObj);
        }
      }
    }
  });
};
// 供应商名字临时变量（编辑时供应商名与之前保存的不同时才做校验）
let vendorNametmp = reactive("");
onMounted(async () => {
  //组合下拉项
  handleJoinGroupOptions();
  console.log(tempChildEditVars.value);
  //编辑数据渲染
  if (tempChildEditVars.value.currentId) {
    //编辑
    if (tempChildEditVars.value.item) {
      // console.log(
      //   '🚀 ~ file: add-supplier.vue:291 ~ onMounted ~ tempChildEditVars.value.item:',
      //   tempChildEditVars.value.item
      // );
      // tableData = tempChildEditVars.value.item.aggregates?.map(
      //   (item) => item.id
      // );
      // console.log(
      //   '🚀 ~ file: add-supplier.vue:302 ~ onMounted ~ tableData:',
      //   tableData
      // );
      // 供应商名字临时变量（编辑时供应商名与之前保存的不同时才做校验）
      // vendorNametmp = tempChildEditVars.value.item.name;
      console.log(vendorNametmp);
      //渲染数据赋值
      reqData.value.testingBasis = tempChildEditVars.value.item.testingBasis;
      reqData.value.testingBasisCode = tempChildEditVars.value.item.testingBasisCode;
      // reqData.value.phone = tempChildEditVars.value.item.phone;
      // reqData.value.status = tempChildEditVars.value.item.status;
      reqData.value.id = tempChildEditVars.value.item.id;
      // reqData.value.type = tempChildEditVars.value.item.type;
      // 临时数据
      // let tempArr = tempChildEditVars.value.item.aggregates;
      // let tempArr2 = [];
      // for (let i = 0; i < tempArr?.length; i++) {
      //   let tempObj = {};
      //   tempObj.manufacturesName = tempArr[i]["manufacturersName"];
      //   tempObj.manufacturersId = tempArr[i]["manufacturersId"];
      //   tempObj.aggregateId = tempArr[i]["id"];

      //   //循环插入表格数据
      //   addTableRef.value.insert(tempObj);
      //   tempArr2.push(tempObj);
      // }
      //加载表格
      // addTableRef.value.reloadData(tempArr2);

      //重置所有状态
      for (let i = 0; i < statusOptionsCopy.value.length; i++) {
        statusOptionsCopy.value[i].isCk = false;
      }
      //状态字段渲染
      for (let i = 0; i < statusOptionsCopy.value.length; i++) {
        if (reqData.value.status == 1) {
          if (statusOptionsCopy.value[i].name == "启用") {
            statusOptionsCopy.value[i].isCk = true;
            break;
          }
        } else if (reqData.value.status == 0) {
          if (statusOptionsCopy.value[i].name == "停用") {
            statusOptionsCopy.value[i].isCk = true;
            break;
          }
        }
      }
    }
  }
});

//格式化集料管理
// const formatAggregate = (val) => {
//   const item = AggregatesGroupOptionsCopy.value.find(
//     (item) => item.value === val
//   );
//   return item ? item.label : val;
// };

//添加动态列
// const handleAddColumn = () => {
//   addTableRef.value.insert({});
// };
// //删除动态列
// const removeEvent = (row) => {
//   addTableRef.value.remove(row);
//   //TODO 还原下拉项目
//   // console.log(row);
// };

//处理状态选择
// const handleStatusChange = (idx) => {
//   //重置所有状态
//   for (let i = 0; i < statusOptionsCopy.value.length; i++) {
//     statusOptionsCopy.value[i].isCk = false;
//   }
//   //选中
//   if (statusOptionsCopy.value[idx].isCk) {
//     //选中取反
//     statusOptionsCopy.value[idx].isCk = false;
//   } else {
//     statusOptionsCopy.value[idx].isCk = true;
//   }
// };

//单独处理状态字段
const statusFieldSave = () => {
  //处理状态字段
  for (let i = 0; i < statusOptionsCopy.value.length; i++) {
    if (statusOptionsCopy.value[i].isCk) {
      if (statusOptionsCopy.value[i].name == "启用") {
        reqData.value.status = 1;
      } else if (statusOptionsCopy.value[i].name == "停用") {
        reqData.value.status = 0;
      }
    }
  }
};

//校验字段
const checkFields = () => {
  if (!reqData.value.testingBasis) {
    message.warn("请输入名称");
    return false;
  }
  if (!reqData.value.testingBasisCode) {
    message.warn("请输入编号");
    return false;
  }
  // if (!reqData.value.type) {
  //   message.warn("请选择单位类型");
  //   return false;
  // }
  // let tempInsertRecords = addTableRef.value.getInsertRecords();
  // if (tempInsertRecords.length > 0) {
  //   for (let i = 0; i < tempInsertRecords.length; i++) {
  //     if (!tempInsertRecords[i]["aggregateId"]) {
  //       message.warn("请选择供应集料");
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   }
  // }

  return true;
};
//保存操作
const handleSave = async () => {
  // 校验集料规格是否已经存在
  // const valid = await addUnitsRef.value.validate();
  // if (!valid) return;

  // 校验供应集料是否重复选择
  // let errMap = await addTableRef.value.validate(true);
  // if (errMap) return;

  //校验名称和电话是否为空
  if (!checkFields()) return;

  //保存状态字段
  statusFieldSave();
  // let tempInsertRecords=addTableRef.value.getInsertRecords();
  // let tempTableData = addTableRef.value.getTableData().tableData;
  // console.log(tempTableData);
  // console.log(addTableRef.value.getTableData());
  // reqData.value.manufactureAndAggregate = tempTableData;
  // console.log('=======reqData =========');
  // console.log(reqData.value);
  reqData.value.type = reqData.value.type ? reqData.value.type : "";
  reqData.value.legalPerson = reqData.value.legalPerson
    ? reqData.value.legalPerson
    : "";

  console.log(reqData.value);

  if (tempChildEditVars.value.currentId) {
    //编辑 调用编辑接口
    apiTopHeader.TestingBasisSave(reqData.value).then((res) => {
      if (res.data.code == 0) {
        message.success("修改成功", 1);

        //刷新表格
        setTimeout(() => {
          tempChildGridRef.value.commitProxy("query");
        }, 500);
        //关闭弹窗
        tempChildEditVars.value.modalShow = false;
      } else {
        message.error("保存失败");
      }
    });
  } else {
    // 新增 调用新增接口
    apiTopHeader
      .TestingBasisSave(reqData.value)
      .then((res) => {
        if (res.data.code == 0) {
          message.success("保存成功", 1);

          //刷新表格
          setTimeout(() => {
            tempChildGridRef.value.commitProxy("query");
          }, 500);
          //关闭弹窗
          tempChildEditVars.value.modalShow = false;
        } else {
          message.error("保存失败");
        }
      })
      .catch((error) => {
        message.error("保存失败");
        console.error("🚀 ~ apiTopHeader.addCompany ~ error:", error);
      });
  }
};

//TODO 5.10 过滤下拉项目
// const handleSelected = ({ value, $event }) => {
//   console.log($event)
//   console.log(value)
//   console.log(AggregatesGroupOptions.value)
//   let tempArr = AggregatesGroupOptions.value.filter((item) => {
//     console.log(item)
//     if (item['value'] !== value) {
//       return true
//     }
//   })
//   console.log(tempArr)
//   AggregatesGroupOptions.value = [...tempArr]
// }
</script>

<style lang="scss" scoped>
@use "../../../assets/style/vxe-coustom.scss";
.mod-main {
  width: 100%;
  height: 100%;
  padding: 0.08rem;
}
.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.06rem;
}

.save-btn {
  background-color: rgb(43, 154, 214);
}

.ant-select-selection-item {
  font-size: 14px !important;
}

.ant-select {
  font-size: 14px !important;
}

.btn {
  /*font-size: 0.06rem;*/
  color: #fff;

  padding: 0.02rem 0.08rem;
  display: inline-block;
  border-radius: 0.02rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.05rem;
}
:deep(.ant-btn-primary) {
  height: fit-content;
}

.btn-color1 {
  background: rgba(263, 39, 69, 0.8);
  border: 1px solid rgb(263, 39, 69, 0.8);
}

.btn-color2 {
  background: rgba(250, 180, 33, 0.8);
  border: 1px solid rgb(250, 180, 33, 0.8);
}
:deep(
    .ant-form-item-has-error
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input
  ) {
  background: #121b25 !important;
  border-color: #9ecdfc !important;
}
:deep(.ant-input) {
  background: #121b25 !important;
  border-color: #9ecdfc !important;
}
</style>
