const tool = {}



// localStorage
tool.data = {
  set(table, settings) {
    const _set = JSON.stringify(settings)
    return localStorage.setItem(table, _set)
  },
  get(table) {
    let data = localStorage.getItem(table)
    try {
      data = JSON.parse(data)
    } catch (err) {
      console.error(err)
      return null
    }
    return data
  },
  remove(table) {
    return localStorage.removeItem(table)
  },

  clear() {
    return localStorage.clear()
  }
}

tool.formatNumber = (num) => {
  if (num || num === 0) {
    // 将数字转换为字符串，并保留一位小数
    let formattedNumber =  num?.toFixed(1) ;
    
    // 如果结果中没有小数点，则在末尾添加一个小数点和0
    if (!formattedNumber.includes('.')) {
      formattedNumber += '.0';
    }
    return formattedNumber;
  }
}
tool.formatScreenSize = (screenSize) => {
  let formattedScreenSize = screenSize
  // 如果筛孔尺寸没有小数位数，保留一位小数
  if (!formattedScreenSize.includes('.')  &&
    !screenSize.includes("筛孔尺寸") &&
    screenSize != '筛后总质量' && 
    screenSize != '筛底') {
    formattedScreenSize += '.0';
  }
  return formattedScreenSize;
}
tool.getGaugeChartsOption = (value, max) => {
  return {
        backgroundColor: 'transparent',
        series: [
        {
            
            type: 'gauge',
            center: ['50%', `${80}%`],
            radius: 45 ,
            // radius: 60 * scale,
            startAngle: 180,
            endAngle: -0,
            min: 0,
            max: max,
            splitNumber: 4,
            itemStyle: {
              color: '#a5c8fd',
            },
            progress: {
              show: true,
              width: 5,
              // width: 5 * scale,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 5,
                // width: 5 * scale,
                color: [[1, '#E6EBF84e']],
              },
            },
            axisTick: {
              show: false,
              distance: 8,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: '#E5F1FF',
              },
            },
            splitLine: {
              distance: -10 ,
              // distance: -10 * scale,
              length: 8,
              // length: 12 * scale,
              lineStyle: {
                width: 1.3 ,
                // width: 1.5 * scale,
                color: '#E5F1FF',
              },
            },
            axisLabel: {
              distance: -18,
              padding: [0, -6, 0, -6],
              // distance: -22 * scale,
              color: '#fff',
              fontSize: 11 ,
              formatter: (param) => {
                return `${isNaN(param) ? '--' : tool.formatNumber(param)}`;
              },
            },
            anchor: {
              show: false,
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              width: '60%',
              lineHeight: 40,
              borderRadius: 8,
              offsetCenter: [0, '-15%'],
              fontSize: 17 ,
              // fontSize: 17 * scale,
              fontWeight: 'bolder',
              formatter: (param) => {
                return `${isNaN(param) ? '--' :  `${tool.formatNumber(param)}%`}`;
              },
              color: 'white',
            },
            data: [
              {
                value: value,
              },
            ],
          },
        ],
      };
  };

/**
 * @name 郭冲
 * @Date 2023-08-07 09:19:42
 * @introduction 将获取的检测记录表的数据转换成ecahrts使用的数据
 * @description 详细描述
 * @param { Array } newValue  newValue为获取的细度模数和颗粒级配的原始数据
 * @param { Array } totalData totalData为ecahrts图表的上限下限和实际值的总和
 * @param { Array } xAxisTickData xAxisTickData为细度模数和颗粒级配对应的自定义X轴刻度数据
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
tool.formatData = (newValue, totalData, xAxisTickData) => {
  if (Array.isArray(newValue)) {
    newValue?.forEach((item) => {
      let index = xAxisTickData.findIndex(
        (tick) => tool.formatScreenSize(item.aperture) === tick.name
      );

      if (index !== -1) {
        totalData.upperLimitData[index] = [
          xAxisTickData[index]['xAxis'],
          item.upperLimit,
        ];
        totalData.detectionResultsData[index] = [
          xAxisTickData[index]['xAxis'],
          item.detectionResults ?? item.passRate, //颗粒级配的实际值字段为passRate，细度模数的实际值字段为detectionResults
        ];
        totalData.lowerLimitData[index] = [
          xAxisTickData[index]['xAxis'],
          item.lowerLimit,
        ];
      }
    });
  }
};


tool.getOptions = (xAxisTickData, ecahrtsData,max,min,isTitle,isPrintOrDownloads) => {
  return {
    backgroundColor: 'rgba(128, 128, 128, 0)',
    animation:false,
    grid: {
      top: isTitle || isPrintOrDownloads ? '10%' : '5%',
      left: '4%',
      right: isTitle || isPrintOrDownloads ? '14%' : '4%',
      bottom: isTitle || isPrintOrDownloads ? '10%' : '18%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      padding: [8, 12.5, 8, 12.5],
      backgroundColor: 'rgb(7, 40, 85, 0.7);',
      borderColor: '#53B4FF',
      textStyle: {
        color: 'white', //设置文字颜色
      },
      axisPointer: {
        label: {
          show: true,
          backgroundColor: '#0b1f56',
          color: '#fff',
          fontSize: 12,
          formatter: (param) => {
            return `${xAxisTickData[param?.seriesData[0]?.dataIndex]?.name ?? '--'}`;
          },
        },
      },
      formatter: (param) => {
        // console.log(param);
        let content = `${xAxisTickData[param[0]?.dataIndex]?.name}</br>`;
        param.forEach((item) => {
          content += `${item.marker + item.seriesName} : 
          ${ item?.value[1] ?? '--' }%</br>`;
        });
        return content;
      },
    },
    xAxis: {
      max: max + 0.01,
      min: min - 0.01,
      type: 'value',
      name: isTitle || isPrintOrDownloads? '筛孔尺寸(mm)' : '',
      nameLocation: 'end',
      nameTextStyle: {
        color: isPrintOrDownloads ? 'black':'white'
      },
      rotate: 45, // 设置刻度标签旋转角度，以度为单位
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      name: isTitle || isPrintOrDownloads ? "通过百分率(%)" : '',
      nameLocation: 'end',
      nameTextStyle: {
        color: isPrintOrDownloads ? 'black' : 'white'
      },
      axisTick: {
        show: false, //是否显示示刻度
      },
      axisLine: {
        show: false, // 是否显示坐标轴线
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: isPrintOrDownloads ?'black':'rgba(237, 237, 237,0.4)',
          type: 'dashed', //虚线
        },
      },
      axisLabel: {
        textStyle: {
          color: isPrintOrDownloads ? 'black' : 'white' // 设置刻度值颜色为白色
        }
      },
    },
    series: [
      {
        name: '最大值',
        showSymbol: false,
        smooth: false,
        lineStyle: {
          type: 'dashed',
          width: isPrintOrDownloads ? 3 : 2,
          color: isPrintOrDownloads ? 'black' :'#cfb49a',
        },
        itemStyle: {
          color: '#cfb49a',
        },
        data: ecahrtsData.upperLimitData,
        connectNulls: true, // 这里设置 connectNulls 为 true，表示连接 null 值
        type: 'line',
        markLine: {
          symbol: ['none', 'none'],
          silent: true,
          label: {
            show: true,
            position: 'start',
            formatter: '{b}',
            color: isPrintOrDownloads ? 'black':'#fff',
            rotate: 30, // 设置刻度标签旋转角度，以度为单位
            offset: [-5, 2], // 第一个值为水平方向偏移量，第二个值为垂直方向偏移量
          },
          lineStyle: {
            type: 'dashed',
            color: isPrintOrDownloads ? 'black':'rgba(245, 245, 245,0.1)',
          },
          data: xAxisTickData,
        },
      },
      {
        name: '实际值',
        showSymbol: true,
        smooth: false,
        lineStyle: {
          width: isPrintOrDownloads ? 3 : 2,
          color: isPrintOrDownloads ? 'black':'#3de7c9',
        },
        itemStyle: {
          color: '#3de7c9',
        },
        data: ecahrtsData.detectionResultsData,
        connectNulls: true, // 这里设置 connectNulls 为 true，表示连接 null 值
        type: 'line',
        markLine: {
          symbol: ['none', 'none'],
          silent: true,
          label: {
            show: false,
            position: 'start',
            formatter: '{b}',
            // color: '#fff',
            // rotate: 30, // 设置刻度标签旋转角度，以度为单位
            // offset: [-5, 2], // 第一个值为水平方向偏移量，第二个值为垂直方向偏移量
          },
          lineStyle: {
            type: 'dashed',
            color: isPrintOrDownloads ? 'black':'rgba(245, 245, 245,0.1)',
          },
          data: xAxisTickData,
        },
      },
      {
        name: '最小值',
        showSymbol: false,
        smooth: false,
        lineStyle: {
          type: 'dashed',
          width: isPrintOrDownloads ? 3 : 2,
          color:isPrintOrDownloads ? 'black': '#cfb49a',
        },
        itemStyle: {
          color: '#cfb49a',
        },
        type: 'line',
        data: ecahrtsData.lowerLimitData,
        connectNulls: true, // 这里设置 connectNulls 为 true，表示连接 null 值
        markLine: {
          symbol: ['none', 'none'],
          silent: true,
          label: {
            show: false,
            position: 'start',
            formatter: '{b}',
            // color: '#fff',
            // rotate: 30, // 设置刻度标签旋转角度，以度为单位
            // offset: [-5, 2], // 第一个值为水平方向偏移量，第二个值为垂直方向偏移量
          },
          lineStyle: {
            type: 'dashed',
            color: isPrintOrDownloads ? 'black':'rgba(245, 245, 245,0.1)',
          },
          data: xAxisTickData,
        },
      },
    ],
  };
};



export default tool