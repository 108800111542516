<template>
  <div class="login-wrapper">
    <div
      :class="
        direction == 1
          ? 'logo-right'
          : direction == 2
          ? 'logo-left'
          : 'logo-center'
      "
    >
      <img src="/logo_anqi.svg" class="logo-white" v-if="isAnqi"/>
      <img src="/logo_dark_them.svg" class="logo-white" v-else/>
      <div class="system-name">集料智能检测平台</div>
    </div>
    <a-form
      ref="loginForm"
      :model="form"
      :rules="rules"
      layout="vertical"
      class="ele-bg-white"
      :class="
        direction == 1
          ? 'login-form-right'
          : direction == 2
          ? 'login-form-left'
          : 'login-form-center'
      "
    >
      <div class="title">用户登录</div>
      <!-- <a-form-item name="tenantId">
        <a-select
          v-model:value="form.tenantId"
          placeholder="请选择租户"
          :options="tenants"
          @select="getSelected"
        >
        </a-select>
      </a-form-item> -->
      <a-form-item name="username">
        <a-input
          placeholder="请输入登录账号"
          v-model:value="form.username"
          @keyup.enter="doSubmit()"
          allow-clear
          size="large"
        >
          <template #prefix>
            <user-outlined />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item name="password">
        <a-input-password
          placeholder="请输入登录密码"
          v-model:value="form.password"
          @keyup.enter="doSubmit()"
          size="large"
        >
          <template #prefix>
            <lock-outlined />
          </template>
        </a-input-password>
      </a-form-item>
      <a-form-item name="code" v-show="rules.code.required">
        <div class="login-input-group">
          <a-input
            placeholder="请输入验证码"
            v-model:value="form.code"
            @keyup.enter="doSubmit()"
            allow-clear
            size="large"
          >
            <template #prefix>
              <safety-certificate-outlined />
            </template>
          </a-input>
          <a-button class="login-captcha">
            <img v-if="captcha" :src="captcha" alt="" @click="changeCode" />
          </a-button>
        </div>
      </a-form-item>
      <a-form-item>
        <a-checkbox v-model:checked="form.remember">记住密码 </a-checkbox>
      </a-form-item>
      <a-form-item>
        <a-button
          block
          size="large"
          type="primary"
          :loading="loading"
          @keyup.enter="doSubmit()"
          @click="doSubmit"
        >
          {{ loading ? '登录中' : '登录' }}
        </a-button>
      </a-form-item>
    </a-form>
    <!-- <div class="login-copyright">
      copyright © 2022 重庆茂侨科技有限公司 all rights reserved.
    </div> -->
    <!-- 实际项目去掉这段 -->
    <!-- <div style="position: absolute; right: 30px; top: 20px">
      <a-radio-group v-model:value="direction" size="small">
        <a-radio-button :value="2">居左</a-radio-button>
        <a-radio-button :value="0">居中</a-radio-button>
        <a-radio-button :value="1">居右</a-radio-button>
      </a-radio-group>
    </div> -->
  </div>
</template>

<script>
import {
  UserOutlined,
  LockOutlined,
  SafetyCertificateOutlined,
  // QqOutlined,
  // WechatOutlined,
  // WeiboOutlined
} from '@ant-design/icons-vue';
import store from '@/store';
import {  message } from 'ant-design-vue'
// import User from '../sql/model/user'

export default {
  name: 'Login',
  components: {
    UserOutlined,
    LockOutlined,
    SafetyCertificateOutlined,
    // QqOutlined,
    // WechatOutlined,
    // WeiboOutlined
  },
  data() {
    return {
      // 系统图表是否为安琪
      isAnqi: process.env.VUE_APP_SYSTEM_ICON === 'anqi',
      calHeight: `${window.innerHeight}px`,
      // 登录框方向, 0居中, 1居右, 2居左
      direction: 0,
      // 加载状态
      loading: false,
      // 表单数据

      tenants: [
        {
          value: '1',
          label: '宁泰搅拌站',
        },
        {
          value: '2',
          label: '蕴丰搅拌站',
        },
      ],
      // 表单验证规则
      rules: {
        username: [
          {
            required: true,
            message: '请输入账号',
            type: 'string',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            type: 'string',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: false,
            message: '请输入验证码',
            type: 'string',
            trigger: 'blur',
          },
        ],
      },
      // 验证码base64数据
      captcha: '',
      // 验证码内容, 实际项目去掉
      text: '',

      form: {
        username: 'admin',
        password: 'admin',
        code: '',
        // tenantId: '1',
        remember: false,
      },
      // ,
      tenantName: '蕴丰搅拌站',
    };
  },
  created() {
    // console.log(typeof this.form.tenantId);
    // console.log(typeof this.tenants[0].value);
  },
  mounted() {
    // console.log(this.form.tenantId);
    document.documentElement.style.fontSize = '16px';
    if (this.$setting.takeToken()) {
      this.goHome();
    }
    this.changeCode();
  },
  methods: {
    // getSelected(val, opt) {
    //   console.log(val);
    //   console.log(opt);
    //   // this.form.tenantId=val;
    // },
    // getSelectedRadio(ev) {
    //   // console.log(ev.target.value);
    //   this.form.tenantId = ev.target.value;
    //   // this.$set(this.form,this.form.tenantId,val)
    // },
    /* 提交 */
    doSubmit() {
      this.$refs.loginForm
        .validate()
        .then(() => {
          this.loading = true;
          if (this.rules.code.required === true) {
            if (this.form.code.toLowerCase() !== this.text) {
              this.loading = false;
              message.error('验证码错误');
              this.changeCode();
              return;
            }
          }

          let formData = new FormData();
          for (let key in this.form) {
            formData.append(key, this.form[key]);
          }
          this.$http
            .post('/login', {
              username: this.form.username,
              password: this.form.password,
              // tenantId: this.form.tenantId,
              remember: this.form.remember,
            })
            .then((res) => {
              this.loading = false;
              if (res.data.code === 0) {
                message.success('登录成功');

                this.$store
                  .dispatch('user/setToken', {
                    token: res.data.data.access_token,
                    remember: this.form.remember,
                  })
                  .then(() => {
                    // this.goHome();
                    //TODO 获取用户信息
                    this.getUserInfo();
                    //TODO 初始化socket
                    // this.$store.state.user.socket.initWebSocket();
                  });
              } else {
                this.loading = false;
                if (res.data.captchaStatus) {
                  this.rules.code.required = true;
                }
                message.error(res.data.msg);
              }
            })
            .catch((error) => {
              // if (error.data.captchaStatus) {
              //   this.rules.code.required = true;
              //   this.changeCode();
              // }
              this.form.username = '';
              this.form.password = '';
              this.loading = false;
              message.error(error.message);
            });
        })
        .catch((error) => {
          this.loading = false;
          message.error(error.errorFields[0].errors);
        });
    },
    /* 获取当前用户信息 */
    getUserInfo() {
      store.dispatch('user/getUser').then(() => {
        this.goHome();

        this.showContent = true;
      });

      // this.$http.get(setting.userUrl).then((res) => {
      //   const result = setting.parseUser ? setting.parseUser(res.data) : res.data;
      //
      //   if (result.code === 0) {
      //     const user = result.data;
      //     setting.loginUser(user);
      //     this.goHome();
      //   } else if (result.msg) {
      //     message.error(result.msg);
      //   }
      //   // 在用户权限信息请求完成后再渲染主体部分, 以免权限控制指令不生效
      //   this.showContent = true;
      // }).catch((e) => {
      //   console.error(e);
      //   this.showContent = true;
      //   message.error(e.message);
      // });
    },
    /* 跳转到首页 */
    goHome() {
      const query = this.$route.query;
      const path = query && query.from ? query.from : '/';
      // console.log("🚀 ~ goHome ~ query:", query)
      if (path == '/404' || path == '/login' || path == '/index.html') {
        this.$router.push('/')
      } else {
        this.$router.push(path).catch((error) => {
          console.log("🚀 ~ goHome ~ error:", error)
        });
      }
    },
    /* 更换图形验证码 */
    changeCode() {
      this.form.code = '';
      // 这里演示的验证码是后端返回base64格式的形式, 如果后端地址直接是图片请参考忘记密码页面
      this.$http
        .get('/captcha')
        .then((res) => {
          if (res.data.code === 0) {
            this.captcha = res.data.data;
            // 实际项目后端一般会返回验证码的key而不是直接返回验证码的内容, 登录用key去验证, 你可以根据自己后端接口修改
            this.text = res.data.text;
            // 自动回填验证码, 实际项目去掉这个
            // this.form.code = this.text;
          } else {
            // message.error(res.data.msg);
          }
        })
        .catch(() => {
          // message.error(e.message);
          message.error('服务不可用，服务器暂时过载或未开启');
        });
    },
  },
};
</script>

<style scoped>
:deep(.ant-select-arrow) {
  color: rgba(0, 0, 0, 0.25);
}
:deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  background-color: #ffffff;
  border: 1px solid #9ecdfc;
}
:deep(.ant-input-affix-wrapper-lg) {
  padding: 4px 11px;
  font-size: 14px;
}
:deep(
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder
  ) {
  color: black;
}
:deep(.ant-input-affix-wrapper) {
  background-color: #ffffff;
  border: 1px solid #9ecdfc;
}
:deep(.ant-input-affix-wrapper > input.ant-input) {
  background-color: #ffffff;
  color: black;
}
:deep(.ant-input-affix-wrapper) {
  background-color: #ffffff;
  color: black;
}
:deep(.ant-btn-primary) {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff;
}
/* 背景 */
.login-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  /* background-image: url('~@/assets/img_bg.png'); */
  background-image: url('https://maoqiaooss.oss-cn-chengdu.aliyuncs.com/img_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.login-wrapper::before {
  content: '';
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  opacity: 0.05;
  background-image: url('~@/assets/rotate.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform-origin: 50% 50%;
  animation: rotate 40s linear 0s infinite;
  pointer-events: none;
}
@keyframes rotate {
  from {
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-left,
.logo-center,
.logo-right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 3rem;
  column-gap: 0.5%;
  top: calc(42% - 12rem) !important;
}
.logo-left {
  justify-content: flex-start;
  padding-left: 14%;
}
.logo-right {
  justify-content: flex-end;
  padding-right: 13%;
}
.logo-white {
  widows: 3rem;
  height: 3rem;
}
.system-name {
  color: white;
  font-size: 2.5rem;
  font-weight: 800;
}
/* .login-wrapper:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
} */

/* 卡片 */
.login-form-right,
.login-form-left,
.login-form-center {
  width: 21rem;
  margin: 0 auto;
  height: 22rem;
  padding: 0 1.75rem;
  box-sizing: border-box;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.15);
  border-radius: 0.125rem;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
}
.login-form-left {
  left: 15%;
}
.login-form-center {
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-form-right {
  right: 15%;
}

.title {
  font-size: 22px;
  color: white;
  text-align: center;
  padding: 1.5rem;
}

/* 验证码 */
.login-input-group {
  display: flex;
  align-items: center;
}

.login-input-group :deep(.ant-input-affix-wrapper) {
  flex: 1;
}

.login-input-group .login-captcha {
  width: 102px;
  height: 40px;
  margin-left: 10px;
  padding: 0;
}

.login-input-group .login-captcha > img {
  width: 100%;
  height: 100%;
}

/* 第三方登录图标 */
.login-oauth-icon {
  color: #fff;
  padding: 5px;
  margin: 0 12px;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}

/* 底部版权 */
.login-copyright {
  color: #eee;
  text-align: center;
  padding: 1%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.ele-bg-white {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 0.5rem 2rem 0 rgba(31, 38, 135, 0.2);
  backdrop-filter: blur(1.25rem);
  -webkit-backdrop-filter: blur(1.25rem);
  border-radius: 0.625rem;
}
:deep(.ant-checkbox + span) {
  color: white;
}
</style>
