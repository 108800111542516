<template>
  <a-modal
    :visible="props.visible"
    width="100%"
    class="detection-record"
    wrap-class-name="table-modal"
    centered
    :destroyOnClose="true"
    :closeIcon="true"
    @cancel="closeAggregateRecord"
  >
    <template #title>
      <div ref="modalTitleRef" class="modal-name">集料检测记录表</div>
      <div class="close-div">
        <a-button
          :type="primary"
          class="download-btn"
          :loading="isDowloading"
          @click="downloadAggregateRecord"
          >下载
        </a-button>
        <a-button
          :type="primary"
          class="download-btn"
          :loading="isPrinting"
          @click="printAggregateRecord"
          >打印
        </a-button>
        <a-button class="close-btn" @click="closeAggregateRecord"
          >关闭
        </a-button>
      </div>
    </template>
    <!-- TODO 集料检测记录表 -->
    <div class="record-main">
      <div class="table-loading" v-if="isLoading">
        <a-spin size="large" :tip="loadingTip" />
      </div>
      <table border="1" class="record-table" v-else>
        <tr>
          <td class="record-item10">检测单位名称</td>
          <td class="record-value90">{{ viewData?.testingUnit }}</td>
        </tr>
        <tr>
          <td class="record-item10">样品名称</td>
          <td class="record-value15">{{ viewData?.sampleName }}</td>
          <td class="record-item10">品种等级</td>
          <td class="record-item15">{{ viewData?.varietyLevel }}</td>
          <td class="record-item10">出厂日期</td>
          <td class="record-value15">{{ viewData?.productionDate }}</td>
          <td class="record-item10">试验编号</td>
          <td class="record-value15">{{ viewData?.testNumber }}</td>
        </tr>
        <tr>
          <td class="record-item10">样品状态</td>
          <td class="record-value15">
            {{ viewData?.sampleStatus }}
          </td>
          <td class="record-item10">试验湿度(%)</td>
          <td class="record-value15">{{ viewData?.humidity }}</td>
          <td class="record-item10">检测时间</td>
          <td class="record-value15">{{ viewData?.testDate }}</td>
          <td class="record-item10">样品编号</td>
          <td class="record-item15">{{ viewData?.sampleNumber }}</td>
        </tr>
        <tr>
          <td class="record-item10">检测依据</td>
          <td class="record-value15">{{ viewData?.testingBasis }}</td>
          <td class="record-item10">试验温度(℃)</td>
          <td class="record-value15">{{ viewData?.temperature }}</td>
          <td class="record-item10">取样时间</td>
          <td class="record-value15">{{ viewData?.samplingDate }}</td>
          <td class="record-item10">代表数量</td>
          <td class="record-item15">{{ viewData?.representativeQuantity }}</td>
        </tr>
        <tr>
          <td class="record-item10">取样地点</td>
          <td class="record-value40">{{ viewData?.samplingLocation }}</td>
          <td class="record-item10">生产厂家/产地</td>
          <td class="record-value40">{{ viewData?.manufacturer }}</td>
        </tr>
        <!-- 含水率、含泥量/石粉含量 -->
        <tr>
          <td class="record-title">
            <p
              v-if="
                viewData?.sampleName.includes('机制砂') ||
                viewData?.sampleName.includes('混合砂')
              "
            >
              含水率、石粉含量
            </p>
            <p v-if="viewData?.sampleName.includes('碎石')">
              含水率、 泥粉含量
            </p>
            <p v-else>含水率、含泥量</p>
            <!-- <p
            class="detection-name"
            v-if="viewData?.sampleName.split('-').includes('粗集料')"
          >
            含水率、含泥量/泥粉含量
          </p>
        
          <p
            class="detection-name"
            v-if="viewData?.sampleName.split('-').includes('细集料')"
          >
            含水率、含泥量/石粉含量
          </p> -->
          </td>
        </tr>
        <tr v-if="recordData.moisture.result !== null">
          <td class="record-item10 highlight">含水率</td>
          <!-- <td class="record-value10">
          {{ viewData?.containingWaterModulusData?.waterContent }}
        </td> -->
          <td class="record-item15">烘干前试样质量(g)</td>
          <td class="record-value15">
            {{
              Number(viewData?.containingWaterModulusData?.dryBefore)?.toFixed(
                1
              )
            }}
          </td>
          <td class="record-item15">烘干后试样质量(g)</td>
          <td class="record-value15">
            {{
              Number(viewData?.containingWaterModulusData?.dryAfter).toFixed(1)
            }}
          </td>
          <td class="record-item15">含水率(%)</td>
          <td class="record-value15">
            {{
              Number(
                viewData?.containingWaterModulusData?.moistureContent
              )?.toFixed(1)
            }}
          </td>
        </tr>
        <tr v-if="recordData.mudcontent.result !== null">
          <td
            class="record-item10 highlight"
            v-if="viewData?.sampleName.split('-').includes('细集料')"
          >
            含泥量/石粉含量
          </td>
          <td
            class="record-item10 highlight"
            v-if="viewData?.sampleName.split('-').includes('粗集料')"
          >
            含泥量/泥粉含量
          </td>
          <!-- <td class="record-value10">
          {{ viewData?.containingMudModulusData?.mudContent }}
        </td> -->
          <td class="record-item15">试验前烘干试样质量(g)</td>
          <td class="record-value15">
            {{
              Number(viewData?.containingMudModulusData?.mudBefore)?.toFixed(1)
            }}
          </td>
          <td class="record-item15">试验后烘干试样质量(g)</td>
          <td class="record-value15">
            {{
              Number(viewData?.containingMudModulusData?.mudAfter)?.toFixed(1)
            }}
          </td>
          <td class="record-item15">含泥量/石粉含量(%)</td>
          <td class="record-value15">
            {{
              Number(
                viewData?.containingMudModulusData?.mudContentPercentage
              )?.toFixed(1)
            }}
          </td>
        </tr>

        <!-- 细度模数 -->
        <template v-if="recordData.finenessModulus.result !== null">
          <tr>
            <td class="record-title">
              <p class="detection-name">颗粒级配</p>
            </td>
          </tr>
          <tr>
            <td class="record-item10">试样总质量(g)</td>
            <td class="record-value90">
              {{ Number(viewData?.finenessModulus?.totalSample)?.toFixed(1) }}
            </td>
          </tr>
          <tr>
            <td class="record-item10">筛孔尺寸(mm)</td>
            <td class="record-item10">分计筛余量(g)</td>
            <td class="record-item10">分计筛余(%)</td>
            <td class="record-item10">累计筛余(%)</td>
            <td class="record-item15">通过百分率(%)</td>
            <td class="record-value45 bold">级配曲线图</td>
          </tr>
          <tr v-for="(item, index) in finenessTotalData" :key="item.aperture">
            <td class="record-item10">{{ item.aperture }}</td>
            <td class="record-value10">{{ item.onSieveQuality }}</td>
            <td class="record-value10">{{ item.gradeScreening }}</td>
            <td class="record-value10">{{ item.accumulateScreening }}</td>
            <td class="record-value15">{{ item.detectionResults }}</td>
            <td
              v-if="index == 0"
              rowspan="9"
              class="record-value45 no-border record-value45-pr"
            >
              <v-chart
                id="fineChart"
                v-if="recordData.finenessModulus.result !== null"
                class="chart"
                ref="fineAggregateChart"
                style="width: 100%; height: 100%"
                autoresize
                :option="optionFineness"
              />
            </td>
          </tr>

          <tr>
            <td class="record-item10">筛后总量(g)</td>
            <td class="record-value90">
              {{
                Number(viewData?.finenessModulus?.finenesAfterSieving)?.toFixed(
                  1
                )
              }}
            </td>
          </tr>
          <tr>
            <td class="record-item10">损耗(g)</td>
            <td class="record-value90">
              {{ Number(viewData?.finenessModulus?.finenessLoss)?.toFixed(1) }}
            </td>
          </tr>
          <tr>
            <td class="record-item10">损耗率(%)</td>
            <td class="record-value90">
              {{
                Number(viewData?.finenessModulus?.finenessLossRate)?.toFixed(1)
              }}
            </td>
          </tr>
          <tr>
            <td class="record-item10 highlight">细度模数Mx</td>
            <td class="record-value10">
              {{
                Number(
                  viewData?.finenessModulus?.finenessModulusNumber
                )?.toFixed(2)
              }}
            </td>
            <td class="record-value80">
              {{ viewData?.finenessModulus?.finenessModulusType }}
            </td>
            <!-- <td class="record-value60">砂</td> -->
            <!-- <td class="record-value45 align-right">区</td> -->
          </tr>
        </template>

        <!-- 机制砂亚甲蓝值 -->
        <template v-if="recordData.methyleneBlue.result !== null">
          <tr>
            <td class="record-title">
              <p class="detection-name">机制砂亚甲蓝值</p>
            </td>
          </tr>
          <tr>
            <td rowspan="3" class="record-item10 record-item-pr no-border">
              <div class="rowspan-item">亚甲蓝吸附量</div>
            </td>
            <td class="record-item10">试样后质量</td>
            <td class="record-value35 bold">快速检测</td>
            <td class="record-value45 bold">标准检测</td>
          </tr>
          <tr class="display-end">
            <td rowspan="2" class="record-item10 record-item-pr no-border">
              <div class="rowspan-item2">M4</div>
            </td>
            <td class="record-value20 bold">是否出现色晕</td>
            <td class="record-value15 bold">评定</td>
            <td class="record-value20 bold">加入亚甲蓝总量(ML)</td>
            <td class="record-value25 bold highlight">MBV(g/kg)</td>
          </tr>
          <tr class="display-end">
            <td class="record-value20">是</td>
            <td class="record-value15">M4</td>
            <td class="record-value20">500</td>
            <td class="record-value25">M6</td>
          </tr>
          <tr class="tr-img">
            <td class="record-item10">识别图像</td>
            <td class="record-value30">照片(最后识别)</td>
            <td class="record-value60">照片(最后识别)</td>
          </tr>
        </template>

        <!-- 颗粒级配 -->
        <template v-if="recordData.needleParticle.result !== null">
          <tr>
            <td class="record-title">
              <p class="detection-name">颗粒级配</p>
            </td>
          </tr>
          <tr>
            <td class="record-item10">试样总质量(g)</td>
            <td class="record-value90">
              {{
                Number(
                  viewData?.particleGradingModulus?.sampleTotalQuality
                )?.toFixed(1)
              }}
            </td>
          </tr>
          <tr class="display-end">
            <td rowspan="2" class="record-item10 record-item-pr no-border">
              <div class="rowspan-item2">针、片状颗粒含量</div>
            </td>
            <td class="record-value10 bold">针、片颗粒总质量(g)</td>
            <td class="record-value20 bold highlight">针、片状颗粒含量(%)</td>
            <td class="record-value15 bold">不规则颗粒总质量(g)</td>
            <td class="record-value45 bold highlight">不规则颗粒含量(%)</td>
          </tr>
          <tr class="display-end">
            <td class="record-value10">
              {{
                Number(viewData?.particleGradingModulus?.totalMass)?.toFixed(1)
              }}
            </td>
            <td class="record-value20">
              {{
                Number(
                  viewData?.particleGradingModulus?.contentNeedle
                )?.toFixed(1)
              }}
            </td>
            <td class="record-value15">
              {{
                Number(viewData?.particleGradingModulus?.irregular)?.toFixed(1)
              }}
            </td>
            <td class="record-value45">
              {{
                Number(
                  viewData?.particleGradingModulus?.irregularContent
                )?.toFixed(1)
              }}
            </td>
          </tr>
          <tr>
            <td class="record-item10">筛孔尺寸(mm)</td>
            <td class="record-item10">分计筛余量(g)</td>
            <td class="record-item10">分计筛余(%)</td>
            <td class="record-item10">累计筛余(%)</td>
            <td class="record-item15">通过百分率(%)</td>
            <td class="record-value45 bold">级配曲线图</td>
          </tr>
          <tr
            v-for="(item, index) in particaleGradingTotalData"
            :key="item.aperture"
          >
            <td class="record-item10">{{ item.aperture }}</td>
            <td class="record-value10">{{ item.sieveWeight }}</td>
            <td class="record-value10">{{ item.sieveResidue }}</td>
            <td class="record-value10">{{ item.accumulateResidue }}</td>
            <td class="record-value15">{{ item.passRate }}</td>
            <td
              v-if="index == 0"
              rowspan="9"
              class="record-value45 no-border record-value45-pr"
            >
              <v-chart
                v-if="recordData.needleParticle.result !== null"
                class="chart"
                ref="coarseAggregateChart"
                style="width: 100%; height: 100%"
                autoresize
                :option="optionParticle"
              />
            </td>
          </tr>

          <tr>
            <td class="record-item10">筛后总量(g)</td>
            <td class="record-value90">
              {{
                Number(
                  viewData?.particleGradingModulus?.particleAfterSieving
                )?.toFixed(1)
              }}
            </td>
          </tr>
          <tr>
            <td class="record-item10">损耗(g)</td>
            <td class="record-value90">
              {{
                Number(viewData?.particleGradingModulus?.particleLoss)?.toFixed(
                  1
                )
              }}
            </td>
          </tr>
          <tr>
            <td class="record-item10">损耗率(%)</td>
            <td class="record-value90">
              {{
                Number(
                  viewData?.particleGradingModulus?.particleLossRate
                )?.toFixed(1)
              }}
            </td>
          </tr>
        </template>

        <tr>
          <td class="record-item10">设备记录</td>
          <td class="record-value90">{{ viewData?.deviceNumber }}</td>
        </tr>
        <tr>
          <td class="record-item10">结论</td>
          <td class="record-value90"></td>
        </tr>
        <tr>
          <td class="record-item10">备注</td>
          <td class="record-value90"></td>
        </tr>
        <tr>
          <td class="record-value15 no-border">检测：</td>
          <td class="record-value15 no-border">记录：</td>
          <td class="record-value15 no-border">复核：</td>
          <td class="record-value55 no-border align-right">
            {{
              `日期：${viewData?.year ?? '-'}年${viewData?.mouth ?? '-'}月${
                viewData?.day ?? '-'
              }日`
            }}
          </td>
        </tr>
      </table>
    </div>
    <template #footer> </template>
  </a-modal>
</template>

<script setup>
import {
  ref,
  reactive,
  defineProps,
  toRefs,
  onMounted,
  nextTick,
  defineEmits,
  toRaw,
} from 'vue';
import VChart from 'vue-echarts';
import { message } from 'ant-design-vue';
import { apiMainfoots } from '@/api/api-main-foots/api-main-foots.js';
import tool from '../../js/tool.js';
const props = defineProps({
  recordData: {
    type: Object,
    required: true,
  },
  visible: {
    type: Boolean,
    required: true,
    default() {
      return false;
    },
  },
});
let { recordData } = toRefs(props);

const emits = defineEmits(['update:visible']);
/**
 * @name 郭冲
 * @Date 2023-09-06 17:14:12
 * @introduction 关闭记录表弹窗
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const closeAggregateRecord = () => {
  emits('update:visible', false);
};
/**
 * @name 郭冲
 * @Date 2023-10-11 09:20:29
 * @introduction 获取echarts渲染的图片base64数据
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const getEcahrtsBase = () => {
  let resultBase64 = null;
  if (
    (recordData.value.aggregatesSpecs.split('-')[0] == '粗集料' ||
      recordData.value.aggregatesSpecs.split('-')[0] == '粗骨料') &&
    recordData.value.needleParticle.result != null
  ) {
    // 打印时的option配置,以保证导出的报告图中的线条和文字是黑色的
    coarseAggregateChart.value[0].setOption(optionParticleDownloadPrint.value);
    resultBase64 = coarseAggregateChart.value[0].getConnectedDataURL({
      type: 'png',
      pixelRatio: 2,
      backgroundColor: '#fff',
    });
    // 还原初始option配置
    coarseAggregateChart.value[0].setOption(optionParticle.value);
  }
  if (
    (recordData.value.aggregatesSpecs.split('-')[0] == '细集料' ||
      recordData.value.aggregatesSpecs.split('-')[0] == '细骨料') &&
    recordData.value.finenessModulus.result != null
  ) {
    // 打印时的option配置，以保证导出的报告图中的线条和文字是黑色的
    fineAggregateChart.value[0].setOption(optionFinenessDownloadPrint.value);
    resultBase64 = fineAggregateChart.value[0].getConnectedDataURL({
      type: 'png',
      pixelRatio: 2,
      backgroundColor: '#fff',
    });
    // 还原option配置
    fineAggregateChart.value[0].setOption(optionFineness.value);
  }
  // console.log('base64图片数据', resultBase64);
  return resultBase64;
};
// 是否在下载中
let isDowloading = ref(false);
/**
 * @name 郭冲
 * @Date 2023-07-18 16:52:47
 * @introduction 下载检测记录表
 * @description 详细描述
 * @param {Number} id 需要下载的记录表id
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const downloadAggregateRecord = () => {
  isDowloading.value = true;
  isLoading.value = true;
  loadingTip.value = '下载中......';
  // 获取ecahrts的base64数据
  let resultBase64 = getEcahrtsBase();
  1; // TODO 下载检测记录表
  let data = {
    id: recordData.value.testNumber,
    type: 'download',
    picByBase64: resultBase64,
  };
  apiMainfoots
    .downloadRecordTable(data)
    .then((response) => {
      if (response.data) {
        isDowloading.value = false;
        isLoading.value = false;
      }
      // 处理响应数据
      const blob = new Blob([response.data]);
      const filename = `${recordData.value.testNumber}-检测记录表.xlsx`;
      // console.log('下载文件的类型：', blob.type);
      // 创建一个下载链接
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // 模拟点击下载链接
      link.click();
      URL.revokeObjectURL(link.href);
      message.success('下载检测记录成功', 2);
    })
    .catch((error) => {
      // 处理请求错误
      console.error(error);
      message.error('下载检测记录失败', 2);
    });
};
// 是否在打印中
let isPrinting = ref(false);
/**
 * @name 郭冲
 * @Date 2023-10-11 14:52:23
 * @introduction 下载检测记录表
 * @description 详细描述
 * @param {Number} id 需要下载的记录表id
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const printAggregateRecord = () => {
  isLoading.value = true;
  isPrinting.value = true;
  loadingTip.value = '打印中.......';
  // 获取ecahrts的base64数据
  let resultBase64 = getEcahrtsBase();
  // TODO 下载检测记录表
  let data = {
    id: recordData.value.testNumber,
    type: 'download',
    picByBase64: resultBase64,
  };
  apiMainfoots
    .printRecordTable(data)
    .then((response) => {
      if (response.data.code == 0) {
        isLoading.value = false;
        isPrinting.value = false;
        message.success('打印成功', 2);
      }
    })
    .catch((error) => {
      // 处理请求错误
      console.error(error);
      message.error('打印失败', 2);
    });
};

// 细集料图表实例
const fineAggregateChart = ref(null);
// 粗集料图表实例
const coarseAggregateChart = ref(null);
// 细度模数--自定义markLine的数据
const xAxisTickFinenessData = [
  {
    name: '筛底', // 在x轴显示的数值
    xAxis: 0, // 与series每一项的data二维数组的第一个元素对应的值
  },
  {
    name: '0.075',
    xAxis: 0.312,
  },
  {
    name: '0.15',
    xAxis: 0.426,
  },
  {
    name: '0.3',
    xAxis: 0.582,
  },
  {
    name: '0.6',
    xAxis: 0.795,
  },
  {
    name: '1.18',
    xAxis: 1.077,
  },
  {
    name: '2.36',
    xAxis: 1.472,
  },
  {
    name: '4.75',
    xAxis: 2.016,
  },
  {
    name: '9.5',
    xAxis: 2.754,
  },
];
// 细度模数ecahrts默认数据
let finenessTotalData = ref([
  {
    aperture: '筛底',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '0.075',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '0.15',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '0.3',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '0.6',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '1.18',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '2.36',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '4.75',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '9.5',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
]);
// 细度模数ecahrts数据
let ecahrtsFinenessData = reactive({
  upperLimitData: [],
  detectionResultsData: [],
  lowerLimitData: [],
});
// 细度模数--ecahrts配置项数据
let optionFineness = ref(
  tool.getOptions(
    xAxisTickFinenessData,
    ecahrtsFinenessData,
    2.754,
    0,
    true,
    false
  )
);
// 细度模数--打印/下载时ecahrts配置项数据
let optionFinenessDownloadPrint = ref(
  tool.getOptions(
    xAxisTickFinenessData,
    ecahrtsFinenessData,
    2.754,
    0,
    false,
    true
  )
);
// // 用坐标轴最大刻度的值加0.01，为了显示图表筛底和最右侧的竖轴虚线
// optionFineness.xAxis.max = 2.016 + 0.01;
// // 用坐标轴最大刻度的值加0.01，为了显示图表筛底的竖轴虚线
// optionFineness.xAxis.min = 0 - 0.01;
// 颗粒级配--自定义markLine的数据
const xAxisTickParticleData = [
  {
    name: '筛底', // 在x轴显示的数值
    xAxis: 1.077, // 与series每一项的data二维数组的第一个元素对应的值
  },
  {
    name: '2.36',
    xAxis: 1.472,
  },
  {
    name: '4.75',
    xAxis: 2.016,
  },
  {
    name: '9.5',
    xAxis: 2.754,
  },
  {
    name: '16.0',
    xAxis: 3.482,
  },
  {
    name: '19.0',
    xAxis: 3.762,
  },
  {
    name: '26.5',
    xAxis: 4.37,
  },
  {
    name: '31.5',
    xAxis: 4.723,
  },
  {
    name: '37.5',
    xAxis: 5.109,
  },
];
// 颗粒级配ecahrts默认数据
let particaleGradingTotalData = ref([
  {
    aperture: '筛底',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '2.36',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '4.75',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '9.5',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '16.0',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '19.0',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '26.5',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '31.5',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
  {
    aperture: '37.5',
    upperLimit: 0, //最大值
    lowerLimit: 0, //最小值
    detectionResults: null, //实际值（时间通过百分率%）
    onSieveQuality: null, //分计筛余量
    gradeScreening: null, //分计筛余
    accumulateScreening: null, //累计筛余
  },
]);
// 颗粒级配ecahrts数据
let ecahrtsParticleData = reactive({
  upperLimitData: [],
  detectionResultsData: [],
  lowerLimitData: [],
});
// 颗粒级配--ecahrts配置项数据
let optionParticle = ref(
  tool.getOptions(
    xAxisTickParticleData,
    ecahrtsParticleData,
    5.109,
    1.077,
    true,
    false
  )
);
// 颗粒级配--打印/下载时ecahrts配置项数据
let optionParticleDownloadPrint = ref(
  tool.getOptions(
    xAxisTickParticleData,
    ecahrtsParticleData,
    5.109,
    1.077,
    false,
    true
  )
);
// // 用坐标轴最大刻度的值加0.01，为了显示图表最右侧的竖轴虚线
// optionParticle.xAxis.max = 5.109 + 0.01;
// // 用坐标轴最大刻度的值加0.01，为了显示图表筛底的竖轴虚线
// optionParticle.xAxis.min = 1.077 - 0.01;
// 请求得到的检测记录表数据总和
let viewData = ref(null);
// 是否在加载或打印中
let isLoading = ref(true);
// 加载或打印中文字提示
let loadingTip = ref('');
/**
 * @name 郭冲
 * @Date 2023-08-05 09:27:05
 * @introduction 查看检测记录表信息
 * @description 详细描述
 * @param {参数类型} 参数 参数说明
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
const viewRecordData = () => {
  loadingTip.value = '加载中......';
  isLoading.value = true;
  apiMainfoots
    .viewRecordTable({
      id: recordData.value.testNumber, //父组件传的值
      type: 'view',
    })
    .then((response) => {
      if (response.data.code == 0) {
        isLoading.value = false;
        viewData.value = response.data.data.data;
        // 细度模数数据
        let xAxisTickDataTemp = [];
        let finenessTotalDataTemp = [];
        if (Array.isArray(viewData.value.finenessModulusCharts)) {
          ecahrtsFinenessData.upperLimitData = [];
          ecahrtsFinenessData.detectionResultsData = [];
          ecahrtsFinenessData.lowerLimitData = [];

          // 先把筛孔尺寸排序
          let numberArray = viewData.value.finenessModulusCharts
            .filter((item) => item.aperture != '筛底')
            .sort((a, b) => b.aperture - a.aperture);
          // 筛底的数据
          let stringArray = viewData.value.finenessModulusCharts.filter(
            (item) => item.aperture == '筛底'
          );
          // finenessTotalData.value = [...numberArray, ...stringArray];
          // 分计筛余量(g)、分计筛余(%)、累计筛余(%)、通过百分率(%)结果保留一位，并且按照筛孔尺寸倒序，筛底排最后
          finenessTotalData.value = [...numberArray, ...stringArray].map(
            (item) => {
              return {
                accumulateScreening: item.accumulateScreening.toFixed(1),
                detectionResults: item.detectionResults.toFixed(1),
                gradeScreening: item.gradeScreening.toFixed(1),
                onSieveQuality: item.onSieveQuality.toFixed(1),
                aggregates: item.aggregates,
                aperture: item.aperture,
                upperLimit: item.upperLimit,
                lowerLimit: item.lowerLimit,
              };
            }
          );

          // 如果上限和下限没有设置时该筛孔尺寸不要
          xAxisTickDataTemp = [...xAxisTickFinenessData];
          finenessTotalDataTemp = [...toRaw(finenessTotalData.value)];
          finenessTotalData.value.forEach((item) => {
            if (
              (typeof item.upperLimit == 'undefined' &&
                typeof item.lowerLimit == 'undefined') ||
              (item.upperLimit == null && item.lowerLimit == null)
            ) {
              finenessTotalDataTemp.forEach((element, finenessIndex) => {
                if (element.aperture == item.aperture) {
                  finenessTotalDataTemp.splice(finenessIndex, 1);
                }
              });
              xAxisTickDataTemp.forEach((element, index) => {
                if (element.name == item.aperture) {
                  // console.log(item.aperture, '上下限都为空');
                  xAxisTickDataTemp.splice(index, 1);
                }
              });
            }
          });
          // console.log('删除后的xAxisTickDataTemp', xAxisTickDataTemp);
          // console.log(
          //   '删除后的finenessTotalDataTemp',
          //   finenessTotalDataTemp
          // );

          // 找到去除上下限为undefined的轴之后，筛选出最大的x轴刻度值
          let initialMax = Math.max(
            ...xAxisTickDataTemp.map((item) => item.xAxis)
          );
          // console.log('最初MAX', initialMax);
          nextTick(() => {
            // console.log('细度模数图表实例', fineAggregateChart.value);
            optionFineness.value = tool.getOptions(
              xAxisTickDataTemp,
              ecahrtsFinenessData,
              initialMax,
              0,
              true,
              false
            );
            optionFinenessDownloadPrint.value = tool.getOptions(
              xAxisTickDataTemp,
              ecahrtsFinenessData,
              initialMax,
              0,
              false,
              true
            );
            fineAggregateChart.value[0]?.setOption(optionFineness.value);
          });

          // 通过百分率值为100,并且上下限达到100，不显示后面的数据
          let firstFullFineness = finenessTotalDataTemp
            .filter((item) => item.aperture != '筛底')
            .sort((a, b) => a.aperture - b.aperture)
            .find(
              (item) =>
                item.detectionResults == 100 &&
                item.upperLimit == 100 &&
                item.lowerLimit == 100
            );
          xAxisTickFinenessData.forEach((tickitem, tickindex) => {
            if (tickitem.name == firstFullFineness?.aperture) {
              nextTick(() => {
                optionFineness.value.xAxis.max =
                  Number(xAxisTickFinenessData[tickindex]?.xAxis) + 0.01;
                optionFinenessDownloadPrint.value.xAxis.max =
                  Number(xAxisTickFinenessData[tickindex]?.xAxis) + 0.01;
                fineAggregateChart.value[0]?.setOption(optionFineness.value);
              });
              // optionFineness.value.xAxis.max =
              //   Number(xAxisTickFinenessData[tickindex].xAxis) + 0.01;
            }
          });
        }
        // 转换数据格式，图表展示用
        tool.formatData(
          finenessTotalDataTemp,
          ecahrtsFinenessData,
          xAxisTickDataTemp
        );

        // 颗粒级配数据
        let xAxisTickData1Temp = [];
        let particaleGradingTotalDataTemp = [];
        if (Array.isArray(viewData.value.particleGradingCharts)) {
          ecahrtsParticleData.upperLimitData = [];
          ecahrtsParticleData.detectionResultsData = [];
          ecahrtsParticleData.lowerLimitData = [];
          // 先把筛孔尺寸排序
          let numberArray = viewData.value.particleGradingCharts
            .filter((item) => item.aperture != '筛底')
            .sort((a, b) => b.aperture - a.aperture);
          // 筛底的数据
          let stringArray = viewData.value.particleGradingCharts.filter(
            (item) => item.aperture == '筛底'
          );
          // 分计筛余量(g)、分计筛余(%)、累计筛余(%)、通过百分率(%)结果保留一位，并且按照筛孔尺寸倒序，筛底排最后
          particaleGradingTotalData.value = [
            ...numberArray,
            ...stringArray,
          ].map((item) => {
            return {
              accumulateResidue: item.accumulateResidue.toFixed(1),
              passRate: item.passRate.toFixed(1),
              sieveResidue: item.sieveResidue.toFixed(1),
              sieveWeight: item.sieveWeight.toFixed(1),
              aggregates: item.aggregates,
              aperture: item.aperture,
              upperLimit: item.upperLimit,
              lowerLimit: item.lowerLimit,
            };
          });

          // 如果上限和下限没有设置时该筛孔尺寸不要
          xAxisTickData1Temp = [...xAxisTickParticleData];
          particaleGradingTotalDataTemp = [
            ...toRaw(particaleGradingTotalData.value),
          ];
          particaleGradingTotalData.value.forEach((item) => {
            if (
              (typeof item.upperLimit == 'undefined' &&
                typeof item.lowerLimit == 'undefined') ||
              (item.upperLimit == null && item.lowerLimit == null)
            ) {
              particaleGradingTotalDataTemp.forEach(
                (element, particaleIndex) => {
                  if (element.aperture == item.aperture) {
                    particaleGradingTotalDataTemp.splice(particaleIndex, 1);
                  }
                }
              );
              xAxisTickData1Temp.forEach((element, index) => {
                if (element.name == item.aperture) {
                  // console.log(item.aperture, '上下限都为空');
                  xAxisTickData1Temp.splice(index, 1);
                }
              });
            }
          });
          // console.log('删除后的xAxiadata1Temp', xAxisTickData1Temp);
          // console.log(
          //   '删除后的particaleGradingTotalDataTemp',
          //   particaleGradingTotalDataTemp
          // );

          // 找到去除上下限为undefined的轴之后，筛选出最大的x轴刻度值
          let initialMax = Math.max(
            ...xAxisTickData1Temp.map((item) => item.xAxis)
          );
          // console.log('最初MAX', initialMax);
          nextTick(() => {
            // console.log('颗粒级配图表实例', coarseAggregateChart.value);
            optionParticle.value = tool.getOptions(
              xAxisTickData1Temp,
              ecahrtsParticleData,
              initialMax,
              1.077,
              true,
              false
            );
            optionParticleDownloadPrint.value = tool.getOptions(
              xAxisTickData1Temp,
              ecahrtsParticleData,
              initialMax,
              1.077,
              false,
              true
            );
            coarseAggregateChart.value[0]?.setOption(optionParticle.value);
          });

          // 通过百分率值为100,并且上下限达到100，不显示后面的数据
          let firstFullParticaleGrading = particaleGradingTotalDataTemp
            .filter((item) => item.aperture != '筛底')
            .sort((a, b) => a.aperture - b.aperture)
            .find(
              (item) =>
                item.passRate == 100 &&
                item.upperLimit == 100 &&
                item.lowerLimit == 100
            );
          xAxisTickParticleData.forEach((tickitem, tickindex) => {
            if (tickitem.name == firstFullParticaleGrading?.aperture) {
              nextTick(() => {
                optionParticle.value.xAxis.max =
                  Number(xAxisTickParticleData[tickindex]?.xAxis) + 0.01;
                optionParticleDownloadPrint.value.xAxis.max =
                  Number(xAxisTickParticleData[tickindex]?.xAxis) + 0.01;
                coarseAggregateChart.value[0]?.setOption(optionParticle.value);
              });
            }
          });
        }
        // 转换数据格式，图表展示用
        tool.formatData(
          particaleGradingTotalDataTemp,
          ecahrtsParticleData,
          xAxisTickData1Temp
        );
      }
    })
    .catch((error) => {
      // 处理请求错误
      console.error(error);
      message.error('获取数据失败', 2);
    });
};

onMounted(() => {
  if (props.visible) {
    viewRecordData();
  }
});
</script>

<style lang="scss" scoped>
@use '../../../assets/style/vxe-coustom.scss';

.close-div {
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2%;
  float: right;
  z-index: 9999;
  background: none;
}
// .close-btn {
//   margin-left: auto;
//   z-index: 9999;
//   background: none;
//   border: none;
//   font-size: 0.08rem;
//   color: white;
// }
.download-btn {
  background: none;
  color: white;
}
.record-main {
  width: 100%;
  height: 100%;
  padding: 0.08rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .table-loading {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.record-table {
  width: 100%;
  border: 1px solid #9ecdfc;
  border-collapse: collapse;
  margin-bottom: 0.1rem;
  margin: 0 auto;
}
.record-table tr {
  width: 100% !important;
  display: flex;
}
.record-table tr td {
  display: flex;
  padding: 0.05rem 0.08rem;
  border: 1px solid #9ecdfc;
  font-size: 0.07rem;
  color: white;
}
.record-item10 {
  font-weight: 800;
  width: 10% !important;
}
.record-item15 {
  font-weight: 800;
  width: 15% !important;
}
.record-item20 {
  font-weight: 800;
  width: 20% !important;
}
.record-value10 {
  width: 10% !important;
}
.record-item-pr {
  position: relative;
}
.record-value15 {
  width: 15% !important;
}
.record-value20 {
  width: 20% !important;
}
.record-value25 {
  width: 25% !important;
}
.record-value30 {
  width: 30% !important;
}
.record-value35 {
  width: 35% !important;
}
.record-value40 {
  width: 40% !important;
}
.record-value45 {
  width: 45% !important;
}
.record-value45-pr {
  position: relative;
}
.record-value55 {
  width: 55% !important;
}
.record-value60 {
  width: 60% !important;
}
.record-value70 {
  width: 70% !important;
}
.record-value80 {
  width: 80% !important;
}
.record-value90 {
  width: 90% !important;
}
.record-title {
  background-color: #0a243d70;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}
.detection-name {
  text-align: center;
  width: 100%;
  font-size: 0.08rem;
  font-weight: 800;
  color: white;
  margin: 0.05rem 0rem;
}
.highlight {
  color: #f3c576 !important;
}
.align-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.bold {
  font-weight: 800;
}
.chart {
  position: absolute;
  top: 0.15rem;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1.7rem !important;
}
.no-border {
  border: none !important;
}
.rowspan-item {
  padding: 0.05rem 0.08rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0.2rem;
  line-height: 0.56rem;
  height: 0.67rem;
  // border: 1px solid white;
}
.rowspan-item2 {
  padding: 0.05rem 0.08rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0.2rem;
  line-height: 0.32rem;
  height: 0.446rem;
  border-left: 1px solid #9ecdfc;
}
.display-end {
  justify-content: flex-end;
}
.tr-img {
  height: 0.7rem;
  line-height: 0.55rem;
}
</style>
