<template>
  <div>
    <a-spin :spinning="spinning">
      <div class="mod-main jcss" style="padding: 0">
        <!-- <a-row class="standard">
          <a-col :span="2" class="standard-name">标准名称（当前）</a-col>
          <a-col :span="12">
            <a-select
              class="standard-select"
              v-model:value="standard"
              :options="standardOptions"
              placeholder="请选择标准"
            >
            </a-select>
          </a-col>
        </a-row> -->
        <a-row class="standard">
          <a-col :span="2" class="standard-name">集料选择</a-col>
          <a-col :span="12">
            <a-cascader
              class="standard-select"
              v-model:value="aggregates"
              :options="aggregatesOptions"
              :defaultValue="aggregatesDefault"
              placeholder="请选择标准"
              :allowClear="false"
              @change="onAggchange"
            >
            </a-cascader>
          </a-col>
        </a-row>
        <!-- <table border="1" style="width: 85%; margin: 0 auto" class="my-table-1">
          <tr>
            <th style="width: 1rem">检测项目</th>
            <th>指标</th>
            <th style="width: 1rem">状态</th>
          </tr>
          <tr>
            <td class="mdtitle">含水率（%）</td>
            <td>
              <div class="mdlin flex-r-sb">
                <div class="mdlin-item">
                  <span>细集料 ≤ </span>
                  <a-input
                    v-model:value="moistureContent.fineAggregate"
                    placeholder="细集料"
                    :max-length="25"
                    style="width: 0.5rem"
                    size="small"
                    :disabled="editAvailable"
                  />
                </div>
                <div class="mdlin-item">
                  <span class="coarse-aggregate">粗集料</span>
                  <span>≤</span>
                  <a-input
                    v-model:value="moistureContent.coarseAggregate"
                    placeholder="粗集料"
                    :max-length="25"
                    style="width: 0.5rem"
                    size="small"
                    :disabled="editAvailable"
                  />
                </div>
              </div>
            </td>
            <td>
              <div class="mswitch">
                <a-switch
                  checked-children="启用"
                  un-checked-children="停用"
                  v-model:checked="moistureContent.waterContentSwitch"
                  :disabled="editAvailable"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td class="mdtitle">含泥量（%）</td>
            <td>
              <div class="mdlin">
                <table border="1" style="width: 100%; margin: 0.02rem auto">
                  <tr>
                    <th>天然砂</th>
                    <th>人工砂</th>
                    <th>混合砂</th>
                    <th class="coarse-aggregate">粗集料</th>
                  </tr>
                  <tr>
                    <td
                      class="mdtitle"
                      v-for="item in mudContent.valueArray"
                      :key="item.key"
                    >
                      ≤
                      <a-input
                        v-model:value="item.value"
                        :max-length="25"
                        style="width: 0.5rem"
                        size="small"
                        :disabled="editAvailable"
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </td>
            <td>
              <div class="mswitch">
                <a-switch
                  checked-children="启用"
                  un-checked-children="停用"
                  v-model:checked="mudContent.containsMudSwitch"
                  :disabled="editAvailable"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td class="mdtitle">细度模数<br />（累计筛余%）</td>
            <td>
              <div class="mdlin">
                <div class="mdlin-item fineness-modulus">
                  <span>细度模数 </span>
                  <div class="fineness-modulus-value">
                    <a-input
                      v-model:value="finenessModulu.start"
                      size="small"
                      :max-length="25"
                      style="width: 0.5rem"
                      :disabled="editAvailable"
                    />
                    -
                    <a-input
                      v-model:value="finenessModulu.end"
                      size="small"
                      :max-length="25"
                      style="width: 0.5rem"
                      :disabled="editAvailable"
                    />
                  </div>
                </div>
              </div>
              <table border="1" style="width: 100%; margin: 0.02rem auto">
                <tr>
                  <th>筛孔尺寸（mm）</th>
                  <th>9.5</th>
                  <th>4.75</th>
                  <th>2.36</th>
                  <th>1.18</th>
                  <th>0.6</th>
                  <th>0.3</th>
                  <th>0.15</th>
                  <th>0.075</th>
                  <th>筛底</th>
                </tr>
                <tr>
                  <td class="mdtitle">上限</td>
                  <td
                    class="mdtitle"
                    v-for="(item, index) in fsievePoreup"
                    :key="index"
                  >
                    <a-input
                      v-model:value="item.value"
                      :max-length="25"
                      style="width: 0.4rem"
                      size="small"
                      :disabled="editAvailable"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="mdtitle">下限</td>
                  <td
                    class="mdtitle"
                    v-for="(item, index) in fsievePoredown"
                    :key="index"
                  >
                    <a-input
                      v-model:value="item.value"
                      :max-length="25"
                      style="width: 0.4rem"
                      size="small"
                      :disabled="editAvailable"
                    />
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <div class="mswitch">
                <a-switch
                  checked-children="启用"
                  un-checked-children="停用"
                  v-model:checked="finenessModulu.finenessModulusSwitch"
                  :disabled="editAvailable"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td class="mdtitle">MB值</td>
            <td>
              <div class="mdlin flex-r-sb">
                <div class="mdlin-item">
                  <span>MB值 ≤ </span>
                  <a-input
                    v-model:value="mbValueData.value"
                    placeholder="MB值"
                    :max-length="25"
                    style="width: 0.5rem"
                    size="small"
                    :disabled="editAvailable"
                  />
                </div>
              </div>
            </td>
            <td>
              <div class="mswitch">
                <a-switch
                  checked-children="启用"
                  un-checked-children="停用"
                  v-model:checked="mbValueData.mbValueSwitch"
                  :disabled="editAvailable"
                />
              </div>
            </td>
          </tr>
        </table> -->

        <div v-for="(item, index) in initData" :key="index">
          <div v-for="(itemi, indexi) in item.itemList" :key="indexi">
            <table
              border="1"
              style="width: 85%; margin: 0 auto"
              class="my-table-1"
              v-if="itemi.ishow"
            >
              <tr>
                <th style="width: 0.7rem">检测项目</th>
                <th>指标</th>
                <th style="width: 0.6rem">状态</th>
              </tr>
              <tr>
                <td class="mdtitle">含水率（%）</td>
                <td>
                  <div class="mdlin flex-r-sb">
                    <div class="mdlin-item">
                      <span> ≤ </span>
                      <a-input
                        v-model:value="
                          itemi.controlStandardsTypeResult.controlStandardsKdj
                            .waterContent
                        "
                        placeholder="含水率"
                        :max-length="25"
                        style="width: 0.5rem"
                        type="number"
                        size="small"
                        :disabled="editAvailable"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div class="mswitch">
                    <a-switch
                      checked-children="启用"
                      un-checked-children="停用"
                      v-model:checked="
                        itemi.controlStandardsTypeResult.controlStandardsKdj
                          .waterContentSwitch
                      "
                      :disabled="editAvailable"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td class="mdtitle">含泥量（%）</td>
                <td>
                  <div class="mdlin">
                    <div class="mdlin flex-r-sb">
                      <div class="mdlin-item">
                        <span> ≤ </span>
                        <a-input
                          v-model:value="
                            itemi.controlStandardsTypeResult.controlStandardsKdj
                              .containsMud
                          "
                          placeholder="含泥量"
                          :max-length="25"
                          style="width: 0.5rem"
                          type="number"
                          size="small"
                          :disabled="editAvailable"
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="mswitch">
                    <a-switch
                      checked-children="启用"
                      un-checked-children="停用"
                      v-model:checked="
                        itemi.controlStandardsTypeResult.controlStandardsKdj
                          .containsMudSwitch
                      "
                      :disabled="editAvailable"
                    />
                  </div>
                </td>
              </tr>
              <tr
                v-for="(xitem, xindex) in itemi.controlStandardsTypeResult
                  .gradeList"
                :key="xindex"
              >
                <!-- 细集料 start -->
                <td
                  class="mdtitle"
                  rowspan="1000"
                  v-if="item.atParentType == '细集料' && xindex == 0"
                >
                  级配(累计筛余)
                </td>
                <td
                  class="mdtitle"
                  v-if="item.atParentType == '细集料'"
                  style="padding: 0; margin: 0"
                >
                  <table
                    border="1"
                    class="my-table-1"
                    style="width: 100%; margin: 0 auto"
                  >
                    <tr>
                      <td style="width: 0.6rem">{{ xitem.aggregatesSpecs }}</td>
                      <td style="padding: 0">
                        <table
                          border="1"
                          class="my-table-1"
                          style="width: 100%; margin: 0 auto"
                        >
                          <tr>
                            <td>
                              <div class="mdlin">
                                <div
                                  class="mdlin-item fineness-modulus"
                                  style="align-items: center"
                                >
                                  <span>细度模数： </span>
                                  <div class="fineness-modulus-value">
                                    <a-input
                                      v-model:value="xitem.finedsModulus.start"
                                      size="small"
                                      :max-length="25"
                                      style="width: 0.5rem"
                                      type="number"
                                      :disabled="editAvailable"
                                    />
                                    -
                                    <a-input
                                      v-model:value="xitem.finedsModulus.end"
                                      size="small"
                                      :max-length="25"
                                      type="number"
                                      style="width: 0.5rem"
                                      :disabled="editAvailable"
                                    />
                                  </div>
                                </div>
                              </div>
                              <table
                                border="1"
                                class="my-table-1"
                                style="width: 100%; margin: 0.02rem auto"
                              >
                                <tr>
                                  <th>筛孔尺寸（mm）</th>
                                  <th
                                    v-for="(
                                      ccitem, ccindex
                                    ) in xitem.sievePoreList"
                                    :key="ccindex"
                                  >
                                    {{ ccitem.key }}
                                  </th>
                                </tr>
                                <tr>
                                  <td class="mdtitle">上限</td>
                                  <td
                                    class="mdtitle"
                                    v-for="(
                                      sxitem, sxindex
                                    ) in xitem.sievePoresx"
                                    :key="sxindex"
                                  >
                                    <a-input
                                      v-model:value="sxitem.value"
                                      :max-length="25"
                                      type="number"
                                      style="width: 0.4rem"
                                      size="small"
                                      :disabled="editAvailable"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td class="mdtitle">下限</td>
                                  <td
                                    class="mdtitle"
                                    v-for="(
                                      xxitem, xxindex
                                    ) in xitem.sievePorexx"
                                    :key="xxindex"
                                  >
                                    <a-input
                                      v-model:value="xxitem.value"
                                      :max-length="25"
                                      style="width: 0.4rem"
                                      type="number"
                                      size="small"
                                      :disabled="editAvailable"
                                    />
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
                <!-- 细集料 end -->

                <!-- 粗集料 start -->
                <td
                  class="mdtitle"
                  rowspan="100"
                  v-if="item.atParentType == '粗集料' && xindex == 0"
                >
                  级配(累计筛余)
                </td>
                <td
                  class="mdtitle"
                  v-if="item.atParentType == '粗集料'"
                  style="padding: 0; margin: 0"
                >
                  <table
                    border="1"
                    class="my-table-1"
                    style="width: 100%; margin: 0 auto"
                  >
                    <tr>
                      <td style="width: 0.6rem">{{ xitem.aggregatesSpecs }}</td>
                      <td style="padding: 0">
                        <table
                          border="1"
                          class="my-table-1"
                          style="width: 100%; margin: 0 auto"
                        >
                          <tr>
                            <td>
                              <div
                                class="mdlin"
                                style="display: flex; align-items: center"
                              >
                                <div
                                  class="mdlin-item fineness-modulus"
                                  style="align-items: center; flex: 1"
                                >
                                  <span>针片状： </span>
                                  <div class="fineness-modulus-value">
                                    <a-input
                                      v-model:value="xitem.needleParticle"
                                      size="small"
                                      :max-length="25"
                                      style="width: 0.5rem"
                                      type="number"
                                      :disabled="editAvailable"
                                    />
                                  </div>
                                </div>
                                <div
                                  class="mdlin-item fineness-modulus"
                                  style="align-items: center; flex: 1"
                                >
                                  <span>不规则颗粒： </span>
                                  <div class="fineness-modulus-value">
                                    <a-input
                                      v-model:value="xitem.irregularParticle"
                                      size="small"
                                      :max-length="25"
                                      type="number"
                                      style="width: 0.5rem"
                                      :disabled="editAvailable"
                                    />
                                  </div>
                                </div>
                              </div>
                              <table
                                border="1"
                                class="my-table-1"
                                style="width: 100%; margin: 0.02rem auto"
                              >
                                <tr>
                                  <th>筛孔尺寸（mm）</th>
                                  <th
                                    v-for="(
                                      ccitem, ccindex
                                    ) in xitem.sievePoreList"
                                    :key="ccindex"
                                  >
                                    {{ ccitem.key }}
                                  </th>
                                </tr>
                                <tr>
                                  <td class="mdtitle">上限</td>
                                  <td
                                    class="mdtitle"
                                    v-for="(
                                      sxitem, sxindex
                                    ) in xitem.sievePoresx"
                                    :key="sxindex"
                                  >
                                    <a-input
                                      v-model:value="sxitem.value"
                                      :max-length="25"
                                      style="width: 0.4rem"
                                      type="number"
                                      size="small"
                                      :disabled="editAvailable"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td class="mdtitle">下限</td>
                                  <td
                                    class="mdtitle"
                                    v-for="(
                                      xxitem, xxindex
                                    ) in xitem.sievePorexx"
                                    :key="xxindex"
                                  >
                                    <a-input
                                      v-model:value="xxitem.value"
                                      :max-length="25"
                                      style="width: 0.4rem"
                                      type="number"
                                      size="small"
                                      :disabled="editAvailable"
                                    />
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
                <!-- 粗集料 end -->

                <td>
                  <div class="mswitch">
                    <a-switch
                      checked-children="启用"
                      un-checked-children="停用"
                      v-model:checked="xitem.switchEnable"
                      :disabled="editAvailable"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <teleport to="#materialDosageEditModalFooter">
        <div class="mod-footer">
          <div class="btn-wrapper">
            <div class="mbtns stars save-btn" @click="handleSave">
              <span v-if="!editAvailable">保存</span>
              <span v-else>更新当前标准</span>
            </div>
          </div>
        </div>
      </teleport>
    </a-spin>
  </div>
</template>

<script setup>
import {
  ref,
  // reactive,
  onMounted,
  defineEmits,
  watch,
  defineExpose,
} from "vue";
import { apiTopHeader } from "@/api/api-topHeader.js";
import { message } from "ant-design-vue";
// import { useStore } from 'vuex';
// const store = useStore();
const spinning = ref(true);
// 用户角色
let userRole = ref(null);
// 国标是否可编辑
let editAvailable = ref(false);
// 所有内控标准信息
// let controlSatndardsData = reactive({
//   waterContent: {
//     粗集料: '0',
//     细集料: '0',
//   },
//   waterContentSwitch: null,
//   containsMud: {
//     天然砂: 0,
//     人工砂: 0,
//     混合砂: 0,
//     粗集料: 0,
//   },
//   containsMudSwitch: null,
//   finenessModulus: {
//     筛孔尺寸: {
//       9.5: { 上限: 0, 下限: 0 },
//       4.75: { 上限: 0, 下限: 0 },
//       2.365: { 上限: 0, 下限: 0 },
//       1.18: { 上限: 0, 下限: 0 },
//       0.6: { 上限: 0, 下限: 0 },
//       0.3: { 上限: 0, 下限: 0 },
//       0.15: { 上限: 0, 下限: 0 },
//       0.075: { 上限: 0, 下限: 0 },
//       筛底: { 上限: 0, 下限: 0 },
//     },
//     细度模数: {
//       细度模数1: 0,
//       细度模数2: 0,
//     },
//   },
//   finenessModulusSwitch: null,
//   mbValue: {
//     MB值: 0,
//   },
//   mbValueSwitch: null,
//   updateDatetime: null,
//   configType: null,
// });
//含水率
// const moistureContent = reactive({
//   fineAggregate: '',
//   coarseAggregate: '',
//   waterContentSwitch: null,
// });
//含泥率
// const mudContent = reactive({
//   valueArray: [
//     { key: '天然砂', value: '' },
//     { key: '人工砂', value: '' },
//     { key: '混合砂', value: '' },
//     { key: '粗集料', value: '' },
//   ],
//   containsMudSwitch: true,
// });

//细度模数
// const finenessModulu = reactive({
//   start: '',
//   end: '',
//   finenessModulusSwitch: null,
// });
//MB值
// const mbValueData = reactive({
//   value: '',
//   mbValueSwitch: true,
// });

//细度模数上限
// const fsievePoreup = reactive([
//   { key: '9.5', value: '' },
//   { key: '4.75', value: '' },
//   { key: '2.36', value: '' },
//   { key: '1.18', value: '' },
//   { key: '0.6', value: '' },
//   { key: '0.3', value: '' },
//   { key: '0.15', value: '' },
//   { key: '0.075', value: '' },
//   { key: '筛底', value: '' },
// ]);

//细度模数下线
// const fsievePoredown = reactive([
//   { key: '9.5', value: '' },
//   { key: '4.75', value: '' },
//   { key: '2.36', value: '' },
//   { key: '1.18', value: '' },
//   { key: '0.6', value: '' },
//   { key: '0.3', value: '' },
//   { key: '0.15', value: '' },
//   { key: '0.075', value: '' },
//   { key: '筛底', value: '' },
// ]);
//标准名称字典
// const standardOptions = reactive([
//   {
//     value: 1,
//     label: '国标（GB∕T 14685-2022）',
//   },
//   {
//     value: 2,
//     label: '内控标准',
//   },
//   {
//     value: 3,
//     label: '行业标准',
//   },
// ]);
// // 修改之前检测标准
// let standardCurrent = ref(undefined);
// // 当前检测标准
// let standard = ref(undefined);
// 集料数据
const aggregatesOptions = ref([]);
//集料选择结果
let aggregates = ref("");
let aggregatesDefault = ref([]);
let initData = ref([]);

/**
 * @name 郭冲
 * @Date 2023-05-24 17:05:29
 * @introduction 监听器-监听当前标准变化
 * @description 详细描述
 * @param {number} newValue 变化后的当前标准
 * @param {number} oldValue 变化前的当前标准
 * @return 无返回类型
 * @exception [违例类型] [违例类型说明]
 */
watch(
  ()=>aggregates,
  (newValue) => {
    // console.log(userRole.value);
    // console.log(newValue);
    // 当前标准为国标且用户为管理员角色时才可以更改国标
    console.log(newValue);
    // userRole.value != "admin"
    //   ? (editAvailable.value = true)
    //   : (editAvailable.value = false);
    // console.log('editAvailable.value', editAvailable.value);

    // console.log("🚀 ~ file: detection-manage.vue:338 ~ newValue:", newValue);
    // apiTopHeader
    //   .queryControlStandard(newValue)
    //   .then((res) => {
    //     if (res.data.code === 0) {
    //       formatterData(res);
    //     }
    //   })
    //   .catch(() => {
    //     message.error('获取当前检测标准失败', 2);
    //   });
  },
  { immediate: false }
);

onMounted(() => {
  userRole.value = JSON.parse(localStorage.getItem("userRole"));
  /**
   * @Date 2023-05-13 17:44:28
   * @introduction 获取当前内控标准信息
   * @description 详细描述
   */
  // apiTopHeader
  //   .getCurrentStandards()
  //   .then((res) => {
  //     if (res.data.code === 0) {
  //       spinning.value = false;
  //       //修改之前的标准
  //       standardCurrent.value = res.data.data.configType;
  //       // 保存检测标准到vuex
  //       store.dispatch('device/CHANGE_SATNDARD', standardCurrent.value);
  //       //转化数据格式
  //       formatterData(res);
  //     }
  //   })
  //   .catch(() => {
  //     message.error('获取当前检测标准失败', 2);
  //   });

  apiTopHeader.getControlStandards().then((res) => {
    if (res.data.code === 0) {
      console.log(res);
      spinning.value = false;

      //设置集料选择参数
      setAggregatesOptions(res.data.data);  
      formatterData(res.data.data);
    }
  });
  // .catch(() => {
  //   message.error("获取失败", 2);
  // });
});

const setAggregatesOptions = (mdata) => {
  let arrs = [];
  let noms = [];
  for (let i = 0; i < mdata.length; i++) {
    let mi = JSON.stringify(i);
    let mobj = {
      value: mi,
      label: mdata[i].atParentType,
      children: [],
    };
    if (i == 0) {
      noms.push(mi);
    }
    let mitemList = mdata[i].itemList;
    if (mitemList != null && mitemList != undefined) {
      for (let j = 0; j < mitemList.length; j++) {
        let childobj = {
          value: i + "-" + j,
          label: mitemList[j].atType,
        };
        if (i == 0 && j == 0) {
          noms.push(i + "-" + j);
        }
        mobj.children.push(childobj);
      }
    }
    arrs.push(mobj);
  }
  console.log(arrs);
  aggregatesOptions.value = arrs;
  aggregates.value = noms;
};

const formatterData = (mdata) => {
  console.log(mdata, "123123");

  for (let i = 0; i < mdata.length; i++) {
    for (let j = 0; j < mdata[i].itemList.length; j++) {
      mdata[i].itemList[j].ishow = false;
      if (i == 0 && j == 0) {
        mdata[i].itemList[j].ishow = true;
      }

      mdata[i].itemList[
        j
      ].controlStandardsTypeResult.controlStandardsKdj.waterContent =
        parseFloat(
          mdata[i].itemList[j].controlStandardsTypeResult.controlStandardsKdj
            .waterContent
        );
      let mwaterContentSwitch =
        mdata[i].itemList[j].controlStandardsTypeResult.controlStandardsKdj
          .waterContentSwitch;
      mdata[i].itemList[
        j
      ].controlStandardsTypeResult.controlStandardsKdj.waterContentSwitch =
        mwaterContentSwitch == 1;
      mdata[i].itemList[
        j
      ].controlStandardsTypeResult.controlStandardsKdj.containsMud = parseFloat(
        mdata[i].itemList[j].controlStandardsTypeResult.controlStandardsKdj
          .containsMud
      );
      let mcontainsMudSwitch =
        mdata[i].itemList[j].controlStandardsTypeResult.controlStandardsKdj
          .containsMudSwitch;
      mdata[i].itemList[
        j
      ].controlStandardsTypeResult.controlStandardsKdj.containsMudSwitch =
        mcontainsMudSwitch == 1;

      for (
        let t = 0;
        t < mdata[i].itemList[j].controlStandardsTypeResult.gradeList.length;
        t++
      ) {
        let mfmd = JSON.parse(
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[t]
            .finenessModulus
        );

        //细集料判断
        if (
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[t]
            .finenessModulus
        ) {
          mfmd = JSON.parse(
            mdata[i].itemList[j].controlStandardsTypeResult.gradeList[t]
              .finenessModulus
          );
          let sievePore = mfmd["筛孔尺寸"];
          let fineness = mfmd["细度模数"];
          let msieve = [];
          let msievesx = [];
          let msievexx = [];

          for (let k in sievePore) {
            let msx =
              sievePore[k]["上限"] !== ""
                ? parseFloat(sievePore[k]["上限"])
                : "";
            let mxx =
              sievePore[k]["下限"] !== ""
                ? parseFloat(sievePore[k]["下限"])
                : "";

            let mobj = {
              key: k,
              value: sievePore[k],
            };
            let mobjsx = {
              key: k,
              value: msx,
            };
            let mobjxx = {
              key: k,
              value: mxx,
            };
            msieve.push(mobj);
            msievesx.push(mobjsx);
            msievexx.push(mobjxx);
          }

          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[
            t
          ].sievePoreList = msieve; //塞孔尺寸
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[
            t
          ].sievePoresx = msievesx; //塞孔尺寸上限
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[
            t
          ].sievePorexx = msievexx; //塞孔尺寸下限
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[
            t
          ].switchEnable =
            mdata[i].itemList[j].controlStandardsTypeResult.gradeList[t]
              .switchEnable == 1
              ? true
              : false; //开关

          let mfls = {
            start: fineness["细度模数上限"],
            end: fineness["细度模数下限"],
          };
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[
            t
          ].finedsModulus = mfls; //细度模数上下限
        }
        //粗集料判断
        if (
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[t]
            .particleMatching
        ) {
          mfmd = JSON.parse(
            mdata[i].itemList[j].controlStandardsTypeResult.gradeList[t]
              .particleMatching
          );
          let meshSize = mfmd["筛孔尺寸"];
          let msieve = [];
          let msievesx = [];
          let msievexx = [];
          for (let k in meshSize) {
            let mobj = {
              key: k,
              value: meshSize[k],
            };
            let mobjsx = {
              key: k,
              value: meshSize[k]["上限"],
            };
            let mobjxx = {
              key: k,
              value: meshSize[k]["下限"],
            };
            msieve.push(mobj);
            msievesx.push(mobjsx);
            msievexx.push(mobjxx);
          }
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[
            t
          ].sievePoreList = msieve; //筛孔尺寸
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[
            t
          ].sievePoresx = msievesx; //筛孔尺寸上限
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[
            t
          ].sievePorexx = msievexx; //筛孔尺寸下限
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[
            t
          ].switchEnable =
            mdata[i].itemList[j].controlStandardsTypeResult.gradeList[t]
              .switchEnable == 1
              ? true
              : false; //开关
        }
      }
    }
  }
  // console.log(mdata.length, "asdasdasd");
  initData.value = mdata;
};

const onAggchange = (e) => {
  console.log(e[1]);
  let mnums = e[1].split("-");
  let mdata = initData.value;
  let mi = mnums[0];
  let mj = mnums[1];

  for (let i = 0; i < mdata.length; i++) {
    for (let j = 0; j < mdata[i].itemList.length; j++) {
      mdata[i].itemList[j].ishow = false;
    }
  }
  initData.value[mi].itemList[mj].ishow = true;
};

//定义子组件传回值给父组件事件
const emit = defineEmits(["closeDetectionModal"]);
const handleSave = () => {
  let mdata = initData.value;
  let mqires = {
    controlStandardsKdjList: [],
    gradeList: [],
  };
  console.log("123123");
  for (let i = 0; i < mdata.length; i++) {
    for (let j = 0; j < mdata[i].itemList.length; j++) {
      mdata[i].itemList[
        j
      ].controlStandardsTypeResult.controlStandardsKdj.waterContentSwitch =
        mdata[i].itemList[j].controlStandardsTypeResult.controlStandardsKdj
          .waterContentSwitch
          ? 1
          : 0;
      mdata[i].itemList[
        j
      ].controlStandardsTypeResult.controlStandardsKdj.containsMudSwitch =
        mdata[i].itemList[j].controlStandardsTypeResult.controlStandardsKdj
          .containsMudSwitch
          ? 1
          : 0;

      for (
        let f = 0;
        f < mdata[i].itemList[j].controlStandardsTypeResult.gradeList.length;
        f++
      ) {
        if (
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[f]
            .aggregatesTypeParent == "细集料"
        ) {
          let sx =
            mdata[i].itemList[j].controlStandardsTypeResult.gradeList[f]
              .sievePoresx;
          let xx =
            mdata[i].itemList[j].controlStandardsTypeResult.gradeList[f]
              .sievePorexx;
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[
            f
          ].switchEnable = mdata[i].itemList[j].controlStandardsTypeResult
            .gradeList[f].switchEnable
            ? 1
            : 0;

          let apertureSizeParams = {}; //含筛孔尺寸临时变量

          for (let k = 0; k < sx.length; k++) {
            let msx = sx[k].value !== "" ? sx[k].value : "";
            let mxx = xx[k].value !== "" ? xx[k].value : "";

            apertureSizeParams[`${sx[k].key}`] = { 上限: msx, 下限: mxx };
          }

          let xdsxnum =
            mdata[i].itemList[j].controlStandardsTypeResult.gradeList[f]
              .finedsModulus.start;
          let xdxxnum =
            mdata[i].itemList[j].controlStandardsTypeResult.gradeList[f]
              .finedsModulus.end;
          let xdsx = xdsxnum !== '' ? xdsxnum : "";
          let xdxx = xdxxnum !== '' ? xdxxnum : "";
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[
            f
          ].finenessModulus = JSON.stringify({
            细度模数: {
              细度模数上限: xdsx.trim(),
              细度模数下限: xdxx.trim(),
            },
            筛孔尺寸: apertureSizeParams,
          });
        }
        if (
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[f]
            .aggregatesTypeParent == "粗集料"
        ) {
          let sx =
            mdata[i].itemList[j].controlStandardsTypeResult.gradeList[f]
              .sievePoresx;
          let xx =
            mdata[i].itemList[j].controlStandardsTypeResult.gradeList[f]
              .sievePorexx;
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[
            f
          ].switchEnable = mdata[i].itemList[j].controlStandardsTypeResult
            .gradeList[f].switchEnable
            ? 1
            : 0;

          let apertureSizeParams = {}; //含筛孔尺寸临时变量

          for (let k = 0; k < sx.length; k++) {
            let msx = sx[k].value !== "" ? sx[k].value : "";
            let mxx = xx[k].value !== "" ? xx[k].value : "";

            apertureSizeParams[`${sx[k].key}`] = { 上限: msx, 下限: mxx };
          }
          mdata[i].itemList[j].controlStandardsTypeResult.gradeList[
            f
          ].particleMatching = JSON.stringify({
            筛孔尺寸: apertureSizeParams,
          });
        }
      }

      mqires.controlStandardsKdjList.push(
        mdata[i].itemList[j].controlStandardsTypeResult.controlStandardsKdj
      );
      mqires.gradeList = [
        ...mqires.gradeList,
        ...mdata[i].itemList[j].controlStandardsTypeResult.gradeList,
      ];
    }
  }

  console.log(mqires);

  apiTopHeader
    .saveAlldata(mqires)
    .then((res) => {
      if (res.data.code === 0) {
        emit("closeDetectionModal", false);
        message.success("保存检测标准成功", 2);
        // store.dispatch(
        //   'device/CHANGE_ISEDITSTANDARD',
        //   !store.state.device.isEditStandard
        // );
      }
    })
    .catch(() => {
      message.error("保存检测标准失败", 2);
    });
};
/**
 * @name 郭冲
 * @Date 2023-05-24 16:41:33
 * @introduction 转换请求的当前检测标准数据
 * @description 详细描述
 * @param {Object} res 请求检测标准得到的返回结果
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
// const formatterData = (res) => {
//   let {
//     waterContent,
//     waterContentSwitch,
//     containsMud,
//     containsMudSwitch,
//     finenessModulus,
//     finenessModulusSwitch,
//     mbValue,
//     mbValueSwitch,
//     // id,
//     // configType,
//   } = res.data.data;
//   //标准名称
//   // standard.value = configType;
//   //含水率
//   Object.assign(moistureContent, {
//     fineAggregate: JSON.parse(waterContent)['细集料'],
//     coarseAggregate: JSON.parse(waterContent)['粗集料'],
//     waterContentSwitch: waterContentSwitch === 1, //含水率是否启用
//   });

//   //含泥率
//   let containsMudObj = JSON.parse(containsMud);
//   Object.keys(containsMudObj).forEach((key) => {
//     Object.assign(
//       mudContent.valueArray[
//         mudContent.valueArray.findIndex((item) => item.key === key)
//       ],
//       { value: containsMudObj[key] }
//     );
//   });
//   mudContent.containsMudSwitch = containsMudSwitch === 1; //含泥率是否启用

//   //细度模数
//   Object.assign(finenessModulu, {
//     start: JSON.parse(finenessModulus)['细度模数']['细度模数1'], //细度模数第一个值
//     end: JSON.parse(finenessModulus)['细度模数']['细度模数2'], //细度模数第二个值
//     finenessModulusSwitch: finenessModulusSwitch === 1, //细度模数是否启用
//   });
//   //细度模数--筛孔尺寸--上限
//   let fs_finenessModulus = JSON.parse(finenessModulus)['筛孔尺寸'];

//   Object.keys(fs_finenessModulus).forEach((key) => {
//     if (fsievePoreup.findIndex((item) => item.key == key) != -1) {
//       Object.assign(
//         fsievePoreup[fsievePoreup.findIndex((item) => item.key == key)],
//         { value: fs_finenessModulus[key]['上限'] }
//       );
//     }
//   });
//   //细度模数--筛孔尺寸--下限
//   Object.keys(fs_finenessModulus).forEach((key) => {
//     if (fsievePoredown.findIndex((item) => item.key == key) != -1) {
//       Object.assign(
//         fsievePoredown[fsievePoredown.findIndex((item) => item.key == key)],
//         { value: fs_finenessModulus[key]['下限'] }
//       );
//     }
//   });

//   //MB值
//   Object.assign(mbValueData, {
//     value: JSON.parse(mbValue)['MB值'],
//     mbValueSwitch: mbValueSwitch === 1, //MB值是否启用
//   });
// };

/**
 * @name 郭冲
 * @Date 2023-05-13 17:48:16
 * @introduction 保存修改的内控标准信息
 * @description 详细描述
 * @param {Object} data 修改后的内控标准信息
 * @return {返回类型说明}
 * @exception [违例类型] [违例类型说明]
 */
// const handleSave = () => {
//   let containsMudParams = {}; //含泥量临时变量
//   mudContent.valueArray.forEach(
//     (item) => (containsMudParams[`${item.key}`] = item.value)
//   );
//   let apertureSizeParams = {}; //含筛孔尺寸临时变量
//   fsievePoreup.forEach((item) => {
//     apertureSizeParams[`${item.key}`] = { 上限: item.value };
//   });
//   fsievePoredown.forEach((item) => {
//     Object.assign(apertureSizeParams[`${item.key}`], { 下限: item.value });
//   });
//   // 修改后的内控标准信息
//   Object.assign(controlSatndardsData, {
//     waterContent: JSON.stringify({
//       粗集料: moistureContent.coarseAggregate,
//       细集料: moistureContent.fineAggregate,
//     }),
//     waterContentSwitch: moistureContent.waterContentSwitch === true ? 1 : 0,
//     containsMud: JSON.stringify(containsMudParams),
//     containsMudSwitch: mudContent.containsMudSwitch === true ? 1 : 0,
//     finenessModulus: JSON.stringify({
//       细度模数: {
//         细度模数1: finenessModulu.start,
//         细度模数2: finenessModulu.end,
//       },
//       筛孔尺寸: apertureSizeParams,
//     }),
//     finenessModulusSwitch:
//       finenessModulu.finenessModulusSwitch === true ? 1 : 0,
//     mbValue: JSON.stringify({
//       MB值: mbValueData.value,
//     }),
//     mbValueSwitch: mbValueData.mbValueSwitch === true ? 1 : 0,
//     id: standard.value, //1--国标，2--内控
//     configType: standard.value, //1--国标，2--内控
//   });
//   // console.log('修改后的内控标准信息', controlSatndardsData);
//   // console.log('修改后的内控标准信息', JSON.parse(controlSatndardsData.waterContent));
//   // console.log('修改后的内控标准信息', JSON.parse(controlSatndardsData.containsMud));
//   // console.log('修改后的内控标准信息', JSON.parse(controlSatndardsData.finenessModulus));
//   // console.log('修改后的内控标准信息', JSON.parse(controlSatndardsData.mbValue));

//   apiTopHeader
//     .saveAllControlStandards(controlSatndardsData)
//     .then((res) => {
//       if (res.data.code === 0) {
//         emit('closeDetectionModal', false);
//         // 保存检测标准到vuex
//         store.dispatch('device/CHANGE_SATNDARD', standard.value);
//         store.dispatch('device/CHANGE_ISEDITSTANDARD', !store.state.device.isEditStandard);
//         message.success('保存检测标准成功', 2);
//       }
//     })
//     .catch(() => {
//       message.error('保存检测标准失败', 2);
//     });
// };
/**
 * @name: 郭冲
 * @Date: 2023-05-24 17:45:25
 * @introduction 暴露当前标准和改变之后的标准
 * @description 若果当前标准和改变之后的标准不同则提示“选择标准需要保存后更新为当前标准！是否关闭？”
 */
defineExpose({
  // standard,
  // standardCurrent,
});
</script>

<style lang="scss" scoped>
@use "../../../assets/style/modalcoustom.scss";
tr:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
input:not([type="range"]),
label,
select,
summary,
textarea {
  padding: 0.02rem 0rem;
  text-align: center;
}
.my-table-1 {
  width: 100%;
  border: 1px solid #9ecdfc;
  border-collapse: collapse;
  margin-bottom: 0.1rem;
}
.my-table-1 tr {
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.my-table-1 tr th,
.mdtitle {
  padding: 0.05rem 0.08rem;
  border: 1px solid #9ecdfc;
  font-size: 0.07rem;
  border-right: 1px solid #9ecdfc;
}
.standard {
  width: 85%;
  height: 0.3rem;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.standard-name {
  color: white;
  font-size: 0.07rem;
  /* line-height: 0.3rem; */
}
.standard-select {
  width: 1.2rem;
}
.coarse-aggregate {
  background-color: #169bd5;
  padding: 0.04rem;
}
.flex-r-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.fineness-modulus {
  display: flex;
  padding: 0.02rem;
}
.fineness-modulus-value {
  display: flex;
  align-items: center;
  margin-left: 0.03rem;
}
.mod-main {
  width: 100%;
  height: 100%;
  padding: 0.08rem;
}
.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.06rem;
}
:deep(.ant-btn-primary) {
  height: fit-content;
}

.save-btn {
  font-size: 0.07rem !important;
  margin-top: 0.1rem;
}
.jcss th,
.jcss td {
  padding: 0.05rem;
  font-size: 0.07rem;
  text-align: center;
  color: #fff;
}
.jcss input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
